import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import useFetch from "../../../hooks/useFecth";
import { toast } from "react-toastify";
import {
  ticketsSelectedAtom,
  ticketsFiltersAtom,
  ticketListAtom,
} from "../../../stores";
import { useAuth } from "../../../hooks";

const initialLoading = {
  get: false,
  show: false,
  edit: false,
  delete: false,
};

export default function useTickets() {
  const ticketFecth = useFetch();
  const { userAuth, logout } = useAuth();

  const [loading, setLoading] = useState(initialLoading);
  const [ticket, setTicket] = useRecoilState(ticketsSelectedAtom);
  const [ticketList, setTicketList] = useRecoilState(ticketListAtom);
  const [totalElements, setTotalElements] = useState(0);

  // Filtros
  const [filters, setFilters] = useRecoilState(ticketsFiltersAtom);

  const handleRole = (role) => {
    switch (role) {
      case "empresa":
        setFilters({
          ...filters,
          tipo_contacto: "Empresa",
          contacto_id: userAuth?.empresa?.id,
        });
        return {
          tipo_contacto: "Empresa",
          contacto_id: userAuth?.empresa?.id,
        };

      case "servicio":
        setFilters({
          ...filters,
          tipo_contacto: "Servicio",
          contacto_id: userAuth?.servicio?.id,
        });

        return {
          tipo_contacto: "Servicio",
          contacto_id: userAuth?.servicio?.id,
        };
      case "candidato":
        setFilters({
          ...filters,
          tipo_contacto: "Candidato",
          contacto_id: userAuth?.candidato?.id,
        });

        return {
          tipo_contacto: "Candidato",
          contacto_id: userAuth?.candidato?.id,
        };

      case "user":
        setFilters({
          ...filters,
          tipo_contacto: "User",
          contacto_id: userAuth?.id,
        });
        return {
          tipo_contacto: "User",
          contacto_id: userAuth?.id,
        };

      default:
        setFilters({
          ...filters,
          tipo_contacto: "",
        });
        return {
          tipo_contacto: "",
          // contacto_id: userAuth?.id
        };
    }
  };

  useEffect(() => {
    let aux = handleRole(userAuth.role);

    listTicketsByUser({
      ...filters,
      tipo_contacto: aux.tipo_contacto,
      contacto_id: aux.contacto_id,
    });
  }, [userAuth]);

  function createTicket(data) {
    setLoading({ ...loading, create: true });
    return ticketFecth.post(`tickets`, data).then(({ data, status }) => {
      setLoading({ ...loading, create: false });
      if (status === "error") {
        toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return false;
      } else {
        setTicket(data);
        return true;
      }
    });
  }

  function showTicket(id) {
    setLoading({ ...loading, show: true });
    return ticketFecth.get(`tickets/${id}`).then(({ data, status }) => {
      setLoading({ ...loading, show: false });
      if (status === "error") {
        toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return false;
      } else {
        setTicket(data);
        return true;
      }
    });
  }

  function listTicketsByUser({
    page = 1,
    per_page = "",
    tema = "",
    estado = "",
    orden = "",
    contacto_id = "",
  }) {
    setLoading({ ...loading, get: true });
    return ticketFecth
      .get(
        `tickets/?page=${page}&per_page=${per_page}&tema=${tema}&estado=${estado}&sort=updated_at&direction=${orden}&tipo_contacto=${
          handleRole(userAuth.role).tipo_contacto
        }&contacto_id=${contacto_id}`
      )
      .then(({ data, status }) => {
        setLoading({ ...loading, get: false });
        if (status === "error") {
          toast.error(data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          return false;
        } else {
          setTicketList(data);
          setTotalElements(data.length);
          return true;
        }
      });
  }

  function editTicket(data, id) {
    // let formData = new FormData();
    // for (let key in data){
    //     formData.append(key, data[key]);
    // }
    // if (data.imagen){
    //     formData.append('imagen', data.imagen);
    // }
    setLoading({ ...loading, edit: true });
    return ticketFecth.put(`tickets/${id}`, data).then(({ data, status }) => {
      setLoading({ ...loading, edit: false });
      if (status === "error") {
        toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return false;
      } else {
        toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        // showCandidato(id);
        return true;
      }
    });
  }

  function deleteTicket(id) {
    setLoading({ ...loading, delete: true });
    return ticketFecth.delete(`tickets/${id}`).then(({ data, status }) => {
      setLoading({ ...loading, delete: false });
      if (status === "error") {
        toast.error(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return false;
      } else {
        toast.success(data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        logout();
        return true;
      }
    });
  }

  function answerTicket(data) {
    setLoading({ ...loading, update: true });
    return ticketFecth.post(`respuestas`, data).then(({ data, status }) => {
      setLoading({ ...loading, update: false });
      if (status === "error") {
        // showNotification({ type: "error", msg: 'Ha ocurrido un error intentando responder el ticket.' });
        return false;
      } else {
        // showNotification({ type: "success", msg: '¡Has respondido el ticket con éxito!' });
        return true;
      }
    });
  }

  function handleChangeFilters(newFilters) {
    setFilters(newFilters);
  }

  return {
    loading,
    ticket,
    editTicket,
    deleteTicket,
    listTicketsByUser,
    ticketList,
    showTicket,
    filters,
    handleChangeFilters,
    totalElements,
    answerTicket,
    handleRole,
    createTicket,
  };
}
