import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import {
  IoMdAdd,
  LuMapPin,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "../../assets/icons/vander";
import useJobs from "./hooks/useJobs";
import fepamicLogo from "../../assets/images/favicon.webp";
import Pagination from "../../components/Pagination";
import { formatDistanceToNow, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import PaginationCustom from "../../components/Pagination-custom-sgm";

export default function JobList() {
  const { getEmpleos, empleos, totalPages } = useJobs();
  const [currentPage, setCurrentPage] = useState(1);

  const { filters } = useJobs();

  const params = useParams();

  useEffect(() => {
    getEmpleos({ ...filters, page: currentPage });
    console.log(params);
  }, [currentPage]);

  return (
    <>
      <div className="lg:col-span-8 md:col-span-6">
        <div className="grid grid-cols-1 gap-[30px]">
          {empleos && empleos.length > 0 ? (
            empleos.map((item) => {
              const fecha = parseISO(item?.updated_at);
              const resultado = formatDistanceToNow(fecha, {
                addSuffix: true,
                locale: es,
              });
              return (
                <div
                  className="group relative overflow-hidden bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-700 dark:hover:shadow-gray-700 hover:-mt-2 rounded-md transition-all duration-500 h-fit"
                  key={item?.id}
                >
                  <div className="p-6">
                    <div className="flex items-center">
                      <div className="w-14 h-14 min-w-[56px] flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                        <img
                          src={item.image ? item.image : fepamicLogo}
                          className="h-8 w-8"
                          alt=""
                        />
                      </div>

                      <div className="ms-3">
                        <Link
                          to={`/empleos/${item.id}`}
                          className="inline-block text-[16px] font-semibold hover:text-primary transition-all duration-500 me-1"
                        >
                          {item.oferta_trabajo}
                        </Link>
                        <span className="inline-block text-sm text-slate-400">
                          {resultado.charAt(0).toUpperCase() +
                            resultado.slice(1)}
                        </span>
                        <div className="flex items-center gap-2">
                          <span className="bg-primary-900/10 inline-block text-white bg-primary-900 text-xs px-2.5 py-0.5 font-semibold rounded-full me-1">
                            {item?.tipo_contrato.charAt(0).toUpperCase() +
                              item?.tipo_contrato.slice(1)}
                          </span>
                          {/* <span className="text-sm font-medium inline-block me-1">Duración: <span className="text-slate-400">{item?.duracion_contrato}</span></span> */}
                          <span className="text-sm font-medium inline-block me-1">
                            Salario:{" "}
                            <span className="text-slate-400">
                              {item?.salario}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <p className="text-slate-400 py-3">
                      {item?.funciones} {item?.conocimientos_informaticos}{" "}
                      {item?.formacion_complementaria}
                    </p>

                    <div>
                      <span className="bg-slate-100 dark:bg-slate-800 inline-block text-slate-900 dark:text-slate-300 text-xs px-2.5 py-0.5 font-semibold rounded-full me-1">
                        HTML
                      </span>
                      <span className="bg-slate-100 dark:bg-slate-800 inline-block text-slate-900 dark:text-slate-300 text-xs px-2.5 py-0.5 font-semibold rounded-full me-1">
                        CSS
                      </span>
                      <span className="bg-slate-100 dark:bg-slate-800 inline-block text-slate-900 dark:text-slate-300 text-xs px-2.5 py-0.5 font-semibold rounded-full me-1">
                        SASS
                      </span>
                      <span className="bg-slate-100 dark:bg-slate-800 inline-block text-slate-900 dark:text-slate-300 text-xs px-2.5 py-0.5 font-semibold rounded-full me-1">
                        SCSS
                      </span>
                      <span className="bg-slate-100 dark:bg-slate-800 inline-block text-slate-900 dark:text-slate-300 text-xs px-2.5 py-0.5 font-semibold rounded-full me-1">
                        Photoshop
                      </span>
                      <span className="bg-slate-100 dark:bg-slate-800 inline-block text-slate-900 dark:text-slate-300 text-xs px-2.5 py-0.5 font-semibold rounded-full me-1">
                        Graphics
                      </span>
                      <span className="bg-slate-100 dark:bg-slate-800 inline-block text-slate-900 dark:text-slate-300 text-xs px-2.5 py-0.5 font-semibold rounded-full me-1">
                        Bootstrap
                      </span>
                    </div>
                  </div>

                  <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 lg:flex justify-between items-center">
                    <div className="lg:inline-block flex justify-between">
                      <span className="inline-flex me-1 items-center text-slate-400">
                        <LuMapPin className="text-[18px] text-slate-900 dark:text-white me-1" />
                        {item?.lugar_trabajo}
                      </span>
                    </div>

                    <Link
                      to={`/empleos/${item.id}`}
                      className="btn btn-sm rounded-md bg-primary-900 hover:bg-primary-900 border-primary hover:border-primary text-white md:ms-2 w-full lg:w-auto lg:mt-0 mt-4"
                    >
                      Ver oferta de empleo
                    </Link>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center">
              <p>
                No hay resultados que coincidan con tus criterios de búsqueda.
              </p>
              <p>Prueba a cambiar los filtros</p>
            </div>
          )}
        </div>
        <PaginationCustom
          getList={getEmpleos}
          filters={filters}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </div>
    </>
  );
}
