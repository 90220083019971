import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Link, useParams, useNavigate } from "react-router-dom";
import useJobs from "./hooks/useJobs";
import { formatDistanceToNow, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { validationInscripcionOfertaEmpleo } from "./constants/validations";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks";

export default function JobApply() {
  const { id } = useParams();
  const { empleo, showEmpleo, newSolicitud } = useJobs();
  const { userAuth } = useAuth();
  const navigate = useNavigate();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationInscripcionOfertaEmpleo),
  });

  useEffect(() => {
    showEmpleo(id);
  }, [id]);

  const onSubmit = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key] instanceof FileList && data[key][0] !== undefined) {
        formData.append(key, data[key][0], data[key].name);
      }
    });
    formData.append("oferta_empleo_id", empleo.id);
    formData.append("candidato_id", userAuth.candidato.id);
    formData.append("discapacidad", data.discapacidad);
    formData.append("desempleado", data.desempleado);
    newSolicitud(formData).then(() => {
      reset();
      toast.success("Solicitud enviada", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        navigate("/empleos");
      }, 3000);
    });
  };

  const fecha = empleo.updated_at !== undefined && parseISO(empleo?.updated_at);
  const resultado =
    fecha && formatDistanceToNow(fecha, { addSuffix: true, locale: es });
  return (
    <>{console.log(userAuth)}
      <Navbar navClass="justify-end nav-light" />
      <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-primary-900/90"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              {empleo.oferta_trabajo}
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/index">Ofertas de empleo</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Formulario
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-slate-50 dark:text-slate-800">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative bg-slate-50 dark:bg-slate-800 lg:py-24 py-16">
        <div className="container">
          <div className="md:flex justify-center">
            <div className="lg:w-2/4 md:w-2/3">
              <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-1">
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label className="font-semibold" htmlFor="RegisterName">
                        Discapacidad:
                      </label>
                      <input
                        id="discapacidad"
                        type="number"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-1"
                        placeholder="Indica el grado de discapacidad"
                        {...register("discapacidad")}
                        errors={errors}
                      />
                      {errors.discapacidad && (
                        <span className="text-red-600">
                          {errors.discapacidad.message}
                        </span>
                      )}
                    </div>
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label className="font-semibold" htmlFor="archivo_drive">
                        Certificado de discapacidad:
                      </label>
                      <input
                        className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1"
                        id="archivo_drive"
                        type="file"
                        {...register("certificado_discapacidad")}
                        errors={errors}
                      />
                    </div>

                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label className="font-semibold">
                        ¿Tienes empleo actualmente?
                      </label>
                      <div className="block mt-2">
                        <div>
                          <label
                            htmlFor="si"
                            className="inline-flex items-center"
                          >
                            <input
                              type="radio"
                              name="desempleado"
                              id="desempleado"
                              className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                              value="0"
                              /* checked={filters.modalidad === "presencial"} */
                              /* ref={register} */
                              {...register("desempleado")}
                              errors={errors}
                            />
                            <span className="ms-2 text-slate-400">Sí</span>
                          </label>
                        </div>
                        <div>
                          <label
                            htmlFor="no"
                            className="inline-flex items-center"
                          >
                            <input
                              type="radio"
                              name="desempleado"
                              id="no"
                              className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                              value="1"
                              /* checked={filters.modalidad === "semipresencial"} */
                              /* ref={register} */
                              {...register("desempleado")}
                              errors={errors}
                            />
                            <span className="ms-2 text-slate-400">No</span>
                          </label>

                          {errors.desempleado && (
                            <p>
                              <span className="text-red-600">
                                {errors.desempleado.message}
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label className="font-semibold" htmlFor="archivo_drive">
                        Carnet de conducir:
                      </label>
                      <input
                        className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1"
                        id="archivo_drive"
                        type="file"
                        {...register("archivo_drive")}
                        errors={errors}
                      />
                    </div>
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label className="font-semibold" htmlFor="archivo_drive">
                        Certificados de idiomas:
                      </label>
                      <input
                        className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1"
                        id="archivo_idiomas"
                        type="file"
                        {...register("archivo_idiomas")}
                        errors={errors}
                      />
                    </div>
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label
                        className="font-semibold"
                        htmlFor="archivo_informatica"
                      >
                        Formación en informática:
                      </label>
                      <input
                        className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1"
                        id="archivo_informatica"
                        type="file"
                        {...register("archivo_informatica")}
                        errors={errors}
                      />
                    </div>
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label
                        className="font-semibold"
                        htmlFor="archivo_idiomas"
                      >
                        Experiencia laboral:
                      </label>
                      <input
                        className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1"
                        id="archivo_experiencia"
                        type="file"
                        {...register("archivo_experiencia")}
                        errors={errors}
                      />
                    </div>
                    <div className="mb-4 ltr:text-left rtl:text-right">
                      <label
                        className="font-semibold"
                        htmlFor="archivo_formacion"
                      >
                        Formación adicional:
                      </label>
                      <input
                        className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1"
                        id="archivo_formacion"
                        type="file"
                        {...register("archivo_formacion")}
                        errors={errors}
                      />
                    </div>

                    <div>
                      <button
                        type="submit"
                        id="submit"
                        name="send"
                        className="btn rounded-md bg-primary-900 hover:bg-primary-900 border-primary hover:border-primary text-white"
                      >
                        Enviar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
