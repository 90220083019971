import React, { useState, useEffect } from 'react';
import { Delete, Filter } from 'react-feather';
import useTickets from './hooks/useTickets';
import { useAuth } from '../../hooks';
import { useForm } from 'react-hook-form';

const SupportFilters = ({ busqueda }) => {
    const { listTicketsByUser, filters, handleChangeFilters, handleRole } = useTickets();
    const { userAuth } = useAuth();

    const {
        register,
        reset,
        setValue,
        handleSubmit
    } = useForm();

    const [selectedEstado, setSelectedEstado] = useState('');
    const [selectedOrden, setSelectedOrden] = useState('');
    const busquedaParam = busqueda;

    // Cuando el componente se monta, establecer los valores iniciales del formulario
    useEffect(() => {
        setValue('tema', filters.tema);
        setValue('estado', filters.estado);
        setValue('orden', filters.orden);
    }, []);

    const handleEstadoChange = (value) => {
        setSelectedEstado(value);
        handleChangeFilters({ ...filters, estado: value });
    };

    const handleOrdenChange = (value) => {
        setSelectedOrden(value);
        handleChangeFilters({ ...filters, orden: value });
    };

    const handleResetFilters = () => {
        const auxFilters = {
            tema: "",
            estado: "",
            orden: "",
        };
        let aux = handleRole(userAuth.role);

        setSelectedEstado('');
        setSelectedOrden('');

        handleChangeFilters(auxFilters);
        listTicketsByUser({ ...auxFilters, contacto_id: aux.contacto_id });

        reset({
            tema: '',
            estado: '',
            orden: '',
        });
    };

    const onSubmit = (data) => {
        listTicketsByUser({
            ...filters,
            tema: data.tema,
            estado: data.estado,
            orden: data.orden
        });
    };

    return (
        <div className='container'>
            <form onSubmit={handleSubmit(onSubmit)} className='grid lg:grid-cols-5 md:grid-cols-3 gap-2 place-items-center bg-primary-900 p-4 rounded-lg shadow-md'>
                <div className="filter-search-form relative filter-border">
                    <input
                        {...register('tema')}
                        type="text"
                        className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0 rounded-md cursor-pointer"
                        placeholder="Buscar"
                    />
                </div>
                <select
                    {...register('estado')}
                    className='form-select filter-input-box rounded-md cursor-pointer focus-visible:ring-primary'
                    onChange={(e) => handleEstadoChange(e.target.value)}
                >
                    <option value="">Estado</option>
                    <option value={1}>Abierto</option>
                    <option value={2}>Cerrado</option>
                    <option value={3}>En proceso</option>
                </select>
                <select
                    {...register('orden')}
                    className='form-select filter-input-box rounded-md cursor-pointer focus-visible:ring-primary'
                    onChange={(e) => handleOrdenChange(e.target.value)}
                >
                    <option value="">Orden</option>
                    <option value="ASC">Ascendente</option>
                    <option value="DESC">Descendente</option>
                </select>
                <button type="submit" className='flex gap-2 justify-center items-center btn bg-slate-900 hover:bg-white hover:text-black border-0 rounded-md text-white font-normal'>
                    <Filter className='w-4 h-4' />Aplicar
                </button>
                <button type="button" onClick={handleResetFilters} className='flex gap-2 justify-center items-center btn bg-slate-900 hover:bg-white hover:text-black border-0 rounded-md text-white font-normal'>
                    <Delete className='w-4 h-4' />Limpiar
                </button>
            </form>
        </div>
    );
}

export default SupportFilters;
