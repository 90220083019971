import { atom } from "recoil";

export const formacionAtom = atom({
    key: "formacionAtom",
    default: [],
});

export const formacionMetaAtom = atom({
    key: "formacionMetaAtom",
    default: [],
});

export const formacionSelectedAtom = atom({
    key: "formacionSelectedAtom",
    default: {},
});