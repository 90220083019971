import React, { useState, useEffect } from 'react'
import { useRecoilState } from "recoil";
import useFetch from "./useFecth";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'; 
import {
    formacionAtom,
    formacionMetaAtom,
    formacionSelectedAtom,
} from "../stores/formacion-store";

const initialLoading = {
    get: false,
};


export default function useFormacion() {
const PATH_URL = process.env.REACT_APP_BASE_URL;
const navigate = useNavigate();
const formacionFecth = useFetch();
const [loading, setLoading] = useState(initialLoading);
const [formaciones, setFormaciones] = useRecoilState(formacionAtom);
const [formaccionesSelector, setFormaccionesSelector] = useState([]);
const [formacionMeta, setFormacionMeta] = useRecoilState(formacionMetaAtom);
const [formacionSelected, setFormacionSelected] = useRecoilState(formacionSelectedAtom);


useEffect(() => {
    getFormacion();
 
}, [])




function getFormacion() {
    setLoading({ ...loading, get: true });
    return formacionFecth.get("nivel-formacion")
    .then(({data, status, meta}) => {
        setLoading({ ...loading, get: false });
        if (status === "error"){
            toast.error(data,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return false;
        }else{
            setFormaciones(data);
            setFormacionMeta(meta);
            
        }
    })
    
}

  

  return {
    formaciones,
    formacionMeta,
    formacionSelected,
    loading,
    getFormacion,
  }
}
