import React, { useState, useEffect, useMemo } from "react";
// mios
import Select from "react-select";
import { useFormacion } from "../../hooks";
import useCandidato from "./hooks/useCandidato";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { initialCandidato, validationCreateCandidato } from "./constants";
import logo_fepamic from "../../assets/images/logo.webp";
//plantilla
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

export default function Signup() {
  const { formaciones } = useFormacion();
  const { createCandidato, loading } = useCandidato();
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const [formacionSelected, setFormacionSelected] = useState(null);
  const [formKey, setFormKey] = useState(Date.now());

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationCreateCandidato),
    defaultValues: useMemo(() => initialCandidato, [initialCandidato]),
  });

  const tieneDiscapacidad = watch("tiene_discapacidad");

  useEffect(() => {
    // Si el checkbox principal de discapacidad está desmarcado,
    // también desmarca todas las opciones de discapacidad.
    if (!tieneDiscapacidad) {
      setValue("discapacidad_inferior_33", false);
      setValue("discapacidad_superior_33", false);
      setValue("discapacidad_superior_65", false);
      setValue("tipo_discapacidad", null);
    }
  }, [tieneDiscapacidad, setValue]);

  // formato de select
  const formacionesSelector = formaciones.map((formacion) => {
    return {
      value: formacion.id,
      label: formacion.name,
    };
  });

  const tiposDiscapacidadSelector = [
    { value: "Física", label: "Física" },
    { value: "Psíquica", label: "Psíquica" },
    { value: "Sensorial", label: "Sensorial" },
    { value: "Intelectual", label: "Intelectual" },
  ];

  // Estilos para el select
  const SelectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#A70827" : provided.borderColor, // Usar el color emerald-600 aquí
      boxShadow: state.isFocused ? "0 0 0 1px #A70827" : provided.boxShadow, // Opcional: para replicar el efecto de foco de Tailwind
      "&:hover": {
        borderColor: state.isFocused ? "#A70827" : provided.borderColor,
      },
      marginTop: "8px",
    }),
  };

  // Función para enviar el formulario
  const onSubmit = async (data) => {
    if (data.terminos_condiciones) {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (data[key] instanceof FileList && data[key][0] !== undefined) {
          formData.append(key, data[key][0], data[key].name);
        } else {
          formData.append(key, data[key]);
        }
      });
      formData.append("curriculum", "");
      formData.append("role_id", 5);

      const status = await createCandidato(formData);
      if (status) {
        reset(initialCandidato);
        // setFormKey(Date.now());
      }
    } else {
      toast.error('Debes aceptar los términos y condiciones para registrarte.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };



  // Función para manejar cambios en las opciones de discapacidad y asegurar que solo una esté marcada
  const handleOptionChange = (selectedOption) => {
    setValue("discapacidad_inferior_33", selectedOption === "discapacidad_inferior_33");
    setValue("discapacidad_superior_33", selectedOption === "discapacidad_superior_33");
    setValue("discapacidad_superior_65", selectedOption === "discapacidad_superior_65");
  };

  return (
    <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg3.jpg')] bg-no-repeat bg-center bg-cover overflow-y-auto min-h-screen">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
      <div className=" max-h-screen overflow-y-auto">
        <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1">
          <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
            <div
              style={{ height: "850px", overflowY: "scroll" }}
              className="p-6"
            >
              <Link to="/">
                <img
                  src={logo_fepamic}
                  className="mx-auto h-[48px] block dark:hidden"
                  alt=""
                />
                <img
                  src={logo_fepamic}
                  className="mx-auto h-[48px] dark:block hidden"
                  alt=""
                />
              </Link>
              <h5 className="my-6 text-xl font-semibold">Registrarse</h5>
              <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* Nombre y Apellidos en dos columnas */}
                  <div className="mb-4">
                    <label className="font-semibold" htmlFor="RegisterName">
                      Nombre:
                    </label>
                    <input
                      id="RegisterName"
                      type="text"
                      className="form-input mt-3 rounded-md w-full"
                      placeholder="Su nombre"
                      {...register("name")}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="font-semibold" htmlFor="RegisterSurnames">
                      Apellidos:
                    </label>
                    <input
                      id="RegisterSurnames"
                      type="text"
                      className="form-input mt-3 rounded-md w-full"
                      placeholder="Sus apellidos"
                      {...register("surnames")}
                    />
                  </div>
                  {/* NIF y fecha nacimiento */}
                  <div className="mb-4">
                    <label className="font-semibold" htmlFor="RegisterNif">
                      NIF:
                    </label>
                    <input
                      id="RegisterNif"
                      type="text"
                      className="form-input mt-3 rounded-md w-full"
                      placeholder="Su NIF"
                      {...register("nif")}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="font-semibold"
                      htmlFor="RegisterNacimiento"
                    >
                      Fecha de nacimiento:
                    </label>
                    <input
                      id="RegisterNacimiento"
                      type="date"
                      className="form-input mt-3 rounded-md w-full"
                      placeholder="Su fecha de nacimiento"
                      {...register("fecha_nacimiento")}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="font-semibold"
                      htmlFor="telfono"
                    >
                      teléfono:
                    </label>
                    <input
                      id="telefono"
                      type="number"
                      className="form-input mt-3 rounded-md w-full"
                      placeholder="Su teléfono"
                      {...register("telefono")}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="font-semibold"
                      htmlFor="direccion"
                    >
                      Dirección:
                    </label>
                    <input
                      id="direccion"
                      type="text"
                      className="form-input mt-3 rounded-md w-full"
                      placeholder="Su dirección"
                      {...register("direccion")}
                    />
                  </div>
                </div>
                {/* formacion candidato */}
                <div className="mb-4">
                  <label className="font-semibold" htmlFor="RegisterFormacion">
                    Formación:
                  </label>
                  <Select
                    options={formacionesSelector}
                    styles={SelectCustomStyles}
                    placeholder="Elige una opción"
                    onChange={(selected) => {
                      setFormacionSelected(selected);
                      setValue("cualificacion", selected.value);
                    }}
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* Email y Password en dos columnas */}
                  <div className="mb-4">
                    <label className="font-semibold" htmlFor="LoginEmail">
                      Email:
                    </label>
                    <input
                      id="LoginEmail"
                      type="email"
                      className="form-input mt-3 rounded-md w-full"
                      placeholder="nombre@ejemplo.com"
                      {...register("email")}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="font-semibold" htmlFor="LoginPassword">
                      Contraseña:
                    </label>
                    <input
                      id="LoginPassword"
                      type="password"
                      className="form-input mt-3 rounded-md w-full"
                      placeholder="Establezca una contraseña"
                      {...register("password")}
                    />
                  </div>
                </div>
                {/* Elementos que abarcan ambas columnas fuera del contenedor de la grilla anterior */}
                {/* discapacidad superior al 33% */}
                <div className="mb-4">
                  <div className="flex items-center w-full mb-0">
                    <input
                      className="form-checkbox text-primary rounded w-4 h-4 me-2 border border-inherit"
                      type="checkbox"

                      id="tieneDiscapacidad"
                      {...register("tiene_discapacidad")}
                    />
                    <label
                      className="form-check-label font-semibold"
                      htmlFor="tieneDiscapacidad"
                    >
                      ¿Tiene reconocida una discapacidad?
                    </label>
                  </div>
                </div>
                {/* Opciones en caso afirmativo*/}
                {tieneDiscapacidad && (<>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                    {/* opcion 1 */}
                    <div className="flex items-center w-full mb-0">
                      <input
                        className="form-checkbox text-primary rounded w-4 h-4 me-2 border border-inherit"
                        type="checkbox"
                        id="inferior33"
                        {...register("discapacidad_inferior_33")}
                        onChange={() => handleOptionChange("discapacidad_inferior_33")}
                      />
                      <label
                        className="form-check-label font-semibold"
                        htmlFor="inferior33"
                      >
                        inferior al 33%
                      </label>
                    </div>
                    {/* opcion 2 */}
                    <div className="flex items-center w-full mb-0">
                      <input
                        className="form-checkbox text-primary rounded w-4 h-4 me-2 border border-inherit"
                        type="checkbox"
                        id="superior33"
                        {...register("discapacidad_superior_33")}
                        onChange={() => handleOptionChange("discapacidad_superior_33")}
                      />
                      <label
                        className="form-check-label font-semibold"
                        htmlFor="superior33"
                      >
                        superior al 33%
                      </label>
                    </div>
                    {/* opcion 3 */}
                    <div className="flex items-center w-full mb-0">
                      <input
                        className="form-checkbox text-primary rounded w-4 h-4 me-2 border border-inherit"
                        type="checkbox"
                        id="superior65"
                        {...register("discapacidad_superior_65")}
                        onChange={() => handleOptionChange("discapacidad_superior_65")}
                      />
                      <label
                        className="form-check-label font-semibold"
                        htmlFor="superior65"
                      >
                        superior al 65%
                      </label>
                    </div>
                  </div>
                  {/* tipo de discapacidad */}
                  {/* formacion candidato */}
                  <div className="mb-4 py-4">
                    <label className="font-semibold" htmlFor="RegisterFormacion">
                      Tipo de discapacidad:
                    </label>
                    <Select
                      options={tiposDiscapacidadSelector}
                      styles={SelectCustomStyles}
                      placeholder="Elige una opción"
                      onChange={(selected) => {

                        setValue("tipo_discapacidad", selected.value);
                      }}
                    />
                  </div>
                  <div className="mb-4 ltr:text-left rtl:text-right">
                    <label className="font-semibold" htmlFor="RegisterName">
                      Discapacidad:
                    </label>
                    <input
                      id="discapacidad"
                      type="number"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-1"
                      placeholder="Indica el grado de discapacidad"
                      {...register("discapacidad")}
                      errors={errors}
                    />
                    {errors.discapacidad && (
                      <span className="text-red-600">
                        {errors.discapacidad.message}
                      </span>
                    )}
                  </div>
                  <div className="mb-4 ltr:text-left rtl:text-right">
                    <label className="font-semibold" htmlFor="archivo_drive">
                      Certificado de discapacidad:
                    </label>
                    <input
                      className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1"
                      id="archivo_drive"
                      type="file"
                      {...register("certificado_discapacidad")}
                      errors={errors}
                    />
                  </div>
                </>)}
                {/* Acepto recibir información de ofertas de empleo*/}
                <div className="mb-4">
                  <div className="flex items-center w-full mb-0">
                    <input
                      className="form-checkbox text-primary rounded w-4 h-4 me-2 border border-inherit"
                      type="checkbox"
                      value=""
                      id="ofertaEmpleo"
                      {...register("info_ofertas_empleo")}
                    />
                    <label
                      className="form-check-label font-semibold"
                      htmlFor="ofertaEmpleo"
                    >
                      Acepto recibir información de ofertas de empleo
                    </label>
                  </div>
                </div>
                {/* Acepto recibir información de cursos formativos */}
                <div className="mb-4">
                  <div className="flex items-center w-full mb-0">
                    <input
                      className="form-checkbox text-primary rounded w-4 h-4 me-2 border border-inherit"
                      type="checkbox"
                      value=""
                      id="cursosFormativos"
                      {...register("info_ofertas_formacion")}
                    />
                    <label
                      className="form-check-label font-semibold"
                      htmlFor="cursosFormativos"
                    >
                      Acepto recibir información de cursos formativos
                    </label>
                  </div>
                </div>
                {/* Acepto inscribirme en la bolsa de empleo */}
                <div className="mb-4">
                  <div className="flex items-center w-full mb-0">
                    <input
                      className="form-checkbox text-primary rounded w-4 h-4 me-2 border border-inherit"
                      type="checkbox"
                      value=""
                      id="bolsaEmpleo"
                      {...register("inscrito_bolsa")}
                    />
                    <label
                      className="form-check-label font-semibold"
                      htmlFor="bolsaEmpleo"
                    >
                      Acepto inscribirme en la bolsa de empleo
                    </label>
                  </div>
                </div>
                {/* Acepto los términos y condiciones */}
                <div className="mb-4">
                  <div className="flex items-center w-full mb-0">
                    <input
                      className="form-checkbox text-primary rounded w-4 h-4 me-2 border border-inherit"
                      type="checkbox"
                      value=""
                      id="AcceptT&C"
                      {...register("terminos_condiciones")}
                    />
                    <label
                      className="form-check-label text-slate-400"
                      htmlFor="AcceptT&C"
                    >
                      Acepto los{" "}
                      <Link to="#" className="text-primary">
                        Términos y Condiciones
                      </Link>
                    </label>
                  </div>
                </div>
                <div className="mb-4">
                  <input
                    type="submit"
                    className="btn bg-primary-900 hover:bg-primary-900 border-primary hover:border-primary text-white rounded-md w-full cursor-pointer transition-all duration-300 ease-in-out"
                    value="Registrar"
                  />
                </div>
                <div className="text-center">
                  <span className="text-slate-400 me-2">
                    ¿Tienes ya una cuenta?{" "}
                  </span>{" "}
                  <Link
                    to="/login"
                    className="text-black dark:text-white font-bold"
                  >
                    Acceder
                  </Link>
                </div>
              </form>
            </div>

            <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 text-center">
              <p className="mb-0 text-gray-400 font-medium">
                © {new Date().getFullYear()} Aicor Empleo. Desarrollado por{" "}
                <Link
                  to="https://www.aicor.com/"
                  target="_blank"
                  className="text-reset"
                >
                  Aicor
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
