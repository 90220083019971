import React from "react";
import contact from "../assets/images/fondo-contacto.svg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { PiMapPin, BsTelephone, LuMail } from "../assets/icons/vander";
import Phone from "../assets/icons/phone.svg";
import Mail from "../assets/icons/mail.svg";
import MapIn from "../assets/icons/mapin.svg";
import { useNavigate } from "react-router-dom";

export default function Contact() {
    const navigate = useNavigate();
  return (
    <>
      <Navbar navClass="justify-end nav-light" />
      <section className="relative table w-full py-36 bg-contacto bg-center bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-primary-900/90"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              Contacto
            </h3>
          </div>
        </div>
        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Inicio</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Contacto
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container lg:mt-24 mt-16">
        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
          <div className="text-center px-6">
            <div className="relative text-transparent">
              <div className="w-14 h-14 bg-primary-900/5 text-primary rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                <img src={Phone} alt="telefono" />
              </div>
            </div>

            <div className="content mt-7">
              <h5 className="title h5 text-lg font-semibold">Teléfono</h5>
              <p className="text-slate-400 mt-3">
                Llamenos a nuestro teléfono, estaremos encantados de contestar.
              </p>

              <div className="mt-5">
                <Link
                  to="tel:+152534-468-854"
                  className="btn btn-link text-primary hover:text-primary after:bg-primary-900 transition duration-500"
                >
                  +152 534-468-854
                </Link>
              </div>
            </div>
          </div>

          <div className="text-center px-6">
            <div className="relative text-transparent">
              <div className="w-14 h-14 bg-primary-900/5 text-primary rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                <img src={Mail} alt="sobre" />
              </div>
            </div>

            <div className="content mt-7">
              <h5 className="title h5 text-lg font-semibold">Email</h5>
              <p className="text-slate-400 mt-3">
                Escríbanos a nuestro email, estaremos encantados de contestar.
              </p>

              <div className="mt-5">
                <Link
                  to="mailto:contact@example.com"
                  className="btn btn-link text-primary hover:text-primary after:bg-primary-900 transition duration-500"
                >
                  contact@example.com
                </Link>
              </div>
            </div>
          </div>

          <div className="text-center px-6">
            <div className="relative text-transparent">
              <div className="w-14 h-14 bg-primary-900/5 text-primary rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                <img src={MapIn} alt="punto de localización" />
              </div>
            </div>

            <div className="content mt-7">
              <h5 className="title h5 text-lg font-semibold">Localización</h5>
              <p className="text-slate-400 mt-3">
                Polígono del Granadal,  <br /> C. Turquesa, 9, 14014 Córdoba
              </p>

              <div className="mt-5">
                <a
                  href="#iframeMapa"
                  className="video-play-icon read-more lightbox btn btn-link text-primary hover:text-primary after:bg-primary-900 transition duration-500"
                >
                  Ver en mapa
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="relative lg:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <img src={contact} alt="" />
            </div>

            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-semibold">
                    ¡Contáctanos!
                  </h3>

                  <form>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <label htmlFor="name" className="font-semibold">
                          Tu nombre:
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-input mt-2"
                          placeholder="Nombre..."
                        />
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <label htmlFor="email" className="font-semibold">
                          Tu Email:
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-input mt-2"
                          placeholder="Email..."
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <label htmlFor="subject" className="font-semibold">
                          Asunto:
                        </label>
                        <input
                          name="subject"
                          id="subject"
                          className="form-input mt-2"
                          placeholder="Asunto..."
                        />
                      </div>

                      <div className="mb-5">
                        <label htmlFor="comments" className="font-semibold">
                          Mensaje:
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          className="form-input mt-2 textarea"
                          placeholder="Mensaje..."
                        ></textarea>
                      </div>
                      <div className="mb-5">
                        <label htmlFor="terminos" className="font-semibold">
                          Aceptar los <span style={{ cursor: 'pointer', color: 'darkred'}}
                          onClick={() => navigate('/terms') }
                          >{'términos y condiciones legales  '}&nbsp;&nbsp;</span>
                        </label>
                        <input
                          className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                          type="checkbox"
                          id="terminos"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      className="btn bg-primary-900 hover:bg-primary-900 text-white rounded-md"
                    >
                      Enviar mensaje
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid relative mt-20">
        <div className="grid grid-cols-1">
          <div className="w-full leading-[0] border-0">
            <iframe
            id="iframeMapa"
              title="contact-frame"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6448497.290514233!2d-14.502667574999988!3d37.89115820000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6d20795f588893%3A0xe4028cacbceb10ad!2sAICOR%20Consultores%20Inform%C3%A1ticos%20%7C%20Consultor%C3%ADa%20Inform%C3%A1tica!5e0!3m2!1ses!2ses!4v1714468856784!5m2!1ses!2ses"
              style={{ border: 0 }}
              className="w-full h-[500px]"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
