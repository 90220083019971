import { atom } from "recoil";

export const empresasAtom = atom({
    key: "empresasAtom",
    default: [],
})

export const empresasMetaAtom = atom({
    key: "empresasMetaAtom",
    default: [],
})

export const selectedEmpresaAtom = atom({
    key: "selectedEmpresarioAtom",
    default: {},
})