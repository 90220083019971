import { atom } from 'recoil';

export const noticiasAtom = atom({
    key: "noticiasAtom",
    default: [],
})

export const noticiasMetaAtom = atom({
    key: "noticiasMetaAtom",
    default: [],
})

export const selectedNoticiaAtom = atom({
    key: "selectedNoticiaAtom",
    default: {},
})

export const categoriasNoticiaAtom = atom({
    key: "categoriasNoticia",
    default: [],
})

export const selectedCategoriasNoticiaAtom = atom({
    key: "selectedCategoriasNoticia",
    default: [],
})