import { useState } from 'react'
import { useRecoilState } from 'recoil'
import useFetch from '../../../hooks/useFecth';
import { toast } from 'react-toastify'
import {
    postsAtom,
    postSelectedAtom,
    postIdAtom,
} from "../../../stores";


const initialLoading = {
    get: false,
    show: false,
    edit: false,
    delete: false,
}


function useBlog() {

    const blogFetch = useFetch();

    const [loading, setLoading] = useState(initialLoading);
    const [posts, setPosts] = useRecoilState(postsAtom);
    const [post, setPost] = useRecoilState(postSelectedAtom);
    const [postId, setPostId] = useRecoilState(postIdAtom);
    const [totalPages, setTotalPages] = useState(0);

    const getPosts = async (page = 1, perPage = 9, categoria_id = 0) => {
        setLoading(true)
        const { data, status } = await blogFetch.get(`noticias-public/?page=${page}&perPage=${perPage}&categoria_id=${categoria_id}`);
        if (status === "error") {
            toast.error(data, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return false;
        } else {
            setPosts(data);
            setLoading(false);
            return true;
        }
    }

    const showPost = async (id) => {
        setLoading({ ...loading, show: true });
        try {
            const { data, status } = await blogFetch.get(`noticias-public/${id}`);
            setLoading({ ...loading, show: false });
            if (status === "error") {
                toast.error(data, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            } else {
                setPost(data);
                return true;
            }
        } catch (error) {
            toast.error(error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }


    const handlePostSelect = (id) => {
        setPostId(id);
        localStorage.setItem('postId', id);
    };

    return {
        loading,
        posts,
        setPosts,
        post,
        setPost,
        getPosts,
        showPost,
        totalPages,
        setTotalPages,
        postId,
        handlePostSelect
    }
}



export default useBlog

