import React, { useState, useEffect } from 'react';
import { useRecoilState } from "recoil";
import useFetch from '../../../hooks/useFecth';
import useFecthFormData from '../../../hooks/useFecthFormData';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
    candidatosAtom,
    candidatosMetaAtom,
    candidatoSelectedAtom
} from "../../../stores";
import { useAuth } from '../../../hooks';

const initialLoading = {
    get: false,
    show: false,
    edit: false,
    delete: false,
    upload: false,
}

export default function useCandidate() {
    const PATH_URL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const candidatoFecth = useFetch();
    const candidatoFecthFormData = useFecthFormData();
    const { userAuth, logout } = useAuth();
    const [loading, setLoading] = useState(initialLoading)
    const [candidatos, setCandidatos] = useRecoilState(candidatosAtom);
    const [candidato, setCandidato] = useRecoilState(candidatoSelectedAtom);

    useEffect(() => {
        if (userAuth) {
            showCandidato(userAuth.id);
            // console.log('userAuth', userAuth);
        }
    }, [userAuth])


    function getCandidatos() {
        setLoading({ ...loading, get: true });
        return candidatoFecth.get("candidatos")
            .then(({ data, status, meta }) => {
                setLoading({ ...loading, get: false });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    setCandidatos(data);
                    return true;
                }
            })
    }

    function showCandidato(id) {
        
        setLoading({ ...loading, show: true });
        return candidatoFecth.get(`candidatos-public/${id}`)
            .then(({ data, status }) => {
                setLoading({ ...loading, show: false });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    setCandidato(data);
                    return true;
                }
            })
    }

    function editCandidato(data, id) {
        // let formData = new FormData();
        // for (let key in data){
        //     formData.append(key, data[key]);
        // }
        // if (data.imagen){
        //     formData.append('imagen', data.imagen);
        // }
        setLoading({ ...loading, edit: true });
        return candidatoFecth.put(`candidatos/${id}`, data)
            .then(({ data, status }) => {
                setLoading({ ...loading, edit: false });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    toast.success(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    // showCandidato(id);
                    return true;
                }
            })

    }

    function deleteCandidato(id) {
        setLoading({ ...loading, delete: true });
        return candidatoFecth.delete(`candidatos/${id}`)
            .then(({ data, status }) => {
                setLoading({ ...loading, delete: false });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    toast.success(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    logout();
                    return true;
                }
            })
    }

    function actualizarDefaultCurriculum(id){
        setLoading({ ...loading, edit: true });
        return candidatoFecth.put(`curriculums/${id}/default`)
            .then(({ data, status }) => {
                setLoading({ ...loading, edit: false });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    toast.success(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    showCandidato(userAuth.id);
                    return true;
                }
            })
    }

    function deleteCurriculum(id){
        setLoading({ ...loading, delete: true });
        return candidatoFecth.delete(`curriculums/${id}`)
          .then(({ data, status }) => {
              setLoading({ ...loading, delete: false });
              if (status === "error") {
                  toast.error(data, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  });
                  return false;
              } else {
                  toast.success(data, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  });
                  showCandidato(userAuth.id);
                  return true;
              }
          })
      }

    function uploadCV(myData) {
        // Verificación inicial para comprobar si el documento existe
        if (!myData.documento || myData.documento.length === 0) {
            // Mostrar un mensaje de error si no hay documento
            toast.error("No existe documento para cargar.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return false;  // Retorna una promesa rechazada para detener la ejecución de la función
        }
    
        let formData = new FormData();
        formData.append('documento', myData.documento[0]); // Asumiendo que documento es un objeto FileList
        formData.append('candidato_id', myData.candidato_id); // Añadir candidato_id al formData
    
        setLoading({ ...loading, upload: true });
    
        return candidatoFecthFormData.post(`curriculum`, formData)
            .then(({ data, status }) => {
                setLoading({ ...loading, upload: false });
                if (status === "error") {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    toast.success('Carga exitosa', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    
                    showCandidato(myData.user_id);
                    return true;
                }
            })
            
    }
    

    
  
    return {
        loading,
        candidatos,
        candidato,
        getCandidatos,
        editCandidato,
        deleteCandidato,
        showCandidato,
        uploadCV,
        actualizarDefaultCurriculum,
        deleteCurriculum
    }
}
