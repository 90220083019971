// import React from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { useAuth } from './hooks';

// const PrivateRoute = ({ children, requiredPermissions }) => {
//   const { userAuth } = useAuth(); // Utiliza la propiedad correcta para obtener los datos del usuario
//   const location = useLocation();
//   const navigate = useNavigate();

//   console.log('auth', userAuth)

//   if (userAuth === null || !userAuth || userAuth === undefined) {
//     // Usuario no logueado, redirigir al login
//     navigate('/login');
//     return null;
//   }

//   // Suponiendo que userAuth.permissions contiene los permisos del usuario
//   const hasRequiredPermissions = requiredPermissions.every(permission =>
//     userAuth.permissions.includes(permission)
//   );

//   if (!hasRequiredPermissions) {
//     // Usuario no tiene permisos, mostrar error o redirigir
//     navigate('/error');
//     return null;
//   }

//   return children;
// };

// export default PrivateRoute;


import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './hooks';
import { toast } from "react-toastify";

const PrivateRoute = ({ children, requiredPermissions }) => {
  const { userAuth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica si el usuario está logueado
    if (userAuth === null || !userAuth || userAuth === undefined) {
      navigate('/login');
      toast.error('Necesitas estar logueado', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      // Verifica si el usuario tiene los permisos necesarios
      const hasRequiredPermissions = requiredPermissions.every(permission =>
        userAuth.permissions.includes(permission)
      );

      if (!hasRequiredPermissions) {
        navigate('/error');

        toast.error('No tienes permisos para acceder a esta página', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }
  }, [userAuth, requiredPermissions, navigate]);

  // Si el usuario está logueado y tiene permisos, renderiza el componente hijo
  // En caso contrario, el useEffect se encargará de redirigir
  return userAuth && children;
};

export default PrivateRoute;
