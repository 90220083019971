import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import bg5 from "../../assets/images/hero/bg5.jpg";
import image from "../../assets/images/team/01.jpg";
import shree_logo from "../../assets/images/company/shree-logo.png";
import circle_logo from "../../assets/images/company/circle-logo.png";
import Navbar from "../../components/Navbar";
import CandidateDetailComp from "../../components/candidate-detail-comp";
import Footer from "../../components/Footer";
import { format, set } from "date-fns";
import { de, es } from "date-fns/locale";
import { RadioGroup, Dialog, Transition } from "@headlessui/react";
import {
  Settings,
  FileText,
  Gift,
  Globe,
  Home,
  Mail,
  MapPin,
  Phone,
  Server,
  CreditCard,
  Check,
  X,
  Users,
  Download
} from "react-feather";
import {
  LuMail,
  BiLogoDribbble,
  AiOutlineBehance,
  BiLogoLinkedin,
  FaFacebookF,
  IoLogoTwitter,
  FaInstagram,
  FiFileText,
} from "../../assets/icons/vander";
import ExploreJob from "../../components/Explore-job";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CandidateList } from "../../data/data";
import useCandidate from "./hooks/useCandidate";

let curriculums = [
  {
    id: 1,
    name: "Curriculum vitae 1",
    url: "https://www.linkedin.com",
    created_at: "13-01-2024, a las 12:00",
  },
  {
    id: 2,
    name: "Curriculum vitae 2",
    url: "https://www.linkedin.com",
    created_at: "21-01-2024, a las 12:00",
  },
  {
    id: 3,
    name: "Curriculum vitae 3",
    url: "https://www.linkedin.com",
    created_at: "29-01-2024, a las 12:00",
  },
];

export default function CandidateDetail() {
  const params = useParams();
  const { candidato, uploadCV, actualizarDefaultCurriculum, deleteCurriculum } =
    useCandidate();
  const id = params.id;
  const data = CandidateList.find((jobs) => jobs.id === parseInt(id));
  const PATH_URL = process.env.REACT_APP_URL_API_FILES;

  const [selected, setSelected] = useState(
    candidato && candidato.curriculums && candidato.curriculums.length > 0
      ? candidato.curriculums.find((c) => c.is_default === 1)
      : null
  );
  const [open, setOpen] = useState(false);
  const [curriculumId, setCurriculumId] = useState(null);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    // Verificar si la lista de currículums está vacía
    if (
      candidato &&
      candidato.curriculums &&
      candidato.curriculums.length === 0
    ) {
      setSelected(null);
    } else {
      // Buscar el currículum marcado como principal
      const currPrincipal =
        candidato &&
        candidato.curriculums &&
        candidato.curriculums.find((c) => c.is_default === 1);
      setSelected(currPrincipal);
    }
  }, [candidato.curriculums]); // Dependencia del efecto: el array de currículums del candidato

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object({
        documento: yup.mixed().required("Debes subir un documento"),
      })
    ),
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "PPpp", { locale: es });
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const onSubmit = (data) => {
    const candidatoId = candidato.id;
    const userId = candidato.user.id;
    const dataConCandidatoId = {
      ...data,
      candidato_id: candidatoId,
      user_id: userId,
    };

    // Llamando a uploadCV con el nuevo objeto que incluye el documento y candidato_id
    const status = uploadCV(dataConCandidatoId);
    if (status) {
      reset({ documento: "" });
    }
  };

  const handleSelectCurriculum = (newCurriculum) => {
    actualizarDefaultCurriculum(newCurriculum.id);
  };

  return (
    <>
      <Navbar />
      <section className="relative lg:mt-24 mt-[74px]">
        <div className="lg:container container-fluid">
          <div className="relative shrink-0">
            <img
              src={bg5}
              className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700"
              alt=""
            />
          </div>

          <div className="md:flex ms-4 -mt-12">
            <div className="md:w-full">
              <div className="relative flex items-end justify-between">
                <div className="relative flex items-end">
                  <img
                    src={data?.image ? data?.image : image}
                    className="h-28 w-28 rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800"
                    alt=""
                  />
                  <div className="ms-4">
                    <h5 className="text-lg font-semibold">
                      {candidato?.user
                        ? candidato?.user?.name + " " + candidato?.user.surname
                        : "Sin nombre y/o apellidos conocidos"}
                    </h5>
                    {/* <p className="text-slate-400">{candidato && candidato?.cualificacion !== '' && candidato?.cualificacion !== undefined && candidato?.cualificacion !== null ? candidato?.cualificacion : "No existe cualificación registrada."}</p> */}
                  </div>
                </div>

                <div className="p-2 flex gap-4 items-center  rounded-md">
                  {candidato && candidato.adaptacion_puesto
                    ? ""
                    : "Completar perfil"}
                  <Link
                    to="/candidato-perfil-configuracion"
                    className="btn btn-icon rounded-full bg-primary-900/5 hover:bg-black border-primary/10 hover:border-primary hover:text-primary flex justify-center items-center"
                  >
                    <Settings className="h-4 w-4" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative mt-12">
        <div className="container md:pb-24 pb-16 ">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-8 md:col-span-7">
              <h5 className="text-xl font-semibold">
                {"Información sobre adaptación del puesto de trabajo:"}
              </h5>
              <p className="text-slate-400 mt-4">
                {candidato &&
                  candidato?.adaptacion_puesto !== "" &&
                  candidato?.adaptacion_puesto !== undefined &&
                  candidato?.adaptacion_puesto !== null
                  ? candidato?.adaptacion_puesto
                  : "No existe información sobre adaptación del puesto de trabajo."}
              </p>
              {/* <p className="text-slate-400 mt-4">Para empezar, sería genial tener un software de lectura de pantalla instalado en mi computadora; esto me permitiría acceder a los textos y correos electrónicos de manera autónoma. Además, un teclado con marcaciones en Braille me facilitaría enormemente la escritura y navegación. En cuanto al espacio físico, mantener el área de trabajo despejada y los caminos libres de obstáculos es crucial para que me pueda mover con seguridad y confianza. Y, si es posible, organizar los utensilios y documentos de manera que siempre estén en el mismo lugar me ayudaría a encontrarlos fácilmente. ¡Con estas adaptaciones, estoy seguro de que podré desempeñar mi trabajo de manera eficiente y cómoda!</p> */}

              <h4 className="mt-6 text-xl font-semibold">
                Empleos solicitados :
              </h4>

              <div>
                {candidato?.empleos?.slice(0,2).map((empleo, index) => (
                  <div className="flex mt-6" key={index}>
                    <div className="text-slate-400 font-semibold min-w-[80px] text-center">
                      <img
                        src={shree_logo}
                        className="h-16 w-16 mx-auto mb-2 block"
                        alt=""
                      />{' '}
                      {empleo.empleo.duracion_contrato}
                    </div>

                    <div className="ms-4">
                      <h5 className="text-lg font-medium mb-0">
                        {empleo.empleo.oferta_trabajo}
                      </h5>
                      <span className="text-slate-400 company-university">
                        {empleo.empleo.lugar_trabajo}
                      </span>
                      <p className="text-slate-400 mt-2 mb-0">
                        {empleo.empleo.funciones}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <h4 className="mt-6 text-xl font-semibold">
                Formaciones solicitadas :
              </h4>

              <div>
                {candidato?.cursos?.slice(0,2).map((curso, index) => (
                  <div className="flex mt-6" key={index}>
                    <div className="text-slate-400 font-semibold min-w-[80px] text-center">
                      <img
                        src={shree_logo}
                        className="h-16 w-16 mx-auto mb-2 block"
                        alt=""
                      />{' '}
                      {new Date(curso.curso?.fecha_inicio).toLocaleDateString('es-ES')}
                    </div>

                    <div className="ms-4">
                      <h5 className="text-lg font-medium mb-0">
                        {curso.curso?.name}
                      </h5>
                      <span className="text-slate-400 company-university">
                        {curso.curso?.ubicacion}
                      </span>
                      <p className="text-slate-400 mt-2 mb-0">
                        {curso.curso?.descripcion}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <h4 className="mt-6 text-xl font-semibold">
                Gestión de curriculums :
              </h4>
              <div className="grid lg:grid-cols-1 grid-cols-1 mt-6 gap-6">
                <div className="flex justify-center">
                  {" "}
                  {/* Añadido un div contenedor con flex y centrado */}
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ display: "flex" }}
                  >
                    <input type="file" {...register("documento")} />
                    <button
                      className="btn bg-primary-900 hover:bg-primary-900 border-primary dark:border-primary text-white rounded-md w-1/3 flex items-center justify-center" // Cambiado de w-full a w-1/3
                      type="submit"
                    >
                      <FiFileText className="me-2" /> Subir CV
                    </button>
                  </form>
                </div>
                <RadioGroup
                  value={selected}
                  onChange={(curriculum) => handleSelectCurriculum(curriculum)}
                  className="col-span-7"
                >
                  <RadioGroup.Label className="sr-only"></RadioGroup.Label>
                  <div className="-space-y-px rounded-md bg-white">
                    {candidato &&
                      candidato.curriculums &&
                      candidato.curriculums.length > 0 ? (
                      candidato.curriculums.map((curriculum, curriculumIdx) => (
                        <>
                          <div className="flex w-full items-center">
                            <div className="basis-4/5 w-full">
                              <RadioGroup.Option
                                key={curriculum.nombre}
                                value={curriculum}
                                className={({ checked }) =>
                                  classNames(
                                    curriculumIdx === 0
                                      ? "rounded-tl-md rounded-tr-md"
                                      : "",
                                    curriculumIdx ===
                                      candidato.curriculums.length - 1
                                      ? "rounded-bl-md rounded-br-md"
                                      : "",
                                    checked
                                      ? "z-10 border-gray-200 bg-gray-50"
                                      : "border-primary-900",
                                    "relative flex justify-between cursor-pointer border p-4 focus:outline-none"
                                  )
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <div className="flex">
                                      <span
                                        className={classNames(
                                          checked
                                            ? "bg-gray-900 border-transparent"
                                            : "bg-white border-gray-300",
                                          active
                                            ? "ring-2 ring-offset-2 ring-gray-600"
                                            : "",
                                          "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                        )}
                                        aria-hidden="true"
                                      >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                      </span>
                                      <span className="ml-3 flex flex-col">
                                        <RadioGroup.Label
                                          as="span"
                                          className={classNames(
                                            checked
                                              ? "text-gray-900"
                                              : "text-gray-900",
                                            "block text-sm font-medium mb-2"
                                          )}
                                        >
                                          &nbsp;&nbsp;{curriculum.nombre}{" "}
                                          {curriculum.is_default === 1 && (
                                            <span className="text-xs text-white bg-primary-900 rounded-full px-2 py-1">
                                              Principal
                                            </span>
                                          )}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                          as="span"
                                          className={classNames(
                                            checked
                                              ? "text-gray-700"
                                              : "text-gray-500",
                                            "block text-sm"
                                          )}
                                        >
                                          {formatDate(curriculum.created_at)}
                                        </RadioGroup.Description>
                                      </span>
                                    </div>
                                  </>
                                )}
                              </RadioGroup.Option>
                            </div>
                            <div className="basis-1/5" style={{ marginLeft: '2rem' }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-end",
                                  marginBottom: 15,
                                }}
                              >
                                {curriculum.id && <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                  key={curriculum.id}
                                >
                                  <button
                                    key={curriculum.id}
                                    className="btn btn-primary align-self-end gap-2"
                                    onClick={() =>
                                      window.open(
                                        `${PATH_URL}${curriculum.file_path}`, "_blank")
                                    }
                                  >
                                    Descargar
                                  </button>
                                </span>}
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                  key={curriculum.id}
                                >
                                  <button
                                    key={curriculum.id}
                                    className="btn bg-primary-900 hover:bg-primary-900 border-primary dark:border-primary text-white rounded-md w-1/3 flex items-center justify-center"
                                    style={{ marginLeft: '2rem' }}
                                    onClick={() => {
                                      setCurriculumId(curriculum.id);
                                      setOpen(true);
                                    }}
                                  >
                                    Eliminar
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <p>No hay curriculums subidos</p>
                    )}
                  </div>
                </RadioGroup>
              </div>

              {/* <div className="rounded-md shadow dark:shadow-gray-700 p-6 mt-6">
                                <h5 className="text-xl font-semibold">Contact Me:</h5>

                                <form>
                                    <div className="grid grid-cols-12 gap-2">
                                        <div className="md:col-span-6 col-span-12">
                                            <label htmlFor="name" className="font-semibold">Your Name:</label>
                                            <input name="name" id="name" type="text" className="form-input mt-2" placeholder="Name :" />
                                        </div>

                                        <div className="md:col-span-6 col-span-12">
                                            <label htmlFor="email" className="font-semibold">Your Email:</label>
                                            <input name="email" id="email" type="email" className="form-input mt-2" placeholder="Email :" />
                                        </div>

                                        <div className="col-span-12">
                                            <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                            <input name="subject" id="subject" className="form-input mt-2" placeholder="Subject :" />
                                        </div>

                                        <div className="col-span-12">
                                            <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                            <textarea name="comments" id="comments" className="form-input mt-2 textarea" placeholder="Message :"></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="btn bg-primary-900 hover:bg-primary-900 text-white rounded-md">Send Message</button>
                                </form>
                            </div> */}
            </div>

            <div className="lg:col-span-4 md:col-span-5">
              <div className="bg-slate-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-700 p-6 sticky top-20">
                <h5 className="text-lg font-semibold">Detalles Personales:</h5>
                <ul className="list-none mt-4">
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Mail className="h-4 w-4 text-slate-400 me-3 inline"></Mail>
                      <span className="text-slate-400 me-3">Email :</span>
                    </span>

                    <span>
                      {candidato?.user ? candidato?.user?.email : "Sin datos"}
                    </span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <CreditCard className="h-4 w-4 text-slate-400 me-3 inline"></CreditCard>
                      <span className="text-slate-400 me-3">NIF :</span>
                    </span>

                    <span>
                      {candidato?.user ? candidato?.user?.nif : "Sin datos"}
                    </span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Gift className="h-4 w-4 text-slate-400 me-3 inline"></Gift>
                      <span className="text-slate-400 me-3">Nacimiento :</span>
                    </span>

                    <span>
                      {candidato
                        ? candidato?.fecha_nacimiento
                        : "Sin fecha registrada"}
                    </span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Users className="h-4 w-4 text-slate-400 me-3 inline"></Users>
                      <span className="text-slate-400 me-3">Sexo :</span>
                    </span>

                    <span>
                      {candidato && candidato?.sexo
                        ? candidato?.sexo
                        : "sin datos"}
                    </span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Home className="h-4 w-4 text-slate-400 me-3 inline"></Home>
                      <span className="text-slate-400 me-3">Dirección :</span>
                    </span>

                    <span>
                      {candidato ? candidato?.direccion : "Sin datos"}
                    </span>
                  </li>

                  {/* <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Globe className="h-4 w-4 text-slate-400 me-3 inline"></Globe><span className="text-slate-400 me-3">País :</span></span>

                                        <span>UK</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">Código Postal :</span></span>

                                        <span>521452</span>
                                    </li> */}
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <Phone className="h-4 w-4 text-slate-400 me-3 inline"></Phone>
                      <span className="text-slate-400 me-3">Teléfono :</span>
                    </span>

                    <span>{candidato ? candidato?.telefono : "sin datos"}</span>
                  </li>
                  <hr className="mt-3" />
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <span className="text-slate-400 me-3">
                        {" "}
                        ¿Tiene discapacidad? :
                      </span>
                    </span>

                    <span>
                      {candidato && candidato?.tiene_discapacidad === 1 ? (
                        <Check className="h-4 w-4 text-slate-600 me-3 inline"></Check>
                      ) : (
                        <X className="h-4 w-4 text-slate-400 me-3 inline"></X>
                      )}
                    </span>
                  </li>

                  {candidato?.tiene_discapacidad === 1 && (
                    <>
                      {candidato?.discapacidad_inferior_33 === 1 && (
                        <li className="flex justify-between mt-3 items-center font-medium">
                          <span>
                            <span className="text-slate-400 me-3">
                              {" "}
                              ¿Es inferior al 33%? :
                            </span>
                          </span>

                          <span>
                            {candidato &&
                              candidato?.discapacidad_inferior_33 === 1 ? (
                              <Check className="h-4 w-4 text-slate-600 me-3 inline"></Check>
                            ) : (
                              <X className="h-4 w-4 text-slate-400 me-3 inline"></X>
                            )}
                          </span>
                        </li>
                      )}

                      {candidato?.discapacidad_superior_33 === 1 && (
                        <li className="flex justify-between mt-3 items-center font-medium">
                          <span>
                            <span className="text-slate-400 me-3">
                              {" "}
                              ¿Tiene una discapacidad superior al 33%? :
                            </span>
                          </span>

                          <span>
                            {candidato &&
                              candidato?.discapacidad_superior_33 === 1 ? (
                              <Check className="h-4 w-4 text-slate-600 me-3 inline"></Check>
                            ) : (
                              <X className="h-4 w-4 text-slate-400 me-3 inline"></X>
                            )}
                          </span>
                        </li>
                      )}

                      {candidato?.discapacidad_superior_65 === 1 && (
                        <li className="flex justify-between mt-3 items-center font-medium">
                          <span>
                            <span className="text-slate-400 me-3">
                              {" "}
                              ¿Tiene una discapacidad superior al 65%? :
                            </span>
                          </span>

                          <span>
                            {candidato &&
                              candidato?.discapacidad_superior_65 === 1 ? (
                              <Check className="h-4 w-4 text-slate-600 me-3 inline"></Check>
                            ) : (
                              <X className="h-4 w-4 text-slate-400 me-3 inline"></X>
                            )}
                          </span>
                        </li>
                      )}

                      <li className="flex justify-between mt-3 items-center font-medium">
                        <span>
                          <span className="text-slate-400 me-3">
                            {" "}
                            ¿Tipo de discapacidad? :
                          </span>
                        </span>

                        <span>
                          {candidato && candidato?.tipo_discapacidad
                            ? candidato?.tipo_discapacidad
                            : "sin datos"}
                        </span>
                      </li>
                      <li className="flex justify-between mt-3 items-center font-medium">
                        <span>
                          <span className="text-slate-400 me-3">
                            {" "}
                            Certificado de discapacidad :
                          </span>
                        </span>

                        <span>
                          {candidato && candidato?.certificado_discapacidad
                            ? <a
                              key={candidato.id}
                              className="btn btn-primary align-self-end gap-2"
                              href={`${PATH_URL}${candidato?.certificado_discapacidad}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Descargar
                            </a>
                            : "sin datos"}
                        </span>
                      </li>
                    </>
                  )}

                  <hr className="mt-3" />

                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <span className="text-slate-400 me-3">
                        {" "}
                        ¿Inscrito en bolsa de empleo? :
                      </span>
                    </span>

                    <span>
                      {candidato && candidato?.inscrito_bolsa === 1 ? (
                        <Check className="h-4 w-4 text-slate-600 me-3 inline"></Check>
                      ) : (
                        <X className="h-4 w-4 text-slate-400 me-3 inline"></X>
                      )}
                    </span>
                  </li>

                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <span className="text-slate-400 me-3">
                        {" "}
                        ¿Recibir información de empleos? :
                      </span>
                    </span>

                    <span>
                      {candidato && candidato?.info_ofertas_empleo === 1 ? (
                        <Check className="h-4 w-4 text-slate-600 me-3 inline"></Check>
                      ) : (
                        <X className="h-4 w-4 text-slate-400 me-3 inline"></X>
                      )}
                    </span>
                  </li>
                  <li className="flex justify-between mt-3 items-center font-medium">
                    <span>
                      <span className="text-slate-400 me-3">
                        {" "}
                        ¿Recibir información de cursos? :
                      </span>
                    </span>

                    <span>
                      {candidato && candidato?.info_ofertas_formacion === 1 ? (
                        <Check className="h-4 w-4 text-slate-600 me-3 inline"></Check>
                      ) : (
                        <X className="h-4 w-4 text-slate-400 me-3 inline"></X>
                      )}
                    </span>
                  </li>

                  {/* <li className="flex justify-between mt-3">
                                        <span className="text-slate-400 font-medium">Social:</span>

                                        <ul className="list-none ltr:text-right rtl:text-left space-x-0.5">
                                            <li className="inline"><Link to="https://dribbble.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 hover:text-white dark:text-white text-slate-400"><BiLogoDribbble/></Link></li>
                                            <li className="inline"><Link to="https://www.behance.net/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 hover:text-white dark:text-white text-slate-400"><AiOutlineBehance/></Link></li>
                                            <li className="inline"><Link to="http://linkedin.com/company/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 hover:text-white dark:text-white text-slate-400"><BiLogoLinkedin/></Link></li>
                                            <li className="inline"><Link to="https://www.facebook.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 hover:text-white dark:text-white text-slate-400"><FaFacebookF/></Link></li>
                                            <li className="inline"><Link to="https://www.instagram.com/shreethemes/" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 hover:text-white dark:text-white text-slate-400"><FaInstagram/></Link></li>
                                            <li className="inline"><Link to="https://twitter.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 hover:text-white dark:text-white text-slate-400"><IoLogoTwitter/></Link></li>
                                            <li className="inline"><Link to="mailto:support@shreethemes.in" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 hover:text-white dark:text-white text-slate-400"><LuMail/></Link></li>
                                        </ul>
                                    </li> */}

                  {/* <li className="mt-3 w-full bg-white dark:bg-slate-900 p-3 rounded-md shadow dark:shadow-gray-700">
                                        <div className="flex items-center mb-3">
                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                            <span className="font-medium ms-2">calvin-carlo-resume.pdf</span>
                                        </div>

                                        <Link to="assets/images/calvin-carlo-resume.pdf" className="btn bg-primary-900 hover:bg-primary-900 border-primary dark:border-primary text-white rounded-md w-full flex items-center justify-center" download><FiFileText className='me-2'/> Descargar CV</Link>
                                    </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <CandidateDetailComp /> */}
      </section >
      <section className="md:pb-24 pb-16">
        <ExploreJob />
      </section>
      <Footer />
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      {/* <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> */}
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Eliminar currículum?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          ¿Estás seguro de que quieres eliminar este curriculum?
                          Esta acción no se puede deshacer.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-primary-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-900 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        deleteCurriculum(curriculumId);
                        setCurriculumId(null);
                        setOpen(false);
                      }}
                    >
                      Sí, eliminar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
