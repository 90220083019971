import logo_light from "../assets/images/logo-white.webp";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks";
import { useNavigate } from "react-router-dom";

import logo_icon_40 from "../assets/images/logo.webp";
import logo_icon_40_white from "../assets/images/logo.webp";
import logo_dark from "../assets/images/logo.webp";
import logo_white from "../assets/images/logo.webp";
import image from "../assets/images/team/05.jpg";
import { LuSearch } from "../assets/icons/vander";
import {
  User,
  Settings,
  Lock,
  LogOut,
  LogIn,
  AlertTriangle,
  LifeBuoy,
  Bell,
  X,
  BookOpen,
  Folder,
} from "react-feather";
import useCandidate from "../pages/candidate/hooks/useCandidate";
import UseNotificaciones from "../pages/notificaciones/hooks/useNotificaciones";

export default function Navbar(props) {
  const [isDropdown, openDropdown] = useState(true);
  const [isDropdownNotifications, openDropdownNotifications] = useState(true);
  const { navClass, topnavClass, isContainerFluid } = props;
  const [isOpen, setMenu] = useState(true);

  const navigate = useNavigate();
  const { logout, userAuth } = useAuth();
  const { notificaciones, marcarComoLeido } = UseNotificaciones();

  const { candidato } = useCandidate();

  window.addEventListener("scroll", windowScroll);
  useEffect(() => {
    activateMenu();
  }, []);

  function windowScroll() {
    const navbar = document.getElementById("topnav");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      if (navbar !== null) {
        navbar?.classList.add("nav-sticky");
      }
    } else {
      if (navbar !== null) {
        navbar?.classList.remove("nav-sticky");
      }
    }

    const mybutton = document.getElementById("back-to-top");
    if (mybutton != null) {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        mybutton.classList.add("flex");
        mybutton.classList.remove("hidden");
      } else {
        mybutton.classList.add("hidden");
        mybutton.classList.remove("flex");
      }
    }
  }

  const toggleMenu = () => {
    setMenu(!isOpen);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation").getElementsByTagName("a")
      );
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        });
      });
    }
  };

  const getClosest = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  };

  const activateMenu = () => {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");

        var immediateParent = getClosest(matchingMenuItem, "li");

        if (immediateParent) {
          immediateParent.classList.add("active");
        }

        var parent = getClosest(immediateParent, ".child-menu-item");
        if (parent) {
          parent.classList.add("active");
        }

        var parent = getClosest(parent || immediateParent, ".parent-menu-item");

        if (parent) {
          parent.classList.add("active");

          var parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }

          var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          var parentOfParent = getClosest(
            matchingMenuItem,
            ".parent-parent-menu-item"
          );
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  // let redireccion;
  // switch (entidad) {
  //     case "Candidate":
  //       redireccion = "/candidate-profile/${notificacion.id}";
  // }

  return (
    <nav id="topnav" className={`defaultscroll is-sticky ${topnavClass}`}>
      <div
        className={`${
          isContainerFluid === true
            ? "container-fluid md:px-8 px-3"
            : "container"
        }`}
      >
        <Link className="logo" to="/">
          <div className="block sm:hidden box-border">
            <img
              src={logo_icon_40}
              className="h-10 inline-block dark:hidden"
              alt="Fepamic Empleo y Formación"
            />
            <img
              src={logo_icon_40_white}
              className="h-10 hidden dark:inline-block"
              alt="Fepamic Empleo y Formación"
            />
          </div>

          {navClass && navClass.includes("nav-light") ? (
            <div className="sm:block hidden box-border">
              <span className="inline-block dark:hidden">
                <img
                  src={logo_dark}
                  className="h-16 l-dark"
                  alt="Fepamic Empleo y Formación"
                />
                <img
                  src={logo_light}
                  className="h-16 l-light"
                  alt="Fepamic Empleo y Formación"
                />
              </span>
              <img
                src={logo_white}
                className=" hidden dark:inline-block"
                alt="Fepamic Empleo y Formación"
              />
            </div>
          ) : (
            <div className="sm:block hidden">
              <img
                src={logo_dark}
                className="h-16 inline-block dark:hidden"
                alt="Fepamic Empleo y Formación"
              />
              <img
                src={logo_white}
                className=" hidden dark:inline-block"
                alt="Fepamic Empleo y Formación"
              />
            </div>
          )}
        </Link>

        <div className="menu-extras">
          <div className="menu-item">
            <Link
              to="#"
              className="navbar-toggle"
              id="isToggle"
              onClick={toggleMenu}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>
        {/* <!-- End Mobile Toggle --> */}

        {/* <!--Login button Start--> */}
        <ul className="buy-button list-none mb-0">
          {userAuth !== undefined && userAuth !== null && (
            <li className="inline-block mb-0">
              <button
                onClick={() => {
                  openDropdownNotifications(!isDropdownNotifications);
                  openDropdown(true);
                }}
                data-dropdown-toggle="dropdown-notifications"
                className="dropdown-toggle btn btn-icon rounded-full bg-white border-primary hover:bg-primary hover:border-primary text-white inline-flex"
                type="button"
              >
                {isDropdownNotifications ? (
                  <>
                    <Bell className="w-5 h-5 text-primary" />
                  </>
                ) : (
                  <>
                    <X className="w-5 h-5 text-primary" />
                  </>
                )}
              </button>

              <div
                style={{ right: "2.5rem" }}
                className={`dropdown-menu absolute m-0 mt-4 z-10 w-60 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 ${
                  isDropdownNotifications ? "hidden" : "block"
                }`}
              >
                <ul className="text-start">
                  {notificaciones && notificaciones.length > 0 ? (
                    notificaciones
                      .filter((notificacion) => !notificacion.leido)
                      .map((notificacion) => (
                        <li className="" key={notificacion.id}>
                          <Link
                            // to={"#"}
                            to={notificacion.tipo_notificacion.ruta}
                            className="flex items-center font-medium p-4 border-b dark:text-white/70 hover:text-white dark:hover:text-white false hover:bg-primary-600"
                            onClick={(id) =>
                              marcarComoLeido(notificacion.id, {
                                leido: 1,
                              })
                            }
                          >
                            {notificacion.tipo_notificacion.nombre}
                          </Link>
                        </li>
                      ))
                  ) : (
                    <li className="px-4 py-2">
                      No hay notificaciones pendientes
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}

          {userAuth !== undefined && userAuth !== null ? (
            <li className="dropdown inline-block relative ps-1">
              <button
                onClick={() => {
                  openDropdown(!isDropdown);
                  openDropdownNotifications(true);
                }}
                data-dropdown-toggle="dropdown"
                className="dropdown-toggle btn btn-icon rounded-full bg-primary-900 hover:bg-primary-900 border-primary hover:border-primary text-white inline-flex"
                type="button"
              >
                <img
                  src={image}
                  className="rounded-full"
                  alt="Fepamic Empleo y Formación"
                />
              </button>

              <div
                className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-44 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 ${
                  isDropdown ? "hidden" : "block"
                }`}
              >
                <ul className="py-2 text-start">
                  <li>
                    <Link
                      to="/candidato-perfil"
                      className={`flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-primary dark:hover:text-white ${
                        !candidato?.adaptacion_puesto && "bg-slate-100"
                      } hover:bg-slate-100`}
                    >
                      {!candidato?.adaptacion_puesto ? (
                        <AlertTriangle className="h-4 w-4 me-2" />
                      ) : (
                        <User className="h-4 w-4 me-2" />
                      )}
                      {!candidato?.adaptacion_puesto
                        ? "Completar perfil"
                        : "Perfil"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/candidato-perfil-configuracion"
                      className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-primary dark:hover:text-white hover:bg-slate-100"
                    >
                      <Settings className="h-4 w-4 me-2" />
                      Ajustes
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/candidato-perfil/cursos"
                      className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-primary dark:hover:text-white hover:bg-slate-100"
                    >
                      <BookOpen className="h-4 w-4 me-2" />
                      Cursos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/candidato-perfil/empleos"
                      className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-primary dark:hover:text-white hover:bg-slate-100"
                    >
                      <Folder className="h-4 w-4 me-2" />
                      Empleos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/soporte"
                      className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-primary dark:hover:text-white hover:bg-slate-100"
                    >
                      <LifeBuoy className="h-4 w-4 me-2" />
                      Soporte
                    </Link>
                  </li>
                  <li className="border-t border-gray-100 dark:border-gray-800 my-2"></li>
                  {/* <li>
                    <Link
                      to="/lock-screen"
                      className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-primary dark:hover:text-white hover:bg-slate-100"
                    >
                      <Lock className="h-4 w-4 me-2" />
                      Bloquear
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      onClick={() => handleLogout()}
                      to="/?logout=success"
                      className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-primary dark:hover:text-white hover:bg-slate-100"
                    >
                      <LogOut className="h-4 w-4 me-2" />
                      Cerrar sesión
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          ) : (
            <li className="inline-block " style={{ marginLeft: "10px" }}>
              <button
                onClick={handleLogin}
                data-dropdown-toggle="dropdown"
                className="dropdown-toggle btn rounded bg-primary-1000 border-primary hover:border-primary text-white inline-flex"
                type="button"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <LogIn className="h-4 w-4 me-2" />
                <span>Acceso Candidatos</span>
              </button>
            </li>
          )}
        </ul>
        {/* <!--End Login button Start--> */}

        <div
          id="navigation"
          className={`${isOpen === true ? "hidden" : "block"}`}
        >
          <ul className={`navigation-menu ${navClass}`}>
            <li className="has-submenu parent-menu-item">
              <Link to="/">Inicio</Link>
              {/* <span className="menu-arrow"></span> */}
              {/* <ul className="submenu">
                <li>
                  <Link to="/index" className="sub-menu-item">
                    Hero One
                  </Link>
                </li>
                <li>
                  <Link to="/index-two" className="sub-menu-item">
                    Hero Two
                  </Link>
                </li>
                <li>
                  <Link to="/index-three" className="sub-menu-item">
                    Hero Three
                  </Link>
                </li>
                <li>
                  <Link to="/index-four" className="sub-menu-item">
                    Hero Four
                  </Link>
                </li>
                <li>
                  <Link to="/index-five" className="sub-menu-item">
                    Hero Five
                  </Link>
                </li>
                <li>
                  <Link to="/index-six" className="sub-menu-item">
                    Hero Six{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/index-seven" className="sub-menu-item">
                    Hero Seven{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/index-eight" className="sub-menu-item">
                    Hero Eight{" "}
                    <span className="bg-yellow-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">
                      New
                    </span>
                  </Link>
                </li>
              </ul> */}
            </li>

            {/* <li className="has-submenu parent-menu-item">
              <Link to="/sobre-nosotros">Fepamic</Link>
            </li> */}

            <li className="has-submenu parent-menu-item">
              <Link to="/empleos">Empleos</Link>
            </li>

            {/* <li className="has-submenu parent-menu-item">
              <Link to="/bolsa-de-empleo">Bolsa de empleo</Link>
            </li> */}

            <li className="has-submenu parent-menu-item">
              <Link to="/cursos">Formación</Link>
            </li>

            {/* <li className="has-submenu parent-parent-menu-item">
              <Link to="#"> Empleos </Link>
              <span className="menu-arrow"></span>
              <ul className="submenu">
                <li>
                  <Link to="/job-categories" className="sub-menu-item">
                    Job Categories
                  </Link>
                </li>

                <li className="has-submenu parent-menu-item">
                  <Link to="#"> Job Grids </Link>
                  <span className="submenu-arrow"></span>
                  <ul className="submenu">
                    <li>
                      <Link to="/job-grid-one" className="sub-menu-item">
                        Job Grid One
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-grid-two" className="sub-menu-item">
                        Job Grid Two
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-grid-three" className="sub-menu-item">
                        Job Grid Three
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-grid-four" className="sub-menu-item">
                        Job Grid Four{" "}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="has-submenu parent-menu-item">
                  <Link to="#"> Job Lists </Link>
                  <span className="submenu-arrow"></span>
                  <ul className="submenu">
                    <li>
                      <Link to="/job-list-one" className="sub-menu-item">
                        Job List One
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-list-two" className="sub-menu-item">
                        Job List Two
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-list-three" className="sub-menu-item">
                        Job List Three
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-list-four" className="sub-menu-item">
                        Job List Four
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-list-five" className="sub-menu-item">
                        Job List Five{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-list-six" className="sub-menu-item">
                        Job List Six{" "}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="has-submenu parent-menu-item">
                  <Link to="#"> Job Detail </Link>
                  <span className="submenu-arrow"></span>
                  <ul className="submenu">
                    <li>
                      <Link to="/job-detail-one" className="sub-menu-item">
                        Job Detail One
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-detail-two" className="sub-menu-item">
                        Job Detail Two
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-detail-three" className="sub-menu-item">
                        Job Detail Three
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/job-apply" className="sub-menu-item">
                    Job Apply
                  </Link>
                </li>

                <li>
                  <Link to="/job-post" className="sub-menu-item">
                    Job Post{" "}
                  </Link>
                </li>

                <li>
                  <Link to="/career" className="sub-menu-item">
                    Career{" "}
                  </Link>
                </li>
              </ul>
            </li> */}

            {/* <li className="has-submenu parent-parent-menu-item">
              <Link to="#">Pages</Link>
              <span className="menu-arrow"></span>
              <ul className="submenu">
                <li>
                  <Link to="/aboutus" className="sub-menu-item">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="sub-menu-item">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/pricing" className="sub-menu-item">
                    Pricing{" "}
                  </Link>
                </li>

                <li className="has-submenu parent-menu-item">
                  <Link to="#">Employers</Link>
                  <span className="submenu-arrow"></span>
                  <ul className="submenu">
                    <li>
                      <Link to="/employer-list" className="sub-menu-item">
                        Employers
                      </Link>
                    </li>
                    <li>
                      <Link to="/employer-detail" className="sub-menu-item">
                        Employer Detail
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="has-submenu parent-menu-item">
                  <Link to="#">
                    Candidates{" "}
                    <span className="bg-yellow-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">
                      New
                    </span>
                  </Link>
                  <span className="submenu-arrow"></span>
                  <ul className="submenu">
                    <li>
                      <Link to="/candidate-list" className="sub-menu-item">
                        Candidates
                      </Link>
                    </li>
                    <li>
                      <Link to="/candidate-profile" className="sub-menu-item">
                        Candidate Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/candidate-profile-setting"
                        className="sub-menu-item"
                      >
                        Profile Settings{" "}
                        <span className="bg-yellow-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">
                          New
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="has-submenu parent-menu-item">
                  <Link to="#"> Helpcenter </Link>
                  <span className="submenu-arrow"></span>
                  <ul className="submenu">
                    <li>
                      <Link to="/helpcenter-overview" className="sub-menu-item">
                        Overview
                      </Link>
                    </li>
                    <li>
                      <Link to="/helpcenter-faqs" className="sub-menu-item">
                        FAQs
                      </Link>
                    </li>
                    <li>
                      <Link to="/helpcenter-guides" className="sub-menu-item">
                        Guides
                      </Link>
                    </li>
                    <li>
                      <Link to="/soporte" className="sub-menu-item">
                        Support
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="has-submenu parent-menu-item">
                  <Link to="#"> Blog </Link>
                  <span className="submenu-arrow"></span>
                  <ul className="submenu">
                    <li>
                      <Link to="/blogs" className="sub-menu-item">
                        {" "}
                        Blogs
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog-detail" className="sub-menu-item">
                        {" "}
                        Blog Detail
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="has-submenu parent-menu-item">
                  <Link to="#"> Auth Pages </Link>
                  <span className="submenu-arrow"></span>
                  <ul className="submenu">
                    <li>
                      <Link to="/login" className="sub-menu-item">
                        {" "}
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/signup" className="sub-menu-item">
                        {" "}
                        Signup
                      </Link>
                    </li>
                    <li>
                      <Link to="/reset-password" className="sub-menu-item">
                        {" "}
                        Forgot Password
                      </Link>
                    </li>
                    <li>
                      <Link to="/lock-screen" className="sub-menu-item">
                        {" "}
                        Lock Screen
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="has-submenu parent-menu-item">
                  <Link to="#"> Utility </Link>
                  <span className="submenu-arrow"></span>
                  <ul className="submenu">
                    <li>
                      <Link to="/terms" className="sub-menu-item">
                        Terms of Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy" className="sub-menu-item">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="has-submenu parent-menu-item">
                  <Link to="#"> Special </Link>
                  <span className="submenu-arrow"></span>
                  <ul className="submenu">
                    <li>
                      <Link to="/comingsoon" className="sub-menu-item">
                        {" "}
                        Coming Soon
                      </Link>
                    </li>
                    <li>
                      <Link to="/maintenance" className="sub-menu-item">
                        {" "}
                        Maintenance
                      </Link>
                    </li>
                    <li>
                      <Link to="/error" className="sub-menu-item">
                        {" "}
                        404!
                      </Link>
                    </li>
                    <li>
                      <Link to="/thankyou" className="sub-menu-item">
                        {" "}
                        Thank you
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}

            <li>
              <Link to="/blog" className="sub-menu-item">
                Blog
              </Link>
            </li>
            <li>
              <Link to="/contacto" className="sub-menu-item">
                Contacto
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
