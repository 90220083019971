// import React from 'react'
// import { HelpCircle } from 'react-feather'


// export default function QuesAnswer() {
//     const QuesAnswerData = [
//         {
//             question: 'How our Jobstack work ?',
//             answer: 'Due to its widespread use as filler text for layouts, non-readability is of great importance: human perception is tuned to recognize certain patterns and repetitions in texts.',
//         },
//         {
//             question: 'What is the main process open account ?',
//             answer: 'words is random, the reader will not be distracted from making a neutral judgement on the visual impact.',
//         },
//         {
//             question: 'How to make unlimited data entry ?',
//             answer: 'Furthermore, it is advantageous when the dummy text is relatively realistic so that the layout impression of the final publication is not compromised.',
//         },
//         {
//             question: 'Is Jobstack safer to use with my account ?',
//             answer: "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin.",
//         }
//     ]
//     return (
//         <div className="container md:mt-24 mt-16">
//             <div className="grid grid-cols-1 pb-8 text-center">
//                 <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Questions & Answers</h3>

//                 <p className="text-slate-400 max-w-xl mx-auto">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>
//             </div>


//             <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
//                 {QuesAnswerData.map((item, index) => (
//                     <div className="flex" key={index}>
//                         <HelpCircle className="h-8 text-primary me-3" ></HelpCircle>
//                         <div className="flex-1">
//                             <h5 className="mb-2 text-lg font-semibold">{item.question}<span className="text-primary"></span> </h5>
//                             <p className="text-slate-400">{item.answer}</p>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     )
// }

import React from 'react'
import { HelpCircle } from 'react-feather'

export default function QuesAnswer() {
    const QuesAnswerData = [
        {
            question: '¿Cómo funciona Aicor empleo?',
            answer: 'Debido a su uso generalizado como texto de relleno para diseños, la no legibilidad es de gran importancia: la percepción humana está sintonizada para reconocer ciertos patrones y repeticiones en los textos.',
        },
        {
            question: '¿Cuál es el proceso principal para abrir una cuenta?',
            answer: 'Si las palabras son aleatorias, el lector no se distraerá de hacer un juicio neutral sobre el impacto visual.',
        },
        {
            question: '¿Cómo hacer entradas de datos ilimitadas?',
            answer: 'Además, es ventajoso cuando el texto ficticio es relativamente realista para que la impresión del diseño de la publicación final no se vea comprometida.',
        },
        {
            question: '¿Es seguro usar Aicor empleo con mi cuenta?',
            answer: "El texto ficticio más conocido es el 'Lorem Ipsum', que se dice que se originó en el siglo 16. Lorem Ipsum está compuesto en un pseudo-idioma latino que más o menos corresponde al latín 'correcto'.",
        }
    ]
    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Preguntas & Respuestas</h3>

                <p className="text-slate-400 max-w-xl mx-auto">Busca todas las posiciones abiertas en la web. Obtén tu propia estimación salarial personalizada. Lee reseñas sobre más de 30000+ empresas en todo el mundo.</p>
            </div>


            <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                {QuesAnswerData.map((item, index) => (
                    <div className="flex" key={index}>
                        <HelpCircle className="h-8 text-primary me-3" ></HelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-lg font-semibold">{item.question}<span className="text-primary"></span> </h5>
                            <p className="text-slate-400">{item.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
