import * as yup from 'yup';

export const validationCreateCandidato = yup
.object({
    fecha_nacimiento: yup
    .string('Introduce una fecha de nacimiento')
    .required('La fecha de nacimiento es obligatoria'),
    discapacidad_superior_33: yup
    .boolean(''),
    inscrito_bolsa: yup
    .boolean(),
    info_ofertas_empleo: yup
    .boolean(),
    info_ofertas_formacion: yup
    .boolean(),
    cualificacion: yup
    .string('Introduce una cualificación')
    .required('La cualificación es obligatoria'),
    
    name: yup
    .string('Introduce un nombre')
    .required('El nombre es obligatorio'),
    surnames: yup
    .string('Introduce unos apellidos')
    .required('Los apellidos son obligatorios'),
    nif: yup
    .string('Introduce un NIF')
    .required('El NIF es obligatorio'),
    email: yup
    .string('Introduce un email')
    .email('Introduce un email válido')
    .required('El email es obligatorio'),
    password: yup
    .string('Introduce una contraseña')
    .required('La contraseña es obligatoria'),
})

export const validationEditCandidato = yup
.object({
    fecha_nacimiento: yup
    .string('Introduce una fecha de nacimiento')
    .required('La fecha de nacimiento es obligatoria'),
    discapacidad_superior_33: yup
    .boolean(''),
    inscrito_bolsa: yup
    .boolean(),
    info_ofertas_empleo: yup
    .boolean(),
    info_ofertas_formacion: yup
    .boolean(),
    cualificacion: yup
    .string('Introduce una cualificación')
    .required('La cualificación es obligatoria'),
    
    name: yup
    .string('Introduce un nombre')
    .required('El nombre es obligatorio'),
    surnames: yup
    .string('Introduce unos apellidos')
    .required('Los apellidos son obligatorios'),
    nif: yup
    .string('Introduce un NIF')
    .required('El NIF es obligatorio'),
    email: yup
    .string('Introduce un email')
    .email('Introduce un email válido')
    .required('El email es obligatorio'),
    
})