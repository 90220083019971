import React, { useState, useEffect } from 'react'
import { useRecoilState } from "recoil";
import useFetch from '../../../hooks/useFecth';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import {
    candidatosAtom,
    candidatosMetaAtom,
    candidatoSelectedAtom,
} from "../../../stores/candidatos-store";
import useFecthFormData from '../../../hooks/useFecthFormData';

const initialLoading = {
    get: false,
    create: false,
};

export default function useCandidato() {
    const PATH_URL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const candidatoFecth = useFetch();
    const [loading, setLoading] = useState(initialLoading);
    const [candidatos, setCandidatos] = useRecoilState(candidatosAtom);
    const [candidatosMeta, setCandidatosMeta] = useRecoilState(candidatosMetaAtom);
    const [candidatoSelected, setCandidatoSelected] = useRecoilState(candidatoSelectedAtom);
    const candidatoFecthFormData = useFecthFormData();


    function createCandidato (data){
        setLoading({ ...loading, create: true });
        return candidatoFecthFormData.post("candidatos-public", data)
        .then(({data, status}) => {
            setLoading({ ...loading, create: false });
            if (status === "error"){
                toast.error(data,{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            }else{
                toast.success(data,{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
               
                /**
                 * TODO: aqui va un navigate a la página de mensaje ok indicando que se le enviará un correo electrónico
                 */
                navigate("/confirmacion-registro");
                return true;
            }
        })
    }

  return {
    loading,
    createCandidato,
  }
}
