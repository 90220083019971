import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import JobDetailComp from '../../components/job-detail-comp'
import Footer from '../../components/Footer'
import { Link, useParams } from "react-router-dom";
import { Calendar, Briefcase, Clock, DollarSign, MapPin, Monitor, Bookmark } from 'react-feather';
import { MdOutlineArrowForward } from "react-icons/md"
import ExploreJob from '../../components/Explore-job';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import useCourses from './hooks/useCourses';

export default function CourseDetail() {
    const { id } = useParams();
    const { curso, showCurso } = useCourses();


    useEffect(() => {
        showCurso(id);
    }, [id])


    const fecha = curso.updated_at !== undefined && parseISO(curso?.updated_at);
    const resultado = fecha && formatDistanceToNow(fecha, { addSuffix: true, locale: es });


    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-primary-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h1 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">{curso?.name}</h1>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Inicio</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/cursos">Cursos</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">{curso?.name}</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-4 md:col-span-6">
                            <div className="shadow dark:shadow-gray-700 rounded-md bg-white dark:bg-slate-900 top-20 mb-6">
                                <div className="p-6">
                                    <h5 className="text-lg font-semibold">Empresa responsable</h5>
                                </div>
                                <div className="p-6 border-t border-slate-100 dark:border-t-gray-700">
                                    <ul className="list-none">
                                        <li className="flex items-center">
                                            <Briefcase className="h-5 w-5" />
                                            <div className="ms-4">
                                                <p className="font-medium">{curso?.empresa_fepamic?.razon_social}</p>
                                                {/* <span className="text-primary font-medium text-sm">{curso?.tipo_contrato?.charAt(0).toUpperCase() + curso?.tipo_contrato?.slice(1)}, </span> */}
                                            </div>
                                        </li>
                                        <li className="flex items-center">
                                            <div className="ms-4 flex flex-col">
                                                <p className='ms-4 text-sm py-3'>{curso?.empresa_fepamic?.persona_contacto}</p>
                                                <a href={`tel:${curso?.empresa_fepamic?.tlf_persona_contacto}`} className='ms-4 text-sm py-2 w-full'>{curso?.empresa_fepamic?.tlf_persona_contacto}</a>
                                                <a href={`mailto:${curso?.empresa_fepamic?.email_contacto}`} className='ms-4 text-sm py-2 w-full'>{curso?.empresa_fepamic?.email_contacto}</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="shadow dark:shadow-gray-700 rounded-md bg-white dark:bg-slate-900 sticky top-20">
                                <div className="p-6">
                                    <h5 className="text-lg font-semibold">Resumen del curso</h5>
                                </div>
                                <div className="p-6 border-t border-slate-100 dark:border-t-gray-700">
                                    <ul className="list-none">
                                        <li className="flex items-center">
                                            <Clock className="h-5 w-5"></Clock>

                                            <div className="ms-4">
                                                <p className="font-medium">Fecha de inicio:</p>
                                                <span className="text-primary font-medium text-sm">{curso?.fecha_inicio}</span>
                                            </div>
                                        </li>
                                        <li className="flex items-center mt-3">
                                            <Bookmark className="h-5 w-5"></Bookmark>

                                            <div className="ms-4">
                                                <p className="font-medium">Inscripción:</p>
                                                <span className="text-primary font-medium text-sm">{curso?.fecha_inicio_inscripcion} </span>
                                            </div>
                                        </li>

                                        <li className="flex items-center mt-3">
                                            <MapPin className="h-5 w-5"></MapPin>

                                            <div className="ms-4">
                                                <p className="font-medium">Lugar:</p>
                                                <span className="text-primary font-medium text-sm">{curso?.ubicacion}</span>
                                            </div>
                                        </li>

                                        <li className="flex items-center mt-3">
                                            <Monitor className="h-5 w-5"></Monitor>

                                            <div className="ms-4">
                                                <p className="font-medium">Modalidad:</p>
                                                <span className="text-primary font-medium text-sm">{curso?.modalidad}</span>
                                            </div>
                                        </li>

                                        <li className="flex items-center mt-3">
                                            <Briefcase className="h-5 w-5"></Briefcase>

                                            <div className="ms-4">
                                                <p className="font-medium">Duración:</p>
                                                <span className="text-primary font-medium text-sm">{curso?.duracion}</span>
                                            </div>
                                        </li>

                                        <li className="flex items-center mt-3">
                                            <Calendar className="h-5 w-5"></Calendar>

                                            <div className="ms-4">
                                                <p className="font-medium">Publicación:</p>
                                                <span className="text-primary font-medium text-sm">{resultado && (resultado?.charAt(0).toUpperCase() + resultado?.slice(1))}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-8 md:col-span-6">

                            <h5 className="text-lg font-semibold">Descripción del curso:</h5>

                            <p className="text-slate-400 mt-4">{curso?.descripcion}</p>

                            <h5 className="text-lg font-semibold mt-6">Requisitos: </h5>
                            <p className="text-slate-400 mt-4">Los requisitos para este curso son:</p>
                            <ul className="list-none flex flex-col">
                                {curso?.experiencia_laboral_minima !== "" && <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />{curso?.requisitos}</li>}
                                {curso?.experiencia_laboral_minima !== "" && <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Cualificación mínima: {curso?.cualificacion_minima}</li>}
                            </ul>

                            <div className="mt-5">
                            <Link to={`solicitud`} className="btn rounded-md bg-primary-900 hover:bg-primary-900 border-primary hover:border-primary text-white md:ms-2 w-full md:w-auto">Inscribirme en el curso</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <JobDetailComp /> */}
                <ExploreJob />
            </section>
            <Footer />
        </>
    )
}