import { atom } from "recoil";

export const empleosAtom = atom({
    key: "empleosAtom",
    default: [],
});

export const empleosMetaAtom = atom({
    key: "empleosMetaAtom",
    default: [],
});

export const empleosSelectedAtom = atom({
    key: "empleosSelectedAtom",
    default: {},
});

export const empleosFiltersAtom = atom({
    key: "empleosFiltersAtom",
    default: {},
})

export const empleosCategoriasAtom = atom({
    key: "empleosCategoriasAtom",
    default: [],
})