import React, { useRef, useState, Fragment } from "react";
import UseSolicitudesEmpleo from "./hooks/useSolicitudesEmpleo";
import { Link } from "react-router-dom";
import { BookOpen } from "react-feather";
import { AiOutlineClockCircle } from "../../assets/icons/vander";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { useForm } from "react-hook-form";
import { Delete, Filter, X } from "react-feather";
import { Dialog, Transition } from "@headlessui/react";
import useCourses from "../course-list/hooks/useCourses";

const EmpleoList = () => {
  const { register, reset, setValue, handleSubmit } = useForm();
  const { solicitudes, filters, listSolicitudesByUser, setFilters } =
    UseSolicitudesEmpleo();
  const [open, setOpen] = useState(false);
  const [selectedCurso, setSelectedCurso] = useState(null);
  const { cursos } = useCourses();

  const cancelButtonRef = useRef(null);

  const estadoColor = (item) => {
    switch (item?.aceptado) {
      case 1:
        return { color: "bg-green-400", texto: "Aceptado" };
      case 0:
        return {
          color: "bg-red-600",
          texto: "Rechazado/Pendiente de revisión",
        };
      default:
        return "bg-gray-500";
    }
  };

  const handleResetFilters = () => {
    const auxFilters = {
      fecha_inicio: "",
      fecha_fin: "",
      aceptado: "",
    };

    listSolicitudesByUser({ ...auxFilters });

    reset({});
  };

  const onSubmit = (data) => {
    listSolicitudesByUser({
      ...filters,
    });
  };

  const handleOpen = (item) => {
    setOpen(true);
    setSelectedCurso(item);
  };

  function transformarNumero(numero) {
    if (numero === 1) {
      return "Sí";
    } else if (numero === 0) {
      return "No";
    } else {
      return "Valor no válido";
    }
  }

  const handleEstadoChange = (aceptado) => {
    setFilters({
      ...filters,
      aceptado: aceptado,
    });
  };

  return (
    <>
      <Navbar />
      <section className="relative lg:mt-24 mt-[74px] pb-16">
        <div className="container">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid lg:grid-cols-5 md:grid-cols-3 gap-2 place-items-center bg-primary-900 p-4 rounded-lg shadow-md"
          >
            <div className="filter-search-form relative">
              <label
                for="fecha_inicio"
                class="block text-gray-700 text-sm font-bold mb-2"
              >
                Fecha de inicio:
              </label>
              <input
                type="date"
                id="fecha_inicio"
                name="fecha_inicio"
                {...register("fecha_inicio")}
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              />
            </div>
            <div className="filter-search-form relative">
              <label
                for="fecha_fin"
                class="block text-gray-700 text-sm font-bold mb-2"
              >
                Fecha de fin:
              </label>
              <input
                type="date"
                id="fecha_fin"
                {...register("fecha_fin")}
                name="fecha_fin"
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              />
            </div>
            <div className="filter-search-form relative">
              <label
                htmlFor="estado"
                className="block text-sm font-bold mb-2 text-gray-700"
              >
                Estado:
              </label>
              <select
                {...register("estado")}
                className="appearance-none block w-full rounded py-4 px-4 leading-tight bg-white"
                onChange={(e) => handleEstadoChange(e.target.value)}
              >
                <option value="">Selecciona un estado</option>
                <option value={1}>Aceptado</option>
                <option value={0}>Rechazado</option>
              </select>
            </div>
            <button
              type="submit"
              className="flex gap-2 justify-center items-center btn bg-slate-900 hover:bg-white hover:text-black border-0 rounded-md text-white font-normal"
            >
              <Filter className="w-4 h-4" />
              Aplicar
            </button>
            <button
              type="button"
              onClick={handleResetFilters}
              className="flex gap-2 justify-center items-center btn bg-slate-900 hover:bg-white hover:text-black border-0 rounded-md text-white font-normal"
            >
              <Delete className="w-4 h-4" />
              Limpiar
            </button>
          </form>
        </div>
        <div className="container mt-10">
          <div className="grid grid-cols-1 gap-[30px] mt-5">
            {solicitudes.length > 0 ? (
              solicitudes.map((item, index) => (
                <div
                  className="group relative overflow-hidden md:flex justify-between items-center rounded shadow hover:shadow-md dark:shadow-gray-700 transition-all duration-500 p-5 "
                  onClick={() => handleOpen(item)}
                  key={index}
                >
                  <div className="flex items-center">
                    <p
                      to={`/soporte/${item.id}`}
                      className="flex gap-2 items-center text-lg hover:text-primary font-semibold transition-all duration-500 ms-3 min-w-[180px]"
                    >
                      <BookOpen className="w-5 h-5" />{" "}
                      {item?.empleo?.oferta_trabajo}
                    </p>
                  </div>

                  <div className="md:block flex justify-between md:mt-0 mt-6">
                    <span className="block">
                      <span className="bg-primary-900/10 inline-block text-primary text-xs py-0.5 font-semibold rounded-full">
                        {item.asunto}
                      </span>
                    </span>
                    <span className="flex items-center text-slate-400 text-sm md:mt-1 mt-0">
                      <AiOutlineClockCircle className="me-1" />
                      {format(new Date(item?.updated_at), "PPP 'a las' p", {
                        locale: es,
                      })}
                    </span>
                  </div>

                  <div className="md:mt-0 mt-4">
                    <div
                      className={`rounded-full border-primary/10 hover:border-primary text-primary md:relative absolute top-0 end-0 md:m-0 m-3 flex items-center justify-center`}
                    >
                      <span
                        className={`rounded-full text-white p-2 py-1 text-xs ${
                          estadoColor(item).color
                        }`}
                      >
                        {estadoColor(item).texto}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center py-40">
                <h1 className="text-xl font-medium">
                  No se encontraron solicitudes que coincidan con tus filtros de
                  búsqueda
                </h1>
                <p className="mt-2">
                  Restaura filtros y comienza una nueva búsqueda
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-overall"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 relative top-1/2 -translate-y-1/2">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  style={{ maxWidth: "448px" }}
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 w-full"
                >
                  <div>
                    <div
                      style={{ right: 0 }}
                      className="absolute top-0 pt-4 pr-4 cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <X size={20} />
                    </div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <BookOpen className="w-5 h-5" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {selectedCurso?.empleo?.oferta_trabajo}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p>{selectedCurso?.empleo?.lugar_trabajo}</p>
                        <p className="text-sm text-gray-500">
                          {selectedCurso?.empleo?.descripcion}
                        </p>
                        <p className="text-slate-500 mt-1">
                          Duración: {selectedCurso?.empleo?.duracion_contrato}
                        </p>
                        <p className="text-slate-500 mt-1">
                          Tipo de contrato:{" "}
                          {selectedCurso?.empleo?.tipo_contrato}
                        </p>
                        <p className="text-slate-500 mt-1">
                          Discapacidad: {selectedCurso?.discapacidad} %
                        </p>
                        <div
                          className={`rounded-full border-primary/10 hover:border-primary text-primary md:relative absolute top-0 end-0 md:m-0 m-3 flex items-center justify-center`}
                        >
                          <p
                            className={`rounded-full text-white p-2 py-1 text-xs mt-5 ${
                              estadoColor(selectedCurso).color
                            }`}
                          >
                            {estadoColor(selectedCurso).texto}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Footer />
    </>
  );
};

export default EmpleoList;
