import React from "react";
import { useForm } from "react-hook-form";
import useTickets from "../hooks/useTickets";
import { useAuth } from "../../../hooks";
import { PlusCircle } from "react-feather";

const CreateTicketForm = () => {
  const { register, reset, handleSubmit } = useForm();
  const { createTicket } = useTickets();
  const { userAuth } = useAuth();

  const onSubmit = (data) => {
    let aux = { ...data };
    aux.tipo_contacto = "Candidato";
    aux.contacto_id = userAuth?.candidato?.id;

    createTicket(aux);

    reset();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col mt-4 gap-4"
    >
      <input
        type="text"
        {...register("tema")}
        className="border border-slate-200 rounded-md p-3"
      />

      <textarea
        {...register("mensaje")}
        className="border border-slate-200 rounded-md p-3"
        rows={6}
      />

      <button
        type="submit"
        className="flex items-center justify-center btn btn-primary bg-primary-900 gap-2 text-white rounded-md"
      >
        <PlusCircle size={18} />
        Crear ticket
      </button>
    </form>
  );
};

export default CreateTicketForm;
