import React, { useCallback } from "react";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "../assets/icons/vander";
import Pagination from "../components/Pagination";

function PaginationCustom({ filters, getList, currentPage, setCurrentPage, totalPages }) {

  const prevData = useCallback(
    async (page) => {
      setCurrentPage(currentPage - 1);
      getList({ ...filters, page: page });
    },
    [currentPage, filters, getList]
  );

  const nextData = useCallback(
    async (page) => {
      setCurrentPage(currentPage + 1);
      getList({ ...filters, page: page });
    },
    [currentPage, filters, getList]
  );

  return (
    <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
      <div className="md:col-span-12 text-center">
        <nav aria-label="Page navigation example">
          <ul className="inline-flex items-center -space-x-px">
            <li>
              <span
                onClick={() =>
                  currentPage > 1 ? prevData(currentPage - 1) : null
                }
                className={`w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-primary dark:hover:border-primary hover:bg-primary-600 dark:hover:bg-primary-900 transition-colors ${
                  currentPage > 1 ? "cursor-pointer" : "cursor-not-allowed"
                }`}
              >
                <MdKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1" />
              </span>
            </li>

            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              updateCurrentPage={setCurrentPage}
            />

            <li>
              <span
                onClick={() => currentPage < totalPages ? nextData(currentPage + 1) : null}
                className={`w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-primary dark:hover:border-primary hover:bg-primary-600 dark:hover:bg-primary-900 ${
                    currentPage < totalPages ? "cursor-pointer" : "cursor-not-allowed"
                  }`}
              >
                <MdKeyboardArrowRight className="text-[20px] rtl:rotate-180 rtl:-mt-1" />
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default PaginationCustom;
