const voz = {
  //funcion para leer en voz alta el texto que se le pase
  //mute es un booleano que si es true no se escuchara el texto
  speak(text, mute) {
    if (!mute){
      const utterance = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(utterance);
    }
    
  },
};

export { voz };
