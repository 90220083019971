import { useState } from "react";

function Pagination({
  totalPages,
  currentPage,
  navigateToPage,
  updateCurrentPage,
}) {
  const handlePageClick = (page) => {
    updateCurrentPage(page);
  };

  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  return pages.map((page) => (
    <li key={page}>
      <span
        className={`w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-primary dark:hover:border-primary hover:bg-primary-600 dark:hover:bg-primary-900 cursor-pointer ${
          currentPage === page ? "bg-primary-900 text-white" : "bg-white"
        }`}
        onClick={() => handlePageClick(page)}
      >
        {page}
      </span>
    </li>
  ));
}

export default Pagination;
