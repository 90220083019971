import React from 'react';
import useTickets from './hooks/useTickets';
import { Link } from 'react-router-dom';
import { LifeBuoy } from 'react-feather';
import { AiOutlineClockCircle } from "../../assets/icons/vander";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const SupportList = () => {

    const estadoColor = (item) => {
        switch (item.estado) {
            case "Abierto":
                return { color: "bg-green-400", texto: "Abierto" };
            case "Cerrado":
                return { color: "bg-red-600", texto: "Cerrado" };
            case "En proceso":
                return { color: "bg-amber-500", texto: "En proceso" }
            default:
                return "bg-gray-500";
        }
    };

    const { ticketList } = useTickets();


    return (
        <>
            {ticketList.length > 0 ? ticketList.map((item, index) => (
                <Link
                    className="group relative overflow-hidden md:flex justify-between items-center rounded shadow hover:shadow-md dark:shadow-gray-700 transition-all duration-500 p-5 "
                    to={`/soporte/${item.id}`}
                    key={index}
                >
                    <div className="flex items-center">
                        <p
                            to={`/soporte/${item.id}`}
                            className="flex gap-2 items-center text-lg hover:text-primary font-semibold transition-all duration-500 ms-3 min-w-[180px]"
                        >
                            <LifeBuoy className="w-5 h-5" /> {item.tema}
                        </p>
                    </div>

                    <div className="md:block flex justify-between md:mt-0 mt-6">
                        <span className="block">
                            <span className="bg-primary-900/10 inline-block text-primary text-xs py-0.5 font-semibold rounded-full">
                                {item.asunto}
                            </span>
                        </span>
                        <span className="flex items-center text-slate-400 text-sm md:mt-1 mt-0">
                            <AiOutlineClockCircle className="me-1" />
                            {format(new Date(item.updated_at), "PPP 'a las' p", {
                                locale: es,
                            })}
                        </span>
                    </div>

                    <div className="md:mt-0 mt-4">
                        <div
                            className={`rounded-full border-primary/10 hover:border-primary text-primary md:relative absolute top-0 end-0 md:m-0 m-3 flex items-center justify-center`}
                        >
                            <span
                                className={`rounded-full text-white p-2 py-1 text-xs ${estadoColor(item).color}`}
                            >
                                {estadoColor(item).texto}
                            </span>
                        </div>
                    </div>
                </Link>
            )) : (
                <div className="text-center py-40">
                    <h1 className="text-xl font-medium">No se encontraron tickets que coincidan con tus filtros de búsqueda</h1>
                    <p className="mt-2">Restaura filtros y comienza una nueva búsqueda</p>
                </div>
            )}
        </>
    );
}

export default SupportList;
