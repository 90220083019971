import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  MdOutlineArrowForward,
  MdOutlineCalendarMonth,
  AiOutlineClockCircle,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "../../assets/icons/vander";
import useBlog from "./hooks/useBlog";
import { generarSlug } from "../../utils/slug";
import { calcularTiempoLectura } from "../../utils";
import Pagination from "../../components/Pagination";
import { BiCameraOff } from "../../assets/icons/vander";

export default function PostsList() {
  const BASE_URL = process.env.REACT_APP_PUBLIC_API_URL;

  const { loading, posts, getPosts, totalPages, handlePostSelect } = useBlog();

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prevData = useCallback(
    async (page) => {
      getPosts(page);
      setCurrentPage(currentPage - 1);
    },
    [loading, currentPage]
  );

  const nextData = useCallback(
    async (page) => {
      getPosts(page);
      setCurrentPage(currentPage + 1);
    },
    [loading, currentPage]
  );

  return (
    <>
      <section className="relative md:py-24 py-16">
        <div className="container">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
            {posts.length > 0 &&
              posts.map((item, index) => (
                <div
                  key={index}
                  className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700"
                >
                  <div className="relative overflow-hidden flex">
                    {item.imagen !== null ? (
                      <img
                        src={`${BASE_URL}${item.imagen}`}
                        className="scale-110 group-hover:scale-100 transition-all duration-500 w-full object-cover"
                        style={{ height: "200px" }}
                        alt={item.titulo}
                      />
                    ) : (
                      <div
                        className="w-full flex justify-center items-center bg-slate-200"
                        style={{ height: "200px" }}
                      >
                        <BiCameraOff color="#666" size={34} />
                      </div>
                    )}
                  </div>

                  <div className="relative p-6">
                    <div className="absolute start-6 -top-4">
                      <span className="bg-primary-900 text-white text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">
                        {item.categoria.nombre}
                      </span>
                    </div>

                    <div className="">
                      <div className="flex mb-4">
                        <span className="text-slate-400 text-sm flex items-center">
                          <MdOutlineCalendarMonth className="text-slate-900 dark:text-white me-2" />
                          {item.created_at}
                        </span>
                        <span className="text-slate-400 text-sm ms-3 flex items-center">
                          <AiOutlineClockCircle className="text-slate-900 dark:text-white me-2" />
                          {`${calcularTiempoLectura(item?.texto)} minuto(s)`}
                        </span>
                      </div>

                      <Link
                          to={`${generarSlug(item.titulo)}`}
                        onClick={() => handlePostSelect(item.id)}
                        className="title text-lg font-semibold hover:text-primary duration-500 ease-in-out"
                      >
                        {item.titulo}
                      </Link>

                      <div className="flex justify-between items-center mt-3">
                        <Link
                          to={`${generarSlug(item.titulo)}`}
                          onClick={() => handlePostSelect(item.id)}
                          className="btn btn-link hover:text-primary after:bg-primary-900 duration-500 ease-in-out inline-flex items-center"
                        >
                          Leer más <MdOutlineArrowForward className="ms-1" />
                        </Link>
                        {/* <span className="text-slate-400 text-sm">by <Link to="#" className="text-slate-900 dark:text-white hover:text-primary dark:hover:text-primary font-medium">{item.powerdby}</Link></span> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* PAGINACION */}
          <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
            <div className="md:col-span-12 text-center">
              <nav aria-label="Page navigation example">
                <ul className="inline-flex items-center -space-x-px">
                  <li>
                    <span
                      onClick={() =>
                        currentPage > 1 ? prevData(currentPage - 1) : null
                      }
                      className={`w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-primary dark:hover:border-primary hover:bg-primary-600 dark:hover:bg-primary-900 transition-colors ${
                        currentPage > 1
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                      }`}
                    >
                      <MdKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1" />
                    </span>
                  </li>

                  {totalPages > 0 ? (
                    <Pagination
                      totalPages={totalPages}
                      currentPage={currentPage}
                      navigateToPage={getPosts}
                      updateCurrentPage={setCurrentPage}
                    />
                  ) : (
                    <li>
                      <span className="w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-primary-900 dark:bg-slate-900  hover:text-white border border-gray-100 dark:border-gray-800 hover:border-primary dark:hover:border-primary hover:bg-primary-600 dark:hover:bg-primary-900 cursor-pointer">
                        1
                      </span>
                    </li>
                  )}

                  <li>
                    <span
                      onClick={() =>
                        totalPages > 0 ? nextData(currentPage + 1) : null
                      }
                      className={`w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-primary dark:hover:border-primary hover:bg-primary-600 dark:hover:bg-primary-900 ${
                        totalPages > 0 ? "cursor-pointer" : "cursor-not-allowed"
                      }`}
                    >
                      <MdKeyboardArrowRight className="text-[20px] rtl:rotate-180 rtl:-mt-1" />
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
