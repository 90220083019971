// propios
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { voz } from "../../utils/voz";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import LatestPosts from "../blog/components/LatestPosts";

// plantilla
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import Select from "react-select";
import ModalVideo from "react-modal-video";

import Navbar from "../../components/Navbar";
import TrendingService from "../../components/Trending-service";
import Popularjobstwo from "../../components/Popular-Jobs-two";
import BestCompanies from "../../components/Best-companies";
import QuesAnswer from "../../components/Question-Answer";
import ExploreJob from "../../components/Explore-job";
import Footer from "../../components/Footer";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

import ab05 from "../../assets/images/about/ab05.jpg";

import {
  BiBriefcaseAlt2,
  PiMapPin,
  BiCheckCircle,
} from "../../assets/icons/vander";
import { Search } from "react-feather";

const optionsOne = [
  { value: "AF", label: "Afganistán" },
  { value: "AZ", label: "Azerbaiyán" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Baréin" },
  { value: "CA", label: "Canadá" },
  { value: "CV", label: "Cabo Verde" },
  { value: "DK", label: "Dinamarca" },
  { value: "DJ", label: "Yibuti" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "GM", label: "Gambia" },
];

export default function IndexTwo() {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const [busqueda, setBusqueda] = useState("");
  const navigate = useNavigate();
  const speak = voz.speak;
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { error },
  } = useForm();

  const search = watch("search");

  useEffect(() => {
    // Crear un objeto URLSearchParams basado en la cadena de consulta actual
    const queryParams = new URLSearchParams(window.location.search);
    const toastParam = queryParams.get("toast");
    const logoutParam = queryParams.get("logout");

    if (toastParam) {
      // Aquí puedes hacer algo más si `toast=success`
      if (toastParam === "success") {
        speak("Has accedido correctamente", true);
        toast.success("¡Bien! Has accedido correctamente", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: "bounce",
        });
      }
    }

    if (logoutParam) {
      if (logoutParam === "success") {
        speak("Has cerrado sesión correctamente", true);
        toast.success("¡Bien! Has cerrado sesión correctamente", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: "bounce",
        });
      }
    }
  }, [location]);

  const onSubmit = (data) => {
    navigate(
      `empleos${data.search && data.search !== "" ? "?busqueda=" + data.search : ""
      }`
    );
  };

  return (
    <>
      <Navbar navClass="justify-end nav-light" />
      <section className="relative h-screen flex justify-center items-center bg-[url('../../assets/images/hero/cabecera-fepamic.jpg')] bg-cover">
        <div className="absolute inset-0 bg-slate-900/50"></div>
        <div className="container z-1">
          <div className="grid grid-cols-1 text-center mt-10 relative">
            <h4 className="lg:leading-normal leading-none text-3xl lg:text-6xl mb-5 font-bold text-white">
              Encuentra y contrata <br /> expertos para cualquier trabajo
            </h4>
            <p className="text-white text-lg max-w-xl mx-auto">
              Encuentra trabajos, empleo y oportunidades de carrera. Algunas de
              las empresas a las que hemos ayudado a reclutar excelentes
              candidatos a lo largo de los años.
            </p>

            <div className="d-flex" id="reserve-form">
              <div className="md:w-5/6 mx-auto">
                <div className="lg:col-span-10 mt-8">
                  <div className="bg-white dark:bg-slate-900 border-0 shadow rounded-md p-3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="registration-form text-dark text-start">
                        <div className="grid  grid-cols-12 lg:gap-0 gap-6">
                          <div className="filter-search-form relative filter-border col-span-9">
                            <BiBriefcaseAlt2 className="icons" />
                            <input
                              name="name"
                              type="text"
                              id="job-keyword"
                              className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                              placeholder="Encuentra tu nuevo empleo"
                              onChange={(e) => setBusqueda(e.target.value)}
                              {...register("search")}
                            />
                          </div>

                          {/* <div className="filter-search-form relative filter-border">
                                                        <PiMapPin className="icons"/>
                                                        <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={optionsOne} />

                                                    </div> */}
                          {/* <div className="filter-search-form relative filter-border"></div> */}

                          <button
                            className="btn bg-primary-900 hover:bg-primary-900 border-primary hover:border-primary text-white searchbtn submit-btn w-100 col-span-3"
                            type="submit"
                            name="search"
                            id="search"
                            style={{ height: "60px", cursor: "pointer" }}
                          >
                            <span className="hidden md:block">Buscar</span>
                            <span className="block md:hidden">
                              <Search />
                            </span>
                          </button>
                          {/* <input
                            type="submit"
                            id="search"
                            name="search"
                            style={{ height: "60px", cursor: "pointer" }}
                            className="btn bg-primary-900 hover:bg-primary-900 border-primary hover:border-primary text-white searchbtn submit-btn w-100 col-span-3"
                            value="Buscar"
                          /> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <span className="text-white">
                <span className="text-white">Búsquedas populares :</span>{" "}
                Diseñador, Desarrollador, Web, IOS, Ingeniero Senior PHP
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:py-24 py-16">
        <TrendingService />
        <Popularjobstwo />
        <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1">
            <div className="relative overflow-hidden bg-primary-900 rounded-md shadow dark:shadow-gray-700">
              <div className="grid md:grid-cols-2 items-center gap-[30px]">
                <div className="relative flex h-full">
                  <img
                    className="w-full object-cover h-full"
                    src={ab05}
                    alt=""
                  />
                  <div className="absolute md:bottom-1/2 md:translate-y-1/2 md:-end-10 ltr:md:translate-x-0 rtl:md:translate-x-0 -bottom-10 end-1/2 ltr:translate-x-1/2 rtl:-translate-x-1/2 text-center">
                    <Link
                      to="#"
                      onClick={() => setOpen(true)}
                      data-type="youtube"
                      data-id="HP5WfEULqkk"
                      className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-primary dark:text-white"
                    >
                      <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                    </Link>
                  </div>
                </div>

                <div>
                  <div className="text-white p-4 py-24">
                    <h4 className="leading-none md:leading-normal text-4xl mb-3 font-semibold">
                      Consigue el trabajo de tus sueños rápidamente.
                    </h4>

                    <p className="text-white/70 text-lg max-w-xl">
                      Busca todas las posiciones abiertas en la web. Obtén tu
                      propia estimación salarial personalizada. Lee reseñas
                      sobre más de 30000+ empresas en todo el mundo.
                    </p>

                    <ul className="list-none text-white/50 mt-4">
                      <li className="mb-1 flex items-center">
                        <BiCheckCircle className="text-white text-xl me-2" />{" "}
                        Soluciones de Marketing Digital para el Mañana
                      </li>
                      <li className="mb-1 flex items-center">
                        <BiCheckCircle className="text-white text-xl me-2" />{" "}
                        Nuestra Agencia de Marketing Talentosa y Experimentada
                      </li>
                      <li className="mb-1 flex items-center">
                        <BiCheckCircle className="text-white text-xl me-2" />{" "}
                        Crea tu propia imagen para que coincida con tu marca
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container md:mt-24 mt-16">
          <div className="relative grid md:grid-cols-3 grid-cols-1 items-center gap-[30px] z-1">
            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 dark:text-white">
                <CountUp start={1010} end={1548} duration={3} />
                K+
              </h1>

              <h5 className="counter-head text-sm font-semibold text-slate-400 uppercase">
                Cumplimiento de Trabajo
              </h5>
            </div>

            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 dark:text-white">
                <CountUp start={2} end={25} duration={3} />+
              </h1>
              <h5 className="counter-head text-sm font-semibold text-slate-400 uppercase">
                Sucursales
              </h5>
            </div>

            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-semibold mb-2 dark:text-white">
                <CountUp start={0} end={9} duration={3} />+
              </h1>
              <h5 className="counter-head text-sm font-semibold text-slate-400 uppercase">
                Años de Experiencia
              </h5>
            </div>
          </div>
        </div>
        {/* <BestCompanies /> */}
        <QuesAnswer />
        <ExploreJob />
      </section>
      <section className="relative md:pb-16 pb-16">
        <LatestPosts />
      </section>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="HP5WfEULqkk"
        onClose={() => setOpen(false)}
      />
      <Footer />
    </>
  );
}
