import { useState, useEffect, useMemo, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

export function usePathQuery({
  initialPage = 1,
  initialPerPage = 15,
  initialPath = "",
  initialSort = ["updated_at", "ASC"],
  initialFilters = [],
}) {
  const [pathQuery, setPathQuery] = useState(null);

  const initialPathQuery = useMemo(
    () => ({
      id: uuidv4(),
      page: initialPage,
      perPage: initialPerPage,
      path: initialPath,
      sort: initialSort,
      filters: initialFilters,
    }),
    []
  );

  const reducerPathQuery = (state, action) => {
    switch (action.type) {
      case "PATH":
        return { ...state, path: action.payload, id: uuidv4() };
      case "SORT":
        return { ...state, sort: action.payload, id: uuidv4() };
      case "PAGE":
        return { ...state, page: action.payload, id: uuidv4() };
      case "PERPAGE":
        return { ...state, perPage: action.payload, id: uuidv4() };
      case "FILTERS":
        return { ...state, filters: action.payload, id: uuidv4() };
      case "RESET":
        return { ...initialPathQuery, path: state.path, id: uuidv4() };
      default:
        return state;
    }
  };

  const [params, setParam] = useReducer(reducerPathQuery, initialPathQuery);

  useEffect(() => {
    setURL();
  }, [params]);

  function setURL() {
    const queryString = new URLSearchParams(
      `page=${params.page}&perPage=${params.perPage}&sort=${params.sort[0]}&direction=${params.sort[1]}`
    );
    params.filters.forEach((filter) =>
      queryString.append(filter[0], filter[1])
    );
    queryString.append("id_url", params.id);
    setPathQuery(params.path + "?" + queryString.toString());
  }

  return {
    setParam,
    pathQuery,
    params,
  };
}
