import { useEffect } from "react";
import { Link } from "react-router-dom";
import { PiMapPin, AiOutlineClockCircle } from "../assets/icons/vander";
import useJobs from "../pages/job-list/hooks/useJobs";
import logo from "../assets/images/favicon.webp";
import { format } from "date-fns";

export default function Popularjobstwo() {
  const { empleos, getEmpleos } = useJobs();

  useEffect(() => {
    getEmpleos(1, 6);
  }, []);

  return (
    <div className="container md:mt-24 mt-16">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">
          Empleos Populares
        </h3>

        <p className="text-slate-400 max-w-xl mx-auto">
          Busca todas las posiciones abiertas en la web. Obtén tu propia
          estimación salarial personalizada. Lee reseñas sobre más de 30000+
          empresas en todo el mundo.
        </p>
      </div>
      {/* {jobData.slice(0, 6).map((item, index) => ( */}
      {empleos.slice(0, 6).map((item, index) => (
        <div className="grid grid-cols-1 mt-8 gap-[30px]" key={index}>
          <div className="group relative overflow-hidden md:flex justify-between items-center rounded shadow hover:shadow-md dark:shadow-gray-700 transition-all duration-500 p-5">
            <div className="flex items-center">
              <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                <img
                  src={item.image || logo}
                  className="h-8 w-8"
                  alt={item.oferta_trabajo}
                />
              </div>
              <Link
                to={`/empleos/${item.id}`}
                className="text-lg hover:text-primary font-semibold transition-all duration-500 ms-3 min-w-[180px]"
              >
                {item.oferta_trabajo}
              </Link>
            </div>

            <div className="md:block flex justify-between md:mt-0 mt-4">
              <span className="block">
                <span className="bg-primary-900/10 inline-block text-primary text-xs px-2.5 py-0.5 font-semibold rounded-full">
                  Jornada {item.jornada}
                </span>
              </span>
              <span className="flex items-center text-slate-400 text-sm md:mt-1 mt-0">
                <AiOutlineClockCircle className="me-1" />
                {format(item.updated_at, "dd/MM/yyyy")}
              </span>
            </div>

            <div className="md:block flex justify-between md:mt-0 mt-2">
              <span className="text-slate-400 flex items-center">
                <PiMapPin className="me-1" />
                {item.lugar_trabajo}
              </span>
              <span className="block font-semibold md:mt-1 mt-0">
                {item.salario}
              </span>
            </div>

            <div className="md:mt-0 mt-4">
              <Link
                to={`/empleos/${item.id}/solicitud`}
                className="btn rounded-md bg-primary-900 hover:bg-primary-900 border-primary hover:border-primary text-white md:ms-2 w-full md:w-auto"
              >
                Aplica Ahora
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
