export const initialCandidato = {
    fecha_nacimiento: '',
    discapacidad_superior_33: false,
    inscrito_bolsa: false,
    info_ofertas_empleo: false,
    info_ofertas_formacion: false,
    cualificacion: '',
    curriculum: '',
    name: '',
    surnames: '',
    nif: '',
    email: '',
    role_id: 5, // 5 es el valor de candidato
    password: '',
    adaptaciones: '',
    discapacidad: ''
}

export const initialEditCandidato = {
    fecha_nacimiento: '',
    discapacidad_superior_33: false,
    inscrito_bolsa: false,
    info_ofertas_empleo: false,
    info_ofertas_formacion: false,
    cualificacion: '',
    curriculum: '',
    name: '',
    surnames: '',
    nif: '',
    email: '',
    role_id: 5, // 5 es el valor de candidato
    adaptaciones: '',
    discapacidad: ''
}