import { useState, useEffect } from "react";
// import { useNotification } from "../../../hooks/useNotification";
import { useFecth } from "../../../hooks";
import { usePathQuery } from "../../../hooks";
import { useRecoilState } from "recoil";
import {
  notificacionesAtom,
  notificacionesMetaAtom,
  selectedNotificacionAtom,
} from "../../../stores/notificaciones-store";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";

const initialLoading = {
  get: false,
  show: false,
  create: false,
  update: false,
  delete: false,
};

const UseNotificaciones = () => {
  const [notificaciones, setNotificaciones] =
    useRecoilState(notificacionesAtom);
  const [notificacionesMeta, setNotificacionesMeta] = useRecoilState(
    notificacionesMetaAtom
  );
  const [selectedNotificacion, setSelectedNotificacion] = useRecoilState(
    selectedNotificacionAtom
  );
  // const { showNotification } = useNotification();
  const { params, pathQuery, setParam } = usePathQuery({
    initialPerPage: 15,
    initialPath: "notificaciones",
  });
  const [loading, setLoading] = useState(initialLoading);

  const notificacionesFecth = useFecth();

  const { userAuth } = useAuth();

  useEffect(() => {
    loading && pathQuery && userAuth && getNotificaciones();
  }, [pathQuery]);

  function getNotificaciones() {
    setLoading({ ...loading, get: true });
    return notificacionesFecth
      .get(
        `notificaciones?user_id=${userAuth?.id}&rol_id=${userAuth?.role_id}&leido=0`
      )
      .then(({ data, status, meta }) => {
        setLoading({ ...loading, get: false });
        if (status === "error") {
          // showNotification({ type: "error", msg: 'Ha ocurrido un error intentando cargar la información de notificaciones.' });
          return false;
        } else {
          setNotificaciones(data);
          setNotificacionesMeta(meta);
        }
      });
  }

  async function marcarComoLeido(id, notificacion) {
    setLoading({ ...loading, edit: true });
    const { data, status } = await notificacionesFecth.put(
      `notificaciones/${id}`,
      notificacion
    );
    setLoading({ ...loading, edit: false });
    if (status === "error") {
      // showNotification({ type: "error", msg: 'Ha ocurrido un error intentando marcar la notificación como leida.' });
      return false;
    } else {
      console.log(notificacion);
      // showNotification({ type: "success", msg: '¡Has marcado la notificación como leida!' });
      setParam({ type: "RESET" });
      return true;
    }
  }

  return {
    loading,
    notificaciones,
    notificacionesMeta,
    selectedNotificacion,
    params,
    setParam,
    pathQuery,
    getNotificaciones,
    marcarComoLeido,
  };
};

export default UseNotificaciones;
