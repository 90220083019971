import logo_icon_40 from "../assets/images/logo.webp"; 
import error from '../assets/images/error.png';
import React from 'react'
import { Link } from "react-router-dom";

export default function Error() {
    return (
        <section className="relative bg-primary-900/5">
            <div className="container-fluid relative">
                <div className="grid grid-cols-1">
                    <div className="flex flex-col min-h-screen justify-center md:px-10 py-10 px-4">
                        <div className="text-center">
                            <Link to="/index"><img
                                src={logo_icon_40}
                                className="h-10 inline-block dark:hidden"
                                alt="Fepamic Empleo y Formación" /></Link>
                        </div>
                        <div className="title-heading text-center my-auto">
                            <img src={error} className="mx-auto" alt="" />
                            <h1 className="mt-3 mb-6 md:text-4xl text-3xl font-bold">¡Página no encontrada!</h1>
                            <p className="text-slate-400">Vaya... <br /> Parece que la página que buscabas no se ha encontrado.</p>

                            <div className="mt-4">
                                <Link to="/" className="btn bg-primary-900 hover:bg-primary-900 border-primary hover:border-primary text-white rounded-md">Volver a la página principal</Link>
                            </div>
                        </div>
                        <div className="text-center">
                            <p className="mb-0 text-slate-400 font-medium">©{(new Date().getFullYear())}{" "} Fepamic. Desarrollado por <Link to="https://www.aicor.com/" target="_blank" className="text-reset">Aicor</Link>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
