function generarSlug(titulo) {
    // Convertir a minúsculas y reemplazar caracteres especiales
    const slug = titulo.toLowerCase()
        .replace(/[^\w\s]/gi, '') // Remover caracteres especiales
        .replace(/\s+/g, '-')     // Reemplazar espacios con guiones
        .replace(/ñ/g, 'n')       // Reemplazar 'ñ' con 'n'
        .replace(/[áä]/g, 'a')    // Reemplazar á y ä con a
        .replace(/[éë]/g, 'e')    // Reemplazar é y ë con e
        .replace(/[íï]/g, 'i')    // Reemplazar í y ï con i
        .replace(/[óö]/g, 'o')    // Reemplazar ó y ö con o
        .replace(/[úü]/g, 'u');   // Reemplazar ú y ü con u

    return slug;
}

export { generarSlug };