import React from 'react';

const TicketPlaceholder = ({ iterations }) => {

    for (let i = 0; i <= iterations; i++) {
        return (
            <div className="shadow rounded-md p-4 max-w-sm w-full mx-auto">
                <div className="animate-pulse flex justify-between items-center">
                    <div className="h-10 w-20 bg-slate-200 rounded"></div>
                    <div className="h-10 w-24 flex flex-col gap-2 bg-red-400">
                        <div className="h-6 w-30 bg-slate-200 rounded"></div>
                        <div className="h-6 w-60 bg-slate-200 rounded"></div>
                    </div>
                    <div className="h-6 w-24 bg-slate-200 rounded-full"></div>
                </div>
            </div>
        )
    }
}

export default TicketPlaceholder;
