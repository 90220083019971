import { atom } from "recoil";

export const serviciosAtom = atom({
    key: "serviciosAtom",
    default: [],
})

export const serviciosMetaAtom = atom({
    key: "serviciosMetaAtom",
    default: [],
})

export const selectedServicioAtom = atom({
    key: "selectedServicioAtom",
    default: {},
})
export const serviciosFepamicAtom = atom({
    key: "serviciosFepamicAtom",
    default: [],
})

export const serviciosFepamicMetaAtom = atom({
    key: "serviciosMetaFepamicAtom",
    default: [],
})

export const selectedServicioFepamicAtom = atom({
    key: "selectedServicioFepamicAtom",
    default: {},
})