import { atom } from "recoil";

const sideMenu = atom({
  key: "sideMenu",
  default: {
    menu: [
      {
        icon: "Home",
        pathname: "/administracion",
        title: "Administración",
      },
      "devider",
      {
        icon: "User",
        title: "Usuarios",
        subMenu: [
          {
            icon: "Users",
            pathname: "/administracion/usuarios",
            title: "Usuarios",
          },
          {
            icon: "Unlock",
            pathname: "/administracion/roles",
            title: "Roles",
          },
          {
            icon: "Info",
            pathname: "/administracion/actividad",
            title: "Actividad",
          },
        ],
      },
      {
        icon: "Users",
        pathname: "/administracion/candidatos",
        title: "Candidatos",
      },
      {
        icon: "Box",
        pathname: "/administracion/empresas",
        title: "Empresas",
      },
      {
        icon: "BookOpen",
        pathname: "/administracion/servicios",
        title: "Servicios",
      },
      {
        icon: 'Trello',
        pathname: '/administracion/publicacion-oferta-empleos',
        title: 'Empleos',
      },
      {
        icon: 'Pencil',
        pathname: '/administracion/cursos',
        title: 'Cursos',
      },
      {
        icon: "Hash",
        pathname: "/administracion/noticias",
        title: "Noticias",
      }
      // {
      //   icon: "Briefcase",
      //   pathname: "/administracion/empleos",
      //   title: "Empleos",
      // },
      // {
      //   icon: "Book",
      //   pathname: "/administracion/cursos",
      //   title: "Cursos",
      // },
      // {
      //   icon: "FileText",
      //   pathname: "/administracion/noticias",
      //   title: "Noticias",
      // },
      // {
      //   icon: "MessageSquare",
      //   pathname: "/administracion/soporte",
      //   title: "Soporte",
      // },
      // {
      //   icon: "BarChart2",
      //   pathname: "/administracion/informes",
      //   title: "Informes",
      // },
      
      // {
      //   icon: "Box",
      //   pathname: "/administracion/servicios-fepamic",
      //   title: "Servicios Fepamic",
      // },
      
      /* "devider",
      {
        icon: "Box",
        title: "Menu Layout",
        subMenu: [
          {
            icon: "",
            pathname: "/",
            title: "Side Menu",
            ignore: true,
          },
          {
            icon: "",
            pathname: "/simple-menu/dashboard",
            title: "Simple Menu",
            ignore: true,
          },
          {
            icon: "",
            pathname: "/top-menu/dashboard",
            title: "Top Menu",
            ignore: true,
          },
        ],
      }, */
    ],
  },
});

export { sideMenu };
