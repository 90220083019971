import React, { useEffect, useState } from "react";
import useNotification from "../../hooks/useNotification";
import useAuth from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { validationLogin } from "../../constants/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { voz } from "../../utils/voz";

import { Link } from "react-router-dom";
import logo_dark from "../../assets/images/logo-dark.png";
import logo_light from "../../assets/images/logo-light.png";
import logo_fepamic from "../../assets/images/logo.webp"


export default function Login() {
  const { showNotification } = useNotification();
  const { login, loading } = useAuth();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const { reset, register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationLogin),
  });
  const { errors } = formState;
  const tokenExpired = params.get("tokenExpired") ?? "";
  const speak = voz.speak;

  // useEffect(() => {
  //     console.log('tokenExpired', tokenExpired)

  //     if (tokenExpired === "success") {
  //       showNotification({ type: "error", msg: "Acceso caducado" });
  //       params.delete("tokenExpired");
  //       setParams(params);
  //     }
  //   }, []);




  const onSubmit = ({ email, password }) => {
    login({ email, password }).then((data) => {

      if (
        /**
         * TODO: Impide el acceso a usuarios con rol diferente a 5 (candidatos)
         */
        data.status === "success" &&
        data.data.user.role_id !== 5 &&
        data.data.user.email !== null
      ) {
        toast.error("Usuario no autorizado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: "bounce",
        });
        localStorage.setItem("auth", JSON.stringify(null))
        reset();
      } else if (data.status === "success") {
        navigate("/?toast=success");
      } else {
        // showNotification({ type: "error", msg: data.data });
        speak(data.data, true);
        toast.error(data.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: "bounce",
        });
        reset();
      }
    });
  };


  return (
    <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg3.jpg')] bg-no-repeat bg-center bg-cover">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
      <div className="container">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
            <div className="p-6">
              <Link to="/">
                <img
                  src={logo_fepamic}
                  className="mx-auto h-[48px] block dark:hidden"
                  alt="Fepamic Formaciónn y Empleo"
                />
                <img
                  src={logo_fepamic}
                  className="mx-auto h-[48px] dark:block hidden"
                  alt="Fepamic Formaciónn y Empleo"
                />
              </Link>
              <h5 className="my-6 text-xl font-semibold">Acceder</h5>
              <form onSubmit={handleSubmit(onSubmit)} className="text-left">
                <div className="grid grid-cols-1">
                  <div className="mb-4 ltr:text-left rtl:text-right">
                    <label className="font-semibold" htmlFor="LoginEmail">
                      Email:
                    </label>
                    <input
                      id="LoginEmail"
                      type="email"
                      className="form-input mt-3 rounded-md"
                      placeholder="nombre@ejemplo.com"
                      {...register("email")}
                    />
                  </div>

                  <div className="mb-4 ltr:text-left rtl:text-right">
                    <label className="font-semibold" htmlFor="LoginPassword">
                      Contraseña:
                    </label>
                    <input
                      id="LoginPassword"
                      type="password"
                      className="form-input mt-3 rounded-md"
                      placeholder="Contraseña"
                      {...register("password")}
                    />
                  </div>

                  {/* <div className="flex justify-between mb-4">
                                        <div className="inline-flex items-center mb-0">
                                            <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50 me-2" type="checkbox" value="" id="RememberMe"/>
                                            <label className="form-checkbox-label text-slate-400" htmlFor="RememberMe">Remember me</label>
                                        </div>
                                        <p className="text-slate-400 mb-0"><Link to="/reset-password" className="text-slate-400">Forgot password ?</Link></p>
                                    </div> */}

                  <div className="mb-4">
                    <input
                      type="submit"
                      className="btn bg-primary-900 hover:bg-primary-900 border-primary hover:border-primary text-white rounded-md w-full cursor-pointer transition-all duration-300 ease-in-out"
                      value="Acceder"
                    />
                  </div>

                  <div className="text-center">
                    <span className="text-slate-400 me-2">
                      ¿Aun no tienes una cuenta?
                    </span>{" "}
                    <Link
                      to="/registro"
                      className="text-black dark:text-white font-bold"
                    >
                      Regístrate
                    </Link>
                  </div>
                </div>
              </form>
            </div>

            <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 text-center">
              <p className="mb-0 text-gray-400 font-medium">
                © {new Date().getFullYear()} Aicor Empleo. Desarrolado por{" "}
                <Link
                  to="https://www.aicor.com/"
                  target="_blank"
                  className="text-reset"
                >
                  Aicor
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
