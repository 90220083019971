import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  MdOutlineArrowForward,
  MdOutlineCalendarMonth,
  AiOutlineClockCircle,
  BiCameraOff,
} from "../../../assets/icons/vander";
import useBlog from "../hooks/useBlog";
import { calcularTiempoLectura } from "../../../utils";
import { generarSlug } from "../../../utils/slug";

export default function LatestPosts({ categoria_id = 0 }) {
  const { posts, postId, getPosts } = useBlog();

  const BASE_URL = process.env.REACT_APP_PUBLIC_API_URL;
  useEffect(() => {
    getPosts(1, 3, categoria_id);
  }, [categoria_id]);

  return (
    <div className="container md:mt-24 mt-16">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">
          Últimas noticias
        </h3>

        <p className="text-slate-400 max-w-xl mx-auto">
          Mantente informado de las últimas tendencias y noticias sobre empleo y
          formación del entorno de Aicor Empleo.
        </p>
      </div>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
        {posts.length > 0 &&
          posts.map((item, index) => (
            <>
              {item.id !== parseInt(postId) && (
                <div
                  key={index}
                  className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700"
                >
                  <div className="relative overflow-hidden flex">
                    {item.imagen !== null ? (
                      <img
                        src={`${BASE_URL}${item.imagen}`}
                        className="scale-110 group-hover:scale-100 transition-all duration-500 w-full object-cover"
                        style={{ height: "200px" }}
                        alt={item.titulo}
                      />
                    ) : (
                      <div
                        className="w-full flex justify-center items-center bg-slate-200"
                        style={{ height: "200px" }}
                      >
                        <BiCameraOff color="#666" size={34} />
                      </div>
                    )}
                  </div>

                  <div className="relative p-6">
                    <div className="absolute start-6 -top-4">
                      <span className="bg-primary-900 text-white text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">
                        {item.categoria.nombre}
                      </span>
                    </div>

                    <div className="">
                      <div className="flex mb-4">
                        <span className="text-slate-400 text-sm flex items-center">
                          <MdOutlineCalendarMonth className="text-slate-900 dark:text-white me-2" />
                          {item.created_at}
                        </span>
                        <span className="text-slate-400 text-sm ms-3 flex items-center">
                          <AiOutlineClockCircle className="text-slate-900 dark:text-white me-2" />
                          {`${calcularTiempoLectura(item?.texto)} minuto(s)`}
                        </span>
                      </div>

                      <Link
                        to="/blog-detail"
                        className="title text-lg font-semibold hover:text-primary duration-500 ease-in-out"
                      >
                        {item.titulo}
                      </Link>

                      <div className="flex justify-between items-center mt-3">
                        <Link
                          to={`/blog/${generarSlug(item?.titulo)}`}
                          className="btn btn-link hover:text-primary after:bg-primary-900 duration-500 ease-in-out inline-flex items-center"
                        >
                          Leer más <MdOutlineArrowForward className="ms-1" />
                        </Link>
                        {/* <span className="text-slate-400 text-sm">by <Link to="#" className="text-slate-900 dark:text-white hover:text-primary dark:hover:text-primary font-medium">{item.powerdby}</Link></span> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
      </div>
    </div>
  );
}
