import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import JobDetailComp from '../../components/job-detail-comp'
import Footer from '../../components/Footer'
import { Link, useParams } from "react-router-dom";
import { Book, Briefcase, Clock, DollarSign, MapPin, Monitor, UserCheck } from 'react-feather';
import { MdOutlineArrowForward } from "react-icons/md"
import ExploreJob from '../../components/Explore-job';
import useJobs from './hooks/useJobs';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

export default function JobDetailOne() {
    const { id } = useParams();
    const { empleo, showEmpleo } = useJobs();


    useEffect(() => {
        showEmpleo(id);
    }, [id])


    const fecha = empleo.updated_at !== undefined && parseISO(empleo?.updated_at);
    const resultado = fecha && formatDistanceToNow(fecha, { addSuffix: true, locale: es });


    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-primary-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h1 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">{empleo?.oferta_trabajo}</h1>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Inicio</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/empleos">Empleos</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">{empleo?.oferta_trabajo}</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-4 md:col-span-6">
                            <div className="shadow dark:shadow-gray-700 rounded-md bg-white dark:bg-slate-900 sticky top-20">
                                <div className="p-6">
                                    <h5 className="text-lg font-semibold">Resumen del puesto</h5>
                                </div>
                                <div className="p-6 border-t border-slate-100 dark:border-t-gray-700">
                                    <ul className="list-none">
                                        <li className="flex items-center">
                                            <UserCheck className="h-5 w-5"></UserCheck>

                                            <div className="ms-4">
                                                <p className="font-medium">Tipo de contrato:</p>
                                                <span className="text-primary font-medium text-sm">{empleo?.tipo_contrato?.charAt(0).toUpperCase() + empleo?.tipo_contrato?.slice(1)}, </span>
                                                <span className="text-primary font-medium text-sm">jornada {empleo?.jornada}</span>

                                            </div>
                                        </li>

                                        <li className="flex items-center mt-3">
                                            <MapPin className="h-5 w-5"></MapPin>

                                            <div className="ms-4">
                                                <p className="font-medium">Lugar de trabajo:</p>
                                                <span className="text-primary font-medium text-sm">{empleo?.lugar_trabajo}</span>
                                            </div>
                                        </li>

                                        <li className="flex items-center mt-3">
                                            <Monitor className="h-5 w-5"></Monitor>

                                            <div className="ms-4">
                                                <p className="font-medium">Categoría:</p>
                                                <span className="text-primary font-medium text-sm">{empleo?.categoria?.name}</span>
                                            </div>
                                        </li>

                                        <li className="flex items-center mt-3">
                                            <Briefcase className="h-5 w-5"></Briefcase>

                                            <div className="ms-4">
                                                <p className="font-medium">Duración:</p>
                                                <span className="text-primary font-medium text-sm">{empleo?.duracion_contrato}</span>
                                            </div>
                                        </li>

                                        <li className="flex items-center mt-3">
                                            <DollarSign className="h-5 w-5"></DollarSign>

                                            <div className="ms-4">
                                                <p className="font-medium">Franja salarial:</p>
                                                <span className="text-primary font-medium text-sm">{empleo?.salario}</span>
                                            </div>
                                        </li>

                                        <li className="flex items-center mt-3">
                                            <Clock className="h-5 w-5"></Clock>

                                            <div className="ms-4">
                                                <p className="font-medium">Publicación:</p>
                                                <span className="text-primary font-medium text-sm">{resultado && (resultado?.charAt(0).toUpperCase() + resultado?.slice(1))}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-8 md:col-span-6">

                            <h5 className="text-lg font-semibold">Descripción de funciones:</h5>

                            <p className="text-slate-400 mt-4">{empleo?.funciones}</p>

                            <h5 className="text-lg font-semibold mt-6">Requisitos: </h5>
                            <p className="text-slate-400 mt-4">Los requisitos para poder aplicar a la oferta de trabajo son:.</p>
                            <ul className="list-none flex flex-col">
                                {empleo?.experiencia_laboral_minima !== "" && <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Experiencia laboral: {empleo?.experiencia_laboral_minima}</li>}
                                {empleo?.nivel_formacion?.name && <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Nivel de formación: {empleo?.nivel_formacion?.name}</li>}
                                {empleo?.formacion_complementaria && <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Formación complementaria: {empleo?.formacion_complementaria}</li>}
                                {empleo?.idiomas && <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Idiomas: {empleo?.idiomas}</li>}
                                {empleo?.permiso_conducir === 1 && <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Permiso de conducir: Si</li>}
                                {empleo?.conocimientos_informaticos && <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Conocimientos informáticos: {empleo?.conocimientos_informaticos}</li>}

                            </ul>

                            {/* <h5 className="text-lg font-semibold mt-6">Required Experience, Skills and Qualifications: </h5>
                            <p className="text-slate-400 mt-4">It sometimes makes sense to select texts containing the various letters and symbols specific to the output language.</p>
                            <ul className="list-none">
                                <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Proven experience as a .NET Developer or Application Developer</li>
                                <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />good understanding of SQL and Relational Databases, specifically Microsoft SQL Server.</li>
                                <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Experience designing, developing and creating RESTful web services and APIs</li>
                                <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Basic know how of Agile process and practices</li>
                                <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Good understanding of object-oriented programming.</li>
                                <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Good understanding of concurrent programming.</li>
                                <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Sound knowledge of application architecture and design.</li>
                                <li className="text-slate-400 mt-2 inline-flex items-center"><MdOutlineArrowForward className="text-primary me-1" />Excellent problem solving and analytical skills</li>
                            </ul> */}

                            <div className="mt-5">
                                <Link to={`solicitud`} className="btn rounded-md bg-primary-900 hover:bg-primary-900 border-primary hover:border-primary text-white md:ms-2 w-full md:w-auto">Inscribirme en la oferta</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <JobDetailComp /> */}
                <ExploreJob />
            </section>
            <Footer />
        </>
    )
}