import { useState } from 'react'
import { useRecoilState } from 'recoil'
import useFetch from '../../../hooks/useFecth'
import { toast } from 'react-toastify'
import {
    cursosAtom,
    selectedCursosAtom,
    cursosFiltersAtom,
    cursosCategoriasAtom
} from "../../../stores";


const initialLoading = {
    get: false,
    show: false,
    edit: false,
    delete: false,
}


export default function useCourses() {

    const courseFetch = useFetch();

    const [loading, setLoading] = useState(initialLoading)
    const [cursos, setCursos] = useRecoilState(cursosAtom);
    const [curso, setCurso] = useRecoilState(selectedCursosAtom);
    const [totalPages, setTotalPages] = useState(0);

    // Filtros
    const [filters, setFilters] = useRecoilState(cursosFiltersAtom);

    // Categorías
    const [categorias, setCategorias] = useRecoilState(cursosCategoriasAtom);

    function getCursos({
        page = 1,
        perPage = 10,
        name = null,
        categoria = null,
        lugarTrabajo = null,
        modalidad = null
    }) {
        setLoading({ ...loading, get: true });
        return courseFetch.get(`publicacion-curso-formacion-public?perPage=${perPage}&page=${page}${name !== null ? `&name=${name}` : ""}${categoria !== null ? `&categoriaId=${categoria}` : ""}${modalidad !== null ? `&modalidad=${modalidad}` : ""}${lugarTrabajo !== null ? `&ubicacion=${lugarTrabajo}` : ""}`)
            .then(({ data, status, meta }) => {
                setLoading({ ...loading });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    setCursos(data);
                    setTotalPages(Math.ceil(meta.total / perPage));
                    return true;
                }
            }).catch(e => console.log(e))
    }


    function showCurso(id) {
        setLoading({ ...loading, show: true });
        return courseFetch.get(`publicacion-curso-formacion-public/${id}`)
            .then(({ data, status }) => {
                setLoading({ ...loading, show: false });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    setCurso(data);
                    return true;
                }
            })
    }

    function newSolicitud(data) {
        setLoading({ ...loading, show: true });
        return courseFetch.post(`solicitud-inscripcion-cursos-formacion`, data)
            .then(({ status, meta }) => {
                setLoading({ ...loading, show: false });
                if (status === "error") {
                    toast.error(meta?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else {
                    toast.success('¡Inscrito con éxito!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    // showCandidato(id);
                    return true;
                }
            })
            .catch(error => {
                // Manejar errores de red u otros errores
                console.error('Error:', error);
            });
    }

    function handleChangeFilters(newFilters) {
        setFilters(newFilters);
    }

    /* 
     * AÑADIMOS EL TIPO DE CATEGORÍA 2 PORQUE ES EL CORRESPONDIENTE A CATEGORÍAS DE OFERTAS DE EMPLEO
     */
    const getCategoriasCursos = () => {
        setLoading({ ...loading, get: true });
        return courseFetch.get(`categorias-public?tipo=2`)
            .then(({ data, status }) => {
                setLoading({ ...loading, get: false });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    setCategorias(data);
                    return true;
                }
            })
    }

    return {
        loading,
        cursos,
        curso,
        setLoading,
        setCursos,
        setCurso,
        showCurso,
        getCursos,
        totalPages,
        filters,
        handleChangeFilters,
        categorias,
        getCategoriasCursos,
        newSolicitud
    }

}