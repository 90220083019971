import { useState } from 'react'
import { useRecoilState } from 'recoil'
import useFetch from '../../../hooks/useFecth'
import useFecthFormData from '../../../hooks/useFecthFormData';
import { toast } from 'react-toastify'
import {
    empleosAtom,
    empleosSelectedAtom,
    empleosFiltersAtom,
    empleosCategoriasAtom
} from "../../../stores";


const initialLoading = {
    get: false,
    show: false,
    edit: false,
    delete: false,
}


export default function useJobs() {

    const jobFecth = useFetch();
    const jobFecthFormData = useFecthFormData();

    const [loading, setLoading] = useState(initialLoading)
    const [empleos, setEmpleos] = useRecoilState(empleosAtom);
    const [empleo, setEmpleo] = useRecoilState(empleosSelectedAtom);
    const [totalPages, setTotalPages] = useState(0);

    // Filtros
    const [filters, setFilters] = useRecoilState(empleosFiltersAtom);

    // Categorías
    const [categorias, setCategorias] = useRecoilState(empleosCategoriasAtom);

    function getEmpleos({
        page = 1,
        perPage = 10,
        name = null,
        discapacidad = null,
        tipo_contrato = null,
        salarioMinimo = null,
        salario = null,
        salarioMaximo = null,
        sexo = null,
        edad = null,
        lugarTrabajo = null,
        jornada = null,
        conocimientosInformaticos = null,
        categoria = null,
        modelo = null,
        nivelFormacion = null
    }) {
        setLoading({ ...loading, get: true });
        return jobFecth.get(`publicacion-oferta-empleos-public?perPage=${perPage}&page=${page}${name !== null ? `&name=${name}` : ""}${discapacidad !== null ? `&discapacidad=${discapacidad}` : ""}${tipo_contrato !== null ? `&tipo_contrato=${tipo_contrato}` : ""}${salario !== null ? `&salario=${salario}` : ""}${salarioMinimo !== null ? `&salarioMinimo=${salarioMinimo}` : ""}${salarioMaximo !== null ? `&salarioMaximo=${salarioMaximo}` : ""}${sexo !== null ? `&sexo=${sexo}` : ''}${edad !== null ? `&edad=${edad}` : ""}${lugarTrabajo !== null ? `&lugarTrabajo=${lugarTrabajo}` : ""}${jornada !== null ? `&jornada=${jornada}` : ""}${conocimientosInformaticos !== null ? `&conocimientosInformaticos=${conocimientosInformaticos}` : ""}${categoria !== null ? `&categoria=${categoria}` : ""}${modelo !== null ? `&modelo=${modelo}` : ""}${nivelFormacion !== null ? `&nivelFormacion=${nivelFormacion}` : ""}`)
            .then(({ data, status, meta }) => {
                console.log(data)
                setLoading({ ...loading });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    setEmpleos(data);
                    setTotalPages(Math.ceil(meta.total / perPage));
                    return true;
                }
            }).catch(e => console.log(e))
    }


    function showEmpleo(id) {
        setLoading({ ...loading, show: true });
        return jobFecth.get(`publicacion-oferta-empleos-public/${id}`)
            .then(({ data, status }) => {
                setLoading({ ...loading, show: false });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    setEmpleo(data);
                    return true;
                }
            })
    }

    function newSolicitud(data) {
        setLoading({ ...loading, show: true });
        return jobFecthFormData.post(`solicitud-inscripcion-oferta-empleos`, data)
            .then(({ data, status, meta }) => {
                setLoading({ ...loading, show: false });
                console.log(data, status, meta)
                if (status === "error") {
                    toast.error(meta?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else {
                    toast.success('¡Inscrito con éxito!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    // showCandidato(id);
                    return true;
                }
            })
            .catch(error => {
                // Manejar errores de red u otros errores
                console.error('Error:', error);
            });
    }


    function handleChangeFilters(newFilters) {
        setFilters(newFilters);
    }

    /* 
     * AÑADIMOS EL TIPO DE CATEGORÍA 1 PORQUE ES EL CORRESPONDIENTE A CATEGORÍAS DE OFERTAS DE EMPLEO
     */
    const getCategoriasEmpleo = () => {
        setLoading({ ...loading, get: true });
        return jobFecth.get(`categorias-public?tipo=1`)
            .then(({ data, status }) => {
                setLoading({ ...loading, get: false });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    setCategorias(data);
                    return true;
                }
            })
    }

    return {
        loading,
        empleos,
        empleo,
        setLoading,
        setEmpleos,
        setEmpleo,
        showEmpleo,
        getEmpleos,
        totalPages,
        filters,
        handleChangeFilters,
        categorias,
        getCategoriasEmpleo,
        newSolicitud
    }

}