import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import useTickets from "./hooks/useTickets";
import { Send, User, ChevronLeft } from 'react-feather';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks";


export default function SupportDetail() {
  const { id } = useParams();
  const { showTicket, ticket, answerTicket } = useTickets();
  const { handleSubmit, register, reset } = useForm();
  const { userAuth } = useAuth();

  const fecha = ticket.updated_at !== undefined && parseISO(ticket.updated_at);
  const resultado = fecha && formatDistanceToNow(fecha, { addSuffix: true, locale: es });

  const [respuestas, setRespuestas] = useState(ticket?.respuestas || []);

  useEffect(() => {
    showTicket(id)
  }, [id])

  useEffect(() => {
    setRespuestas(ticket?.respuestas || []);
  }, [ticket]);

  console.log(userAuth)

  const submitTicket = (data) => {

    console.log(data)
    let aux = { ...data }

    aux.ticket_id = id // Sacamos el id de los params
    aux.tema = ticket.tema
    aux.prioridad = ticket.prioridad
    aux.estado = ticket.estado
    aux.servicio = ticket.servicio
    aux.asunto = ticket.asunto
    aux.mensaje = data.mensaje
    aux.cc = ticket.cc
    aux.asignado = ticket.asignado
    aux.tipo_contacto = userAuth.role
    aux.contacto_id = userAuth.id
    aux.created_at = ticket.created_at
    aux.updated_at = ticket.updated_at

    const newRespuestas = [...respuestas, aux];
    setRespuestas(newRespuestas);

    answerTicket(aux);

    reset();

  }

  return (
    <>
      <Navbar navClass="justify-end" />
      <div className="container mt-30 text-white justify-between">
        <div className="flex justify-between items-end p-4 bg-slate-900 rounded-md shadow-sm">
          <div className="flex gap-2 h-full items-center">
            <Link to={"/soporte"} className="p-4 pl-2 border-r border-white">
              <ChevronLeft className="w-5 h-5" />
            </Link>
            <div className="flex flex-col gap-2 pl-2">
              <h1 className="text-xl md:text-3xl">{ticket.tema}</h1>
              <p>{ticket.prioridad}</p>
            </div>
          </div>
          <p className="text-xs">Última actualización: {resultado}</p>
        </div>
      </div>
      <section className="grid relative md:py-16 py-16" style={{ minHeight: "calc(100vh - 494px)" }}>
        <div className="container flex flex-col justify-end h-full">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-12 md:col-span-12">
              {respuestas.map((item) => (
                <div key={item.id} className={`flex w-full ${item.tipo_contacto === "Candidate" || item.tipo_contacto === "candidato" ? "flex-row-reverse" : "flex-row"}`}>
                  <span
                    className="flex items-center m-2 justify-center bg-slate-100 rounded-full w-10 h-10"
                  >
                    <User className="w-8 h-8 p-2" />
                  </span>
                  <div
                    className={`shadow-sm m-2 p-3 px-6 ${item.tipo_contacto === "Candidate" || item.tipo_contacto === "candidato" ? "bg-primary-900 text-white" : "bg-slate-100"}`}
                    style={item.tipo_contacto === "Candidate" || item.tipo_contacto === "candidato" ? { borderRadius: "999px 999px 0px 999px" } : { borderRadius: "0px 999px 999px 999px" }}
                  >
                    <p>{item.mensaje}</p>
                    {/* <p>{item.created_at}</p>
                    <p>{item.name}</p> */}
                  </div>

                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-12">
            <form className="col-span-12" onSubmit={handleSubmit(submitTicket)}>
              <div className="flex gap-2 mt-4 bg-slate-50 rounded-lg overflow-hidden">
                <textarea
                  name="mensaje"
                  id="mensaje"
                  {...register("mensaje")}
                  cols="10"
                  rows="1"
                  placeholder={`${ticket.estado === "Cerrado" ? "No puedes escribir en un ticket cerrado" : "Escribe tu mensaje"}`}
                  className="w-full px-6 py-4 bg-transparent dark:bg-slate-900 focus-visible:outline-none border rounded-lg border-gray-300 dark:border-gray-700"
                  disabled={ticket.estado === "Cerrado" ? true : false}
                />
                {ticket.estado !== "Cerrado" &&
                  <button type="submit" className={`p-4 bg-primary-900 hover:bg-black transition-colors rounded-lg`}>
                    <Send width={24} height={24} className="text-white" />
                  </button>
                }
              </div>
            </form>
          </div>
        </div>
      </section >
      <Footer />
    </>
  );
}
