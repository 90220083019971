import { atom } from "recoil";

export const notificacionesAtom = atom({
    key: "notificacionesAtom",
    default: [],
})

export const notificacionesMetaAtom = atom({
    key: "notificacionesMetaAtom",
    default: [],
})

export const selectedNotificacionAtom = atom({
    key: "notificacionesSelectedAtom",
    default: {},
})

export const totalNotificacionesAtom = atom({
    key: "totalNotificacionesAtom",
    default: 0
})

export const notificacionesFiltersAtom = atom({
    key: "notificacionesFiltersAtom",
    default: {},
})