import { atom } from "recoil";

export const candidatosAtom = atom({
    key: "candidatosAtom",
    default: [],
});

export const candidatosMetaAtom = atom({
    key: "candidatosMetaAtom",
    default: [],
});

export const candidatoSelectedAtom = atom({
    key: "candidatoSelectedAtom",
    default: {},
});