import React from 'react'
import { Link } from "react-router-dom";
import {MdOutlineThumbUpOffAlt} from "../assets/icons/vander"

export default function Thankyou() {
    return (
        <section className="relative h-screen flex items-center justify-center text-center bg-gray-50 dark:bg-slate-800">
            <div className="container relative">
                <div className="grid grid-cols-1">
                    <div className="title-heading text-center my-auto">
                        <div className="w-24 h-24 bg-primary-900/5 text-primary rounded-full text-5xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                           <MdOutlineThumbUpOffAlt/>
                        </div>
                        <h1 className="mt-6 mb-8 md:text-5xl text-3xl font-bold">¡Se ha registrado con éxito!</h1>
                        <p className="text-slate-500 max-w-xl mx-auto">
                            Gracias por registrarte en nuestra plataforma, te hemos enviado un correo electrónico para activar tu cuenta.
                        </p>

                        <div className="mt-6">
                            <Link to="/" className="btn bg-primary-900/5 hover:bg-primary-900 border-primary/10 hover:border-primary text-primary hover:text-white rounded-full">Volver</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
