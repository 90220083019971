import { atom } from 'recoil';

export const cursosAtom = atom({
    key: "cursosAtom",
    default: [],
})

export const cursosMetaAtom = atom({
    key: "cursosMetaAtom",
    default: [],
})

export const selectedCursosAtom = atom({
    key: "selectedCursosAtom",
    default: {},
})


export const cursosFiltersAtom = atom({
    key: "cursosFiltersAtom",
    default: {},
})

export const cursosCategoriasAtom = atom({
    key: "cursosCategoriasAtom",
    default: [],
})