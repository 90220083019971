import { useState, useEffect } from "react";
import useFetch from '../../../hooks/useFecth';
import { useRecoilState } from "recoil";
import { publicacionesAtom, selectedPublicacionAtom, categoriasAtom, publicacionesMetaAtom, solicitudesAtom, solicitudesMetaAtom, selectedSolicitudAtom, solicitudesFiltrosAtom } from "../../../stores";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import { useAuth } from '../../../hooks';
import { toast } from "react-toastify";
const initialLoading = {
    get: false,
    show: false,
    create: false,
    update: false,
    delete: false
}

const UseSolicitudesEmpleo = () => {
    const solicitudesFetch = useFetch();
    const paginationSolicitudes = [15, 30, 45, 60];
    const [loading, setLoading] = useState(initialLoading);
    const [totalElements, setTotalElements] = useState(0);
    const [solicitudes, setSolicitudes] = useRecoilState(solicitudesAtom);
    const [solicitudesMeta, setSolicitudesMeta] = useRecoilState(solicitudesMetaAtom);
    const [selectedSolicitud, setSelectedSolicitud] = useRecoilState(selectedSolicitudAtom);
    const [categorias, setCategorias] = useRecoilState(categoriasAtom);
    const [allEmpresas, setAllEmpresas] = useState([]);
    const navigate = useNavigate();
    const { userAuth, logout } = useAuth();
    // Filtros
    const [filters, setFilters] = useRecoilState(solicitudesFiltrosAtom);

    useEffect(() => {
        console.log(userAuth)
        listSolicitudesByUser({
            candidato_id: userAuth.candidato.id
        });

    }, [userAuth])


    function listSolicitudesByUser({ page = 1, per_page = '', candidato_id = userAuth.candidato.id, orden = '', fecha_inicio = '', fecha_fin = '', aceptado = '' }) {
        setLoading({ ...loading, get: true });
        return solicitudesFetch.get(`solicitud-inscripcion-oferta-empleos/?page=${page}&per_page=${per_page}&candidato_id=${candidato_id}&fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}&aceptado=${aceptado}&sort=updated_at&direction=${orden}`)
            .then(({ data, status }) => {
                setLoading({ ...loading, get: false });
                if (status === "error") {
                    toast.error(data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                } else {
                    console.log(data)
                    setSolicitudes(data);
                    setTotalElements(data.length);
                    return true;
                }
            })
    }

    return {
        loading,
        solicitudes,
        solicitudesMeta,
        filters,
        setFilters,
        listSolicitudesByUser
    }
}

export default UseSolicitudesEmpleo;
