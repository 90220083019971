import { atom } from "recoil";

export const ticketsAtom = atom({
    key: "ticketsAtom",
    default: [],
});

export const ticketsMetaAtom = atom({
    key: "ticketsMetaAtom",
    default: [],
});

export const ticketsFiltersAtom = atom({
    key: "ticketsFiltersAtom",
    default: {},
})

export const ticketsSelectedAtom = atom({
    key: "ticketsSelectedAtom",
    default: {},
});

export const ticketListAtom = atom({
    key: "ticketListAtom",
    default: {},
});

export const respuestasAtom = atom({
    key: "respuestasAtom",
    default: [],
});

export const respuestasMetaAtom = atom({
    key: "respuestasMetaAtom",
    default: [],
});

export const respuestasSelectedAtom = atom({
    key: "respuestasSelectedAtom",
    default: {},
});