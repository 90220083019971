import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../../components/Navbar";
import bg5 from "../../assets/images/hero/bg5.jpg";
import team1 from "../../assets/images/team/01.jpg";
import { Link } from "react-router-dom";
import { Twitter, Facebook, Instagram, Linkedin, Youtube, AlertTriangle } from "react-feather";

//importaciones mias
import Select from "react-select";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormacion } from "../../hooks";
import useCandidate from "../candidate/hooks/useCandidate";
import { initialCandidato, validationEditCandidato } from "../auth/constants";

import Switcher from "../../components/Switcher";


const sexos = [
  { value: "Masculino", label: "Masculino" },
  { value: "Femenino", label: "Femenino" },
  { value: "Otro", label: "Otro" },
];

const tiposDiscapacidadSelector = [
  { value: "Física", label: "Física" },
  { value: "Psíquica", label: "Psíquica" },
  { value: "Sensorial", label: "Sensorial" },
  { value: "Intelectual", label: "Intelectual" },
];

export default function CandidateProfileSetting() {
  const { formaciones } = useFormacion();
  const [formacionSelected, setFormacionSelected] = useState(null);
  const [sexoSelected, setSexoSelected] = useState(null);
  const [tiposDiscapacidadSelected, setTiposDiscapacidadSelected] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const { candidato, editCandidato, deleteCandidato } = useCandidate();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEditCandidato),
    defaultValues: useMemo(() => initialCandidato, [candidato]),
  });

  const tieneDiscapacidad = watch("tiene_discapacidad");

  const onSubmit = (data) => {
    delete data.password;
    const addCurriculum = {
      ...data,
      curriculum: "test",
    }
    editCandidato(addCurriculum, candidato?.id);
  };

  // formato de select
  const formacionesSelector = formaciones.map((formacion) => {
    return {
      value: formacion.id,
      label: formacion.name,
    };
  });

  useEffect(() => {
    // Si el checkbox principal de discapacidad está desmarcado,
    // también desmarca todas las opciones de discapacidad.
    if (!tieneDiscapacidad) {
      setValue("discapacidad_inferior_33", false);
      setValue("discapacidad_superior_33", false);
      setValue("discapacidad_superior_65", false);
      setValue("tipo_discapacidad", null);
    }
  }, [tieneDiscapacidad, setValue]);

  useEffect(() => {
    if (candidato) {
      setValue("name", candidato?.user?.name);
      setValue("surnames", candidato?.user?.surname);
      setValue("email", candidato?.user?.email);
      setValue("nif", candidato?.user?.nif);
      setValue("direccion", candidato?.direccion);
      setValue("telefono", candidato?.telefono);
      setValue("adaptacion_puesto", candidato?.adaptacion_puesto);

        //tiene discapacidad
        const tieneDiscapacidad = candidato?.tiene_discapacidad === 1;
        setValue("tiene_discapacidad", tieneDiscapacidad);
        //discapacidad inferior al 33
        const esDiscapacitadoInferior = candidato?.discapacidad_inferior_33 === 1;
        setValue("discapacidad_inferior_33", esDiscapacitadoInferior);
        //discapacidad superior al 33
        const esDiscapacitado = candidato?.discapacidad_superior_33 === 1;
        setValue("discapacidad_superior_33", esDiscapacitado);
        //discapacidad superior al 65
        const esDiscapacitadoSuperior = candidato?.discapacidad_superior_65 === 1;
        setValue("discapacidad_superior_65", esDiscapacitadoSuperior);
        //bolsa
        const estaInscritoBolsa = candidato?.inscrito_bolsa === 1;
        setValue("inscrito_bolsa", estaInscritoBolsa);
        //ofertas empleo
        const infoOfertasEmpleo = candidato?.info_ofertas_empleo === 1;
        setValue("info_ofertas_empleo", infoOfertasEmpleo);
        //ofertas formacion
        const infoOfertasFormacion = candidato?.info_ofertas_formacion === 1;
        setValue("info_ofertas_formacion", infoOfertasFormacion);
      //fecha de nacimiento
      if (candidato?.fecha_nacimiento) {
        let fechaStr = candidato?.fecha_nacimiento;
        let partes = fechaStr.split("-");
        let fecha = new Date(partes[2], partes[1] - 1, partes[0]);
        let fechaFormatted = fecha.toISOString().slice(0, 10);
        setValue("fecha_nacimiento", fechaFormatted);
      }
      //cualificacion
      register("cualificacion"); // Registra el campo para que React Hook Form lo reconozca
      if (candidato && formacionesSelector) {
        // Encuentra la opción por defecto basada en el valor de candidato
        const defaultValue = formacionesSelector.find(
          (option) => option.value === parseInt(candidato.cualificacion)
        );
        if (defaultValue) {
          setFormacionSelected(defaultValue);
          setValue("cualificacion", defaultValue.value); // Actualiza el valor en React Hook Form
        }
      }
      //sexo
      register("sexo");
      if (candidato?.sexo) {
        const defaultValueSexo = sexos.find(
          (option) => option.value === candidato.sexo
        );
        if (defaultValueSexo) {
          setSexoSelected(defaultValueSexo);
          setValue("sexo", defaultValueSexo.value); // Actualiza el valor en React Hook Form
        }
      }
      //tipo discapacidad
      register("tipo_discapacidad");
      if (candidato?.tipo_discapacidad) {
        const defaultValueTipoDiscapacidad = tiposDiscapacidadSelector.find(
          (option) => option.value === candidato.tipo_discapacidad
        );
        if (defaultValueTipoDiscapacidad) {
          setTiposDiscapacidadSelected(defaultValueTipoDiscapacidad);
          setValue("tipo_discapacidad", defaultValueTipoDiscapacidad.value); // Actualiza el valor en React Hook Form
        }
    }
  }
  }, [candidato, setValue]);


  // Función para manejar cambios en las opciones de discapacidad y asegurar que solo una esté marcada
  const handleOptionChange = (selectedOption) => {
    setValue("discapacidad_inferior_33", selectedOption === "discapacidad_inferior_33");
    setValue("discapacidad_superior_33", selectedOption === "discapacidad_superior_33");
    setValue("discapacidad_superior_65", selectedOption === "discapacidad_superior_65");
  };



  const stylesToDelete = {
    position: 'sticky !important',
    width: 'fit-content',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '0.375rem !important',
    overflow: 'hidden',
    marginBottom: '20px',
    bottom: '0 !important'
  }

  return (
    <>
      <Navbar />
      <section className="relative lg:mt-24 mt-[74px] pb-16">
        <div className="lg:container container-fluid">
          <div className="profile-banner relative text-transparent">
            {/* <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="hidden"
            /> */}
            <div className="relative shrink-0">
              <img
                src={bg5}
                className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700"
                id="profile-banner"
                alt="Fondo de perfil del candidato"
              />
              <label
                className="absolute inset-0"
                htmlFor="pro-banner"
              ></label>
            </div>
          </div>
          <div className="md:flex mx-4 -mt-12">
            <div className="md:w-full">
              <div className="relative flex items-end">
                <div className="profile-pic text-center">
                  {/* <input
                    id="pro-img"
                    name="profile-image"
                    type="file"
                    className="hidden"
                  /> */}
                  <div>
                    <div className="relative h-28 w-28 max-w-[112px] max-h-[112px] mx-auto">
                      <img
                        src={team1}
                        className="rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800"
                        id="profile-image"
                        alt=""
                      />
                      <label
                        className="absolute inset-0"
                        htmlFor="pro-img"
                      ></label>
                    </div>
                  </div>
                </div>
                <div className="ms-4">
                  <h5 className="text-lg font-semibold">
                    {candidato?.user
                      ? candidato?.user?.name + " " + candidato?.user.surname
                      : "Sin nombre y/o apellidos conocidos"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        { !candidato?.adaptacion_puesto &&
          <div className="lg:container container-fluid mt-8">
            <div className="flex gap-4 bg-slate-100 text-slate-900 rounded-md border p-6">
              <AlertTriangle />
              <p>Recuerda completar tu perfil añadiendo las necesidades de adaptación para tu puesto de trabajo.</p>
            </div>
          </div>
        }

<Switcher/>

        <div className={`container ${!candidato?.adptaciones ? 'mt-8' : 'mt-16'}`}>
          <div className="grid grid-cols-1 gap-[30px]">
            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">
                Detalles Personales :
              </h5>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                    <label className="form-label font-medium">
                      Nombre : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Nombre"
                      id="name"
                      name="name"
                      required={true}
                      {...register("name")}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Apellidos : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="surname"
                      id="surname"
                      name="name"
                      required={true}
                      {...register("surnames")}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Email : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="Email"
                      name="email"
                      required={true}
                      {...register("email")}
                    />
                  </div>
                  
                  <div>
                    <label className="form-label font-medium">
                      NIF : <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="NIF"
                      name="nif"
                      required={true}
                      {...register("nif")}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Dirección :
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="C/ Ejemplo, 123, 1ºA..."
                      id="direccion"
                      name="direccion"
                      required={false}
                      {...register("direccion")}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Teléfono :
                    </label>
                    <input
                      type="text"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      placeholder="C/ Ejemplo, 123, 1ºA..."
                      id="telefono"
                      name="telefono"
                      required={false}
                      {...register("telefono")}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Sexo:
                    </label>
                    <Select
                      options={sexos}
                      value={sexoSelected}
                      className="mt-2"
                      placeholder="Elige una opción"
                      onChange={(selected) => {
                        setSexoSelected(selected);
                        setValue("sexo", selected.value);
                      }}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Formación :
                    </label>
                    <Select
                      options={formacionesSelector}
                      value={formacionSelected}
                      className="mt-2"
                      placeholder="Elige una opción"
                      onChange={(selected) => {
                        setFormacionSelected(selected);
                        setValue("cualificacion", selected.value);
                      }}
                    />
                  </div>
                  <div>
                    <label className="form-label font-medium">
                      Fecha de nacimiento :{" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                      name="fecha_nacimiento"
                      required={true}
                      {...register("fecha_nacimiento")}
                    />
                  </div>
                  <div className="flex justify-between pb-4"></div>
                  <div className="flex justify-between pb-4">
                    <h6 className="mb-0 font-medium">
                      ¿Tienes reconocida una discapacidad?
                    </h6>
                    <div className="">
                      <input
                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                        type="checkbox"
                        {...register("tiene_discapacidad")}
                        
                        id="tiene_discapacidad"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="tiene_discapacidad"
                      ></label>
                    </div>
                  </div>
                  <div className="flex justify-between pb-4"></div>
                  {tieneDiscapacidad && (
                  <>
                  <div className="flex justify-between pb-4">
                    <h6 className="mb-0 font-medium">
                      ¿Discapacidad inferior al 33%?
                    </h6>
                    <div className="">
                      <input
                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                        type="checkbox"
                        {...register("discapacidad_inferior_33")}
                        onChange={() => handleOptionChange("discapacidad_inferior_33")}
                        id="discapacidad_inferior_33"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="discapacidad_inferior_33"
                      ></label>
                    </div>
                  </div>
                  <div className="flex justify-between pb-4"></div>
                  <div className="flex justify-between pb-4">
                    <h6 className="mb-0 font-medium">
                      ¿Discapacidad superior al 33%?
                    </h6>
                    <div className="">
                      <input
                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                        type="checkbox"
                        {...register("discapacidad_superior_33")}
                        onChange={() => handleOptionChange("discapacidad_superior_33")}
                        id="discapacidad"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="discapacidad"
                      ></label>
                    </div>
                  </div>
                  <div className="flex justify-between pb-4"></div>
                  <div className="flex justify-between pb-4">
                    <h6 className="mb-0 font-medium">
                      ¿Discapacidad superior al 65%?
                    </h6>
                    <div className="">
                      <input
                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                        type="checkbox"
                        {...register("discapacidad_superior_65")}
                        onChange={() => handleOptionChange("discapacidad_superior_65")}
                        id="discapacidad_superior_65"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="discapacidad_superior_65"
                      ></label>
                    </div>
                  </div>
                  <div className="flex justify-between pb-4"></div>
                  <div>
                    <label className="form-label font-medium">
                      Tipo de discapacidad :
                    </label>
                    <Select
                      options={tiposDiscapacidadSelector}
                      value={tiposDiscapacidadSelected}
                      className="mt-2"
                      placeholder="Elige una opción"
                      onChange={(selected) => {
                        setTiposDiscapacidadSelected(selected);
                        setValue("tipo_discapacidad", selected.value);
                      }}
                    />
                  </div>
                 
                  <div className="flex justify-between pb-4">

                    <div className="">

                      <label
                        className="form-check-label"
                        htmlFor=""
                      ></label>
                    </div>
                  </div>
                  </>
                  )}
                  <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                    <h5 className="text-lg font-semibold mb-5">
                      Ajustes de notificaciones:
                    </h5>
                    <div className="flex justify-between pb-4">
                      <h6 className="mb-0 font-medium">
                        ¿Inscribirse en la bolsa de empleo?
                      </h6>
                      <div className="">
                        <input
                          className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                          type="checkbox"
                          {...register("inscrito_bolsa")}
                          id="inscrito_bolsa"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inscrito_bolsa"
                        ></label>
                      </div>
                    </div>
                    <div className="flex justify-between pb-4">
                      <h6 className="mb-0 font-medium">
                        ¿Recibir información sobre empleos?
                      </h6>
                      <div className="">
                        <input
                          className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                          type="checkbox"
                          {...register("info_ofertas_empleo")}
                          id="info_ofertas_empleo"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="info_ofertas_empleo"
                        ></label>
                      </div>
                    </div>
                    <div className="flex justify-between pb-4">
                      <h6 className="mb-0 font-medium">
                        ¿Recibir información sobre cursos formativos?
                      </h6>
                      <div className="">
                        <input
                          className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                          type="checkbox"
                          {...register("info_ofertas_formacion")}
                          id="info_ofertas_formacion"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="info_ofertas_formacion"
                        ></label>
                      </div>
                    </div>
                  </div>

                  {/* INFO SOBRE ADAPTACIONES EN EL PUESTO DE TRABAJO */}
                  <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                    <div className="flex gap-2 items-center mb-2">
                      <h5 className="text-lg font-semibold">
                        Adaptaciones en el puesto de trabajo
                      </h5>
                      {
                        !candidato?.adaptacion_puesto &&
                        <div className="flex justify-center p-2 rounded-full bg-slate-100"><AlertTriangle size={20} /></div>
                      }
                    </div>
                    <label className="form-label leading-none text-sm" htmlFor="adaptaciones">Describe tus necesidades de apoyo, ajuste y/o adaptación al puesto</label>
                    <textarea
                      className="form-textarea border border-slate-100 rounded-md dark:border-slate-800 block w-full mt-2 placeholder:text-sm text-sm"
                      placeholder="Escribe las adaptaciones necesarias para tu puesto de trabajo"
                      rows="4"
                      name="adaptaciones"
                      required={true}
                      {...register("adaptacion_puesto")}
                    />
                  </div>
                  {/* <div>
                    <label className="form-label font-medium">Location :</label>
                    <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-2">
                      <option value="NY">New York</option>
                      <option value="MC">North Carolina</option>
                      <option value="SC">South Carolina</option>
                    </select>
                  </div> */}
                  {/* <div>
                    <label
                      className="form-label font-medium"
                      htmlFor="multiple_files"
                    >
                      Upload Resume:
                    </label>
                    <input
                      className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-2"
                      id="multiple_files"
                      type="file"
                      multiple
                    />
                  </div> */}
                </div>

                {/* <div className="grid grid-cols-1">
                  <div className="mt-5">
                    <label className="form-label font-medium">Intro : </label>
                    <textarea
                      name="comments"
                      id="comments"
                      className="form-input border border-slate-100 dark:border-slate-800 mt-2 textarea"
                      placeholder="Intro :"
                    ></textarea>
                  </div>
                </div> */}

                <input
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn bg-primary-900 hover:bg-primary-900 text-white rounded-md mt-5 cursor-pointer"
                  value="Guardar Cambios"
                />
              </form>
            </div>

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                <div>
                  <h5 className="text-lg font-semibold mb-4">Contact Info :</h5>

                  <form>
                    <div className="grid grid-cols-1 gap-4">
                      <div>
                        <label className="form-label font-medium">
                          Phone No. :
                        </label>
                        <input
                          name="number"
                          id="number"
                          type="number"
                          className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                          placeholder="Phone :"
                        />
                      </div>

                      <div>
                        <label className="form-label font-medium">
                          Website :
                        </label>
                        <input
                          name="url"
                          id="url"
                          type="url"
                          className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                          placeholder="Url :"
                        />
                      </div>
                    </div>

                    <button className="btn bg-primary-900 hover:bg-primary-900 text-white rounded-md mt-5">
                      Add
                    </button>
                  </form>
                </div>

                <div>
                  <h5 className="text-lg font-semibold mb-4">
                    Change password :
                  </h5>
                  <form>
                    <div className="grid grid-cols-1 gap-4">
                      <div>
                        <label className="form-label font-medium">
                          Old password :
                        </label>
                        <input
                          type="password"
                          className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                          placeholder="Old password"
                          required=""
                        />
                      </div>

                      <div>
                        <label className="form-label font-medium">
                          New password :
                        </label>
                        <input
                          type="password"
                          className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                          placeholder="New password"
                          required=""
                        />
                      </div>

                      <div>
                        <label className="form-label font-medium">
                          Re-type New password :
                        </label>
                        <input
                          type="password"
                          className="form-input border border-slate-100 dark:border-slate-800 mt-2"
                          placeholder="Re-type New password"
                          required=""
                        />
                      </div>
                    </div>

                    <button className="btn bg-primary-900 hover:bg-primary-900 text-white rounded-md mt-5">
                      Save password
                    </button>
                  </form>
                </div>
              </div>
            </div> */}

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-4">Social Media :</h5>

              <div className="md:flex">
                <div className="md:w-1/3">
                  <span className="font-medium">Twitter</span>
                </div>

                <div className="md:w-2/3 mt-4 md:mt-0">
                  <form>
                    <div className="form-icon relative">
                      <Twitter className="w-4 h-4 absolute top-5 start-4" />
                      <input
                        type="text"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12"
                        placeholder="Twitter Profile Name"
                        id="twitter_name"
                        name="name"
                        required=""
                      />
                    </div>
                  </form>

                  <p className="text-slate-400 mt-1">
                    Add your Twitter username (e.g. jennyhot).
                  </p>
                </div>
              </div>

              <div className="md:flex mt-8">
                <div className="md:w-1/3">
                  <span className="font-medium">Facebook</span>
                </div>

                <div className="md:w-2/3 mt-4 md:mt-0">
                  <form>
                    <div className="form-icon relative">
                      <Facebook className="w-4 h-4 absolute top-5 start-4" />
                      <input
                        type="text"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12"
                        placeholder="Facebook Profile Name"
                        id="facebook_name"
                        name="name"
                        required=""
                      />
                    </div>
                  </form>

                  <p className="text-slate-400 mt-1">
                    Add your Facebook username (e.g. jennyhot).
                  </p>
                </div>
              </div>

              <div className="md:flex mt-8">
                <div className="md:w-1/3">
                  <span className="font-medium">Instagram</span>
                </div>

                <div className="md:w-2/3 mt-4 md:mt-0">
                  <form>
                    <div className="form-icon relative">
                      <Instagram className="w-4 h-4 absolute top-5 start-4" />
                      <input
                        type="text"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12"
                        placeholder="Instagram Profile Name"
                        id="insta_name"
                        name="name"
                        required=""
                      />
                    </div>
                  </form>

                  <p className="text-slate-400 mt-1">
                    Add your Instagram username (e.g. jennyhot).
                  </p>
                </div>
              </div>

              <div className="md:flex mt-8">
                <div className="md:w-1/3">
                  <span className="font-medium">Linkedin</span>
                </div>

                <div className="md:w-2/3 mt-4 md:mt-0">
                  <form>
                    <div className="form-icon relative">
                      <Linkedin className="w-4 h-4 absolute top-5 start-4" />
                      <input
                        type="text"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12"
                        placeholder="Linkedin Profile Name"
                        id="linkedin_name"
                        name="name"
                        required=""
                      />
                    </div>
                  </form>

                  <p className="text-slate-400 mt-1">
                    Add your Linkedin username.
                  </p>
                </div>
              </div>

              <div className="md:flex mt-8">
                <div className="md:w-1/3">
                  <span className="font-medium">Youtube</span>
                </div>

                <div className="md:w-2/3 mt-4 md:mt-0">
                  <form>
                    <div className="form-icon relative">
                      <Youtube className="w-4 h-4 absolute top-5 start-4" />
                      <input
                        type="url"
                        className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12"
                        placeholder="Youtube url"
                        id="you_url"
                        name="url"
                        required=""
                      />
                    </div>
                  </form>

                  <p className="text-slate-400 mt-1">Add your Youtube url.</p>
                </div>
              </div>

              <div className="md:flex">
                <div className="md:w-1/3">
                  <input
                    type="submit"
                    id="submit"
                    name="send"
                    className="btn bg-primary-900 hover:bg-primary-900 text-white rounded-md mt-5"
                    value="Save Changes"
                  />
                </div>
              </div>
            </div> */}

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-5">
                Account Notifications :
              </h5>

              <div className="flex justify-between pb-4">
                <h6 className="mb-0 font-medium">When someone mentions me</h6>
                <div className="">
                  <input
                    className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                    type="checkbox"
                    value=""
                    id="noti1"
                  />
                  <label className="form-check-label" htmlFor="noti1"></label>
                </div>
              </div>
              <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                <h6 className="mb-0 font-medium">When someone follows me</h6>
                <div className="">
                  <input
                    className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                    type="checkbox"
                    value=""
                    defaultChecked
                    id="noti2"
                  />
                  <label className="form-check-label" htmlFor="noti2"></label>
                </div>
              </div>
              <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                <h6 className="mb-0 font-medium">When shares my activity</h6>
                <div className="">
                  <input
                    className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                    type="checkbox"
                    value=""
                    id="noti3"
                  />
                  <label className="form-check-label" htmlFor="noti3"></label>
                </div>
              </div>
              <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                <h6 className="mb-0 font-medium">When someone messages me</h6>
                <div className="">
                  <input
                    className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                    type="checkbox"
                    value=""
                    id="noti4"
                  />
                  <label className="form-check-label" htmlFor="noti4"></label>
                </div>
              </div>
            </div> */}

            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <h5 className="text-lg font-semibold mb-5">
                Marketing Notifications :
              </h5>

              <div className="flex justify-between pb-4">
                <h6 className="mb-0 font-medium">
                  There is a sale or promotion
                </h6>
                <div className="">
                  <input
                    className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                    type="checkbox"
                    value=""
                    id="noti5"
                  />
                  <label className="form-check-label" htmlFor="noti5"></label>
                </div>
              </div>
              <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                <h6 className="mb-0 font-medium">Company news</h6>
                <div className="">
                  <input
                    className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                    type="checkbox"
                    value=""
                    id="noti6"
                  />
                  <label className="form-check-label" htmlFor="noti6"></label>
                </div>
              </div>
              <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                <h6 className="mb-0 font-medium">Weekly jobs</h6>
                <div className="">
                  <input
                    className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                    type="checkbox"
                    value=""
                    defaultChecked
                    id="noti7"
                  />
                  <label className="form-check-label" htmlFor="noti7"></label>
                </div>
              </div>
              <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                <h6 className="mb-0 font-medium">Unsubscribe News</h6>
                <div className="">
                  <input
                    className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                    type="checkbox"
                    value=""
                    defaultChecked
                    id="noti8"
                  />
                  <label className="form-check-label" htmlFor="noti8"></label>
                </div>
              </div>
            </div> */}

            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              {isOpen ? (
                <>
                  <h5 className="text-lg font-semibold mb-5 text-red-600">¿Estás seguro?</h5>
                  <p>¿Estás seguro de querer borrar tu cuenta? Esta acción no se puede desahacer</p>
                  <div className="flex gap-3 mt-4 ">
                    <button
                      className="btn bg-slate-100 border rounded-md text-slate-600 hover:bg-slate-200"
                      onClick={() => setOpen(false)}
                    >
                      Cancelar
                    </button>
                    <button
                      className="btn bg-red-600 hover:bg-red-700 text-white rounded-md"
                      onClick={() => deleteCandidato(candidato.id)}
                    >
                      Sí, borrar cuenta
                    </button>
                  </div>
                </>
              ) : (<>
                <h5 className="text-lg font-semibold mb-5">
                  Borrar cuenta
                </h5>

                <p className="text-slate-400 mb-4">
                  ¿Quieres eliminar tu cuenta? Por favor pulse el siguiente botón
                  "Borrar Cuenta"
                </p>

                <button
                  onClick={() => setOpen(true)}
                  className="btn bg-red-600 hover:bg-red-700 text-white rounded-md"
                >
                  Borrar Cuenta
                </button>
              </>)}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
