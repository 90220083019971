import logo_light from '../assets/images/logo-light.png';
import React from 'react'
import { Link } from "react-router-dom";
import {AiOutlineShoppingCart, LuMail, BiLogoDribbble, AiOutlineBehance, BiLogoLinkedin, FaFacebookF, FaInstagram, IoLogoTwitter} from "../assets/icons/vander"
import logoWhite from "../assets/images/logo-white.webp"

export default function Footer() {

    return (
        <footer className="relative bg-slate-900 dark:bg-slate-800">
            <div className="container">
                <div className="grid grid-cols-1">
                    <div className="relative py-12">

                        <div className="relative w-full">
                            <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                                <div className="md:col-span-3">
                                    <Link to="/" className="flex justify-center md:justify-start focus:outline-none">
                                        <img src={logoWhite} className="" alt="" />
                                    </Link>
                                </div>

                                <div className="md:col-span-9">
                                    <ul className="list-disc footer-list ltr:md:text-right rtl:md:text-left text-center space-x-3">
                                        <li className="inline-block"><Link to="/" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium me-4">Inicio</Link></li>
                                        <li className="inline-block mt-[10px] md:mt-0"><Link to="/sobre-nosotros" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium me-4">Aicor Empleo</Link></li>
                                        <li className="inline-block mt-[10px] md:mt-0"><Link to="/empleos" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium me-4">Empleos</Link></li>
                                        <li className="inline-block mt-[10px] md:mt-0"><Link to="/" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium me-4">Formación</Link></li>
                                        <li className="inline-block mt-[10px] md:mt-0"><Link to="/contacto" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium">Contacto</Link></li>
                                        <li className="inline-block mt-[10px] md:mt-0"><Link to="/terms" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium">Términos legales</Link></li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
                <div className="container text-center">
                    <div className="grid md:grid-cols-2 items-center gap-6">
                        <div className="ltr:md:text-left rtl:md:text-right text-center">
                            <p className="mb-0 text-gray-300 font-medium">©{(new Date().getFullYear())}{" "} Aicor Empleo. Desarrollado por <Link to="https://www.aicor.com/" target="_blank" className="text-reset">Aicor</Link>.</p>
                        </div>

                        <ul className="list-none ltr:md:text-right rtl:md:text-left text-center space-x-0.5">
                            {/* <li className="inline"><Link to="https://1.envato.market/jobstack-react" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 text-white"><AiOutlineShoppingCart/></Link></li> */}
                            {/* <li className="inline"><Link to="https://dribbble.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 text-white"><BiLogoDribbble/></Link></li> */}
                            {/* <li className="inline"><Link to="https://www.behance.net/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 text-white"><AiOutlineBehance/></Link></li> */}
                            <li className="inline"><Link to="https://www.linkedin.com/company/fepamic-servicios-para-personas-dependientes-sl/" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 text-white"><BiLogoLinkedin/></Link></li>
                            <li className="inline"><Link to="https://www.facebook.com/centrosfepamic/?locale=es_ES" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 text-white"><FaFacebookF/></Link></li>
                            <li className="inline"><Link to="https://www.facebook.com/centrosfepamic/?locale=es_ES" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 text-white"><FaInstagram/></Link></li>
                            <li className="inline"><Link to="https://twitter.com/Fepamic" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 text-white"><svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill="#ffffff" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></Link></li>
                            {/* <li className="inline"><Link to="mailto:support@shreethemes.in" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-primary dark:hover:border-primary hover:bg-primary-900 dark:hover:bg-primary-900 text-white"><LuMail/></Link></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
