import React, { useState, useEffect } from "react";
import { LuSearch } from "../../assets/icons/vander";
import useJobs from "./hooks/useJobs";
import {
  MapPin,
} from "react-feather";

const JobFilters = ({ busqueda }) => {
  const [selectedTipoEmpleo, setSelectedTipoEmpleo] = useState("");
  const [selectedJornada, setSelectedJornada] = useState("");
  const [selectedSalario, setSelectedSalario] = useState("");
  const [selectedCategoria, setSelectedCategoria] = useState("");
  const busquedaParam = busqueda

  const {
    categorias,
    getCategoriasEmpleo,
    handleChangeFilters,
    filters,
    getEmpleos,
    currentPage,
  } = useJobs();

  useEffect(() => {
    getCategoriasEmpleo();
  }, []);

  useEffect(() => {

    // Función para limpiar los parámetros de búsqueda de la URL
    const limpiarParametrosDeBusqueda = () => {
      const urlSinParametros = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState(null, '', urlSinParametros);
    };

    if (busquedaParam !== '' && busquedaParam !== null && busquedaParam !== undefined) {
      const auxFilters = {
        name: busquedaParam,
        categoria: "",
        lugarTrabajo: "",
        tipo_contrato: null,
        jornada: null,
        salario: null,
      };
      handleJornadaChange(null);
      handleSalarioChange(null);
      handleTipoEmpleoChange(null);

      handleChangeFilters(auxFilters);
      getEmpleos({ ...auxFilters, page: currentPage });
      limpiarParametrosDeBusqueda();
    }
  }, [])

  const handleTipoEmpleoChange = (value) => {
    setSelectedTipoEmpleo(value);
  };

  const handleJornadaChange = (value) => {
    setSelectedJornada(value);
  };

  const handleSalarioChange = (value) => {
    setSelectedSalario(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getEmpleos({ ...filters, page: currentPage });
  };

  const handleResetFilters = () => {
    const auxFilters = {
      name: "",
      categoria: "",
      lugarTrabajo: "",
      tipo_contrato: null,
      jornada: null,
      salario: null,
    };
    handleJornadaChange(null);
    handleSalarioChange(null);
    handleTipoEmpleoChange(null);

    handleChangeFilters(auxFilters);
    getEmpleos({ ...auxFilters, page: currentPage });
  };

  return (
    <div className="lg:col-span-4 md:col-span-6">
      <div className="shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900 sticky top-20">
        <form>
          <div className="grid grid-cols-1 gap-3">
            <div>
              <label htmlFor="searchname" className="font-semibold">
                Nombre
              </label>
              <div className="relative mt-2">
                <LuSearch className="text-lg absolute top-[10px] start-3" />
                <input
                  name="name"
                  id="name"
                  type="text"
                  className="form-input border border-slate-100 dark:border-slate-800 ps-10"
                  placeholder="Nombre"
                  value={filters.name}
                  onChange={(e) =>
                    handleChangeFilters({ ...filters, name: e.target.value })
                  }
                />
              </div>
            </div>

            <div>
              <label className="font-semibold">Categorías</label>
              <select
                name="categoria"
                id="categoria"
                className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                onChange={(e) =>
                  handleChangeFilters({ ...filters, categoria: e.target.value })
                }
                value={filters.categoria}
              >
                <option value="">Selecciona una opción</option>
                {categorias?.map((categoria) => (
                  <option key={categoria.id} value={categoria.id}>
                    {categoria.nombre}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="font-semibold" htmlFor="lugarTrabajo">
                Localización
              </label>
              <div className="relative mt-2">
                <MapPin className="absolute top-[10px] start-3 text-slate-600 w-5 h-5" />
                <input
                  name="lugarTrabajo"
                  id="lugarTrabajo"
                  type="text"
                  className="form-input border border-slate-100 dark:border-slate-800 ps-10"
                  placeholder="Localización"
                  onChange={(e) =>
                    handleChangeFilters({
                      ...filters,
                      lugarTrabajo: e.target.value,
                    })
                  }
                  value={filters.lugarTrabajo}
                />
              </div>
              {/* <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                                <option value="cordoba">Córdoba</option>
                                <option value="cardena">Cardeña</option>
                                <option value="lucena">Lucena</option>
                            </select> */}
            </div>

            <div>
              <label className="font-semibold">Tipo de empleo</label>
              <div className="block mt-2">
                <div>
                  <label
                    htmlFor="indefinido"
                    className="inline-flex items-center"
                  >
                    <input
                      type="radio"
                      name="tipo_contrato"
                      id="indefinido"
                      className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      value="indefinido"
                      checked={filters.tipo_contrato === "indefinido"}
                      onChange={(e) =>
                        handleChangeFilters({
                          ...filters,
                          tipo_contrato: e.target.value,
                        })
                      }
                    />
                    <span className="ms-2 text-slate-400">Indefinido</span>
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="temporal"
                    className="inline-flex items-center"
                  >
                    <input
                      type="radio"
                      name="tipo_contrato"
                      id="temporal"
                      className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      value="temporal"
                      checked={filters.tipo_contrato === "temporal"}
                      onChange={(e) =>
                        handleChangeFilters({
                          ...filters,
                          tipo_contrato: e.target.value,
                        })
                      }
                    />
                    <span className="ms-2 text-slate-400">Temporal</span>
                  </label>
                </div>
              </div>
            </div>

            <div>
              <label className="font-semibold">Tipo de jornada</label>
              <div className="block mt-2">
                <div>
                  <label
                    htmlFor="completa"
                    className="inline-flex items-center"
                  >
                    <input
                      type="radio"
                      id="completa"
                      name="jornada"
                      className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      value="completa"
                      defaultChecked={false}
                      checked={filters.jornada === "completa"}
                      onChange={(e) =>
                        handleChangeFilters({
                          ...filters,
                          jornada: e.target.value,
                        })
                      }
                    />
                    <span className="ms-2 text-slate-400">
                      Jornada completa
                    </span>
                  </label>
                </div>
                <div>
                  <label htmlFor="parcial" className="inline-flex items-center">
                    <input
                      type="radio"
                      id="parcial"
                      name="jornada"
                      defaultChecked={false}
                      className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      value="parcial"
                      checked={filters.jornada === "parcial"}
                      onChange={(e) =>
                        handleChangeFilters({
                          ...filters,
                          jornada: e.target.value,
                        })
                      }
                    />
                    <span className="ms-2 text-slate-400">Jornada parcial</span>
                  </label>
                </div>
              </div>
            </div>

            <div>
              <label className="font-semibold">Franja salarial</label>
              <div className="block mt-2">
                <div>
                  <label
                    htmlFor="salario-1"
                    className="inline-flex items-center"
                  >
                    <input
                      type="radio"
                      name="salario"
                      id="salario-1"
                      className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      value="menos de 15000"
                      checked={filters.salario === "menos de 15000"}
                      onChange={(e) =>
                        handleChangeFilters({
                          ...filters,
                          salario: e.target.value,
                        })
                      }
                    />
                    <span className="ms-2 text-slate-400">Menos de 15.000</span>
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="salario-2"
                    className="inline-flex items-center"
                  >
                    <input
                      type="radio"
                      name="salario"
                      id="salario-2"
                      className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      value="de 15000 a 18000"
                      checked={filters.salario === "de 15000 a 18000"}
                      onChange={(e) =>
                        handleChangeFilters({
                          ...filters,
                          salario: e.target.value,
                        })
                      }
                    />
                    <span className="ms-2 text-slate-400">
                      De 15.000 a 18.000
                    </span>
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="salario-3"
                    className="inline-flex items-center"
                  >
                    <input
                      type="radio"
                      name="salario"
                      id="salario-3"
                      className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      value="de 18000 a 25000"
                      checked={filters.salario === "de 18000 a 25000"}
                      onChange={(e) =>
                        handleChangeFilters({
                          ...filters,
                          salario: e.target.value,
                        })
                      }
                    />
                    <span className="ms-2 text-slate-400">
                      De 18.000 a 25.000
                    </span>
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="salario-4"
                    className="inline-flex items-center"
                  >
                    <input
                      type="radio"
                      name="salario"
                      id="salario-4"
                      className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      value="más de 25000"
                      checked={filters.salario === "más de 25000"}
                      onChange={(e) =>
                        handleChangeFilters({
                          ...filters,
                          salario: e.target.value,
                        })
                      }
                    />
                    <span className="ms-2 text-slate-400">Más de 25.000</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex gap-2 mt-4">
              <button
                onClick={(e) => handleSubmit(e)}
                className="btn bg-primary-900 hover:bg-black border-primary hover:border-black text-white rounded-md w-full"
              >
                Aplicar filtros
              </button>
              <button
                type="button"
                className="btn bg-primary-900 hover:bg-black border-primary hover:border-black text-white rounded-md w-full"
                onClick={handleResetFilters}
              >
                Limpiar filtros
              </button>
            </div>
            <div></div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JobFilters;
