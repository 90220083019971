import { atom } from 'recoil';

export const publicacionesAtom = atom({
    key: "publicacionesAtom",
    default: [],
})

export const publicacionesMetaAtom = atom({
    key: "publicacionesMetaAtom",
    default: [],
})

export const selectedPublicacionAtom = atom({
    key: "selectedPublicacionAtom",
    default: {},
})

export const categoriasAtom = atom({
    key: "categoriasAtom",
    default: {},
})

export const solicitudesAtom = atom({
    key: "solicitudesAtom",
    default: [],
})

export const solicitudesMetaAtom = atom({
    key: "solicitudesMetaAtom",
    default: [],
})

export const selectedSolicitudAtom = atom({
    key: "selectedSolicitudAtom",
    default: {},
})

export const solicitudesFiltrosAtom = atom({
    key: "solicitudesFiltrosAtom",
    default: [],
})