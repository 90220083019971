import React from 'react';
import { CheckSquare, Trash } from 'react-feather';
import UseNotificaciones from '../hooks/useNotificaciones';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

const NotificacionesList = () => {

    const { notificaciones, marcarComoLeido } = UseNotificaciones();

    return (
        <ul className='col-span-12'>
            {notificaciones?.map((notificacion) => {

                const fecha = parseISO(notificacion?.updated_at);
                const resultado = formatDistanceToNow(fecha, { addSuffix: true, locale: es });
                return (
                    <li className='flex justify-between items-center border rounded-lg p-6 mb-2 shadow' key={notificacion.id} >
                        <div>
                            <p>{notificacion.tipo_notificacion}</p>
                            <small>Última actualización: {resultado}</small>
                        </div>
                        <div className='flex gap-2'>
                            <button
                                onClick={() => marcarComoLeido(notificacion.id)}
                                className='flex items-center gap-2 btn btn-primary border-primary rounded-md bg-primary-900 text-white hover:border-black hover:bg-black transition-colors'
                            >
                                <CheckSquare className='w-4 h-4' />
                                Marcar como leída
                            </button>
                        </div>
                    </li>
                )
            })
            }
        </ul >
    );
}

export default NotificacionesList;
