import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import Index from "./pages/index";
import React, { useEffect } from "react";
import IndexTwo from "./pages/index/index-two";
import IndexThree from "./pages/index/index-three";
import IndexFour from "./pages/index/index-four";
import IndexFive from "./pages/index/index-five";
import IndexSix from "./pages/index/index-six";
import IndexSeven from "./pages/index/index-seven";
import Comingsoon from "./pages/comingsoon";
import Maintenance from "./pages/maintenance";
import Error from "./pages/error";
import Thankyou from "./pages/thankyou";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Login from "./pages/auth/login";
import Signup from "./pages/auth/signup";
import ResetPassword from "./pages/auth/reset-password";
import Support from "./pages/helpcenter/support";
import Guides from "./pages/helpcenter/guides";

import JobGridsOne from "./pages/job-grids/job-grids-one";
import JobGridsTwo from "./pages/job-grids/job-grids-two";
import JobGridsThree from "./pages/job-grids/job-grids-three";
import JobGridsFour from "./pages/job-grids/job-grids-four";
import JobListOne from "./pages/job-list/job-list-one";
import JobListTwo from "./pages/job-list/job-list-two";
import JobListThree from "./pages/job-list/job-list-three";
// import JobListFour from "./pages/job-list/job-list-four";
import JobsPage from "./pages/job-list/JobsPage";
import JobDetail from './pages/job-list/JobDetail'
import JobListFive from "./pages/job-list/job-list-five";
import JobListSix from "./pages/job-list/job-list-six";
import JobDetailOne from "./pages/job-detail/job-detail-one";
import JobDetailTwo from "./pages/job-detail/job-detail-two";
import JobDetailThree from "./pages/job-detail/job-detail-three";
import Overview from "./pages/helpcenter/overview";
import Faqs from "./pages/helpcenter/faqs";
import Aboutus from "./pages/aboutus";
import Services from "./pages/services";
import Pricing from "./pages/pricing";
import Employerlist from "./pages/employer/list";
import EmployerDetail from "./pages/employer/detail";
import CandidateList from "./pages/candidate/list";
import CandidateDetail from "./pages/candidate/detail";
import CursosList from "./pages/candidate/CursosList";
import EmpleoList from "./pages/candidate/EmpleoList";
import JobPost from "./pages/job-post";
import Career from "./pages/career";
import Contact from "./pages/contact";
import SupportPage from "./pages/support/SupportPage";
import SupportDetail from "./pages/support/SupportDetail";
// import Switcher from "./components/Switcher";
// import Blogs from "./pages/blog/blogs";
import Blog from "./pages/blog/Blog";
import Post from "./pages/blog/Post";
import LockScreen from "./pages/auth/lock-screen";
import JobCategories from "./pages/job-categories";
import ScrollToTop from "./components/Scroll-top";
import IndexEight from "./pages/index/index-eight";
import CandidateProfileSetting from "./pages/candidate/candidate-profile-setting";
import CoursePage from './pages/course-list/CoursePage';
import CourseDetail from "./pages/course-list/CourseDetail";
import CourseApply from "./pages/course-list/CourseApply";
import JobApply from "./pages/job-list/JobApply";
import NotificacionesPage from "./pages/notificaciones/NotificacionesPage";
import BolsaDeEmpleo from "./pages/job-list/BolsaDeEmpleo";
import RegistroPage from "./pages/auth/RegistroPage";

function App() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
  }, []);

  return (
    <RecoilRoot>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<IndexTwo />} />
          <Route path="/index" element={<IndexTwo />} />
          {/*  <Route path="/index-two" element={<IndexTwo />} />
          <Route path="/index-three" element={<IndexThree />} />
          <Route path="/index-four" element={<IndexFour />} />
          <Route path="/index-five" element={<IndexFive />} />
          <Route path="/index-six" element={<IndexSix />} />
          <Route path="/index-seven" element={<IndexSeven />} />
          <Route path="/index-eight" element={<IndexEight />} /> */}

          {/* <Route path="/job-categories" element={<JobCategories />} />
          <Route path="/job-post" element={<JobPost />} />
          <Route path="/job-grid-one" element={<JobGridsOne />} />
          <Route path="/job-grid-two" element={<JobGridsTwo />} />
          <Route path="/job-grid-three" element={<JobGridsThree />} />
          <Route path="/job-grid-four" element={<JobGridsFour />} />
          <Route path="/job-list-one" element={<JobListOne />} />
          <Route path="/job-list-two" element={<JobListTwo />} />
          <Route path="/job-list-three" element={<JobListThree />} /> */}
          {/* <Route path="/job-list-four" element={<JobListFour />} /> */}
          <Route path="/empleos" element={<JobsPage />} />
          <Route path="/empleos/:id" element={<JobDetail />} />
          <Route
            path="/empleos/:id/solicitud"
            element={
              <PrivateRoute requiredPermissions={[
                "panel.solicitud-inscripcion-oferta-empleos.store"
              ]}>
                <JobApply />
              </PrivateRoute>
            }
          />
          {/* <Route path="/job-list-five" element={<JobListFive />} />
          <Route path="/job-list-six" element={<JobListSix />} />
          <Route path="/job-detail-one/:id" element={<JobDetailOne />} />
          <Route path="/job-detail-one" element={<JobDetailOne />} />
          <Route path="/job-detail-two/:id" element={<JobDetailTwo />} />
          <Route path="/job-detail-two" element={<JobDetailTwo />} />
          <Route path="/job-detail-three/:id" element={<JobDetailThree />} />
          <Route path="/job-detail-three" element={<JobDetailThree />} /> */}

          <Route path="/cursos" element={<CoursePage />} />
          <Route
            path="/cursos/:id/solicitud"
            element={
              <PrivateRoute requiredPermissions={[
                "panel.solicitud-inscripcion-cursos-formacion.store"
              ]}>
                <CourseApply />
              </PrivateRoute>
            }
          />
          <Route path="/cursos/:id" element={<CourseDetail />} />

          {/* <Route path="/helpcenter-faqs" element={<Faqs />} />
          <Route path="/helpcenter-overview" element={<Overview />} /> */}
          <Route path="/soporte" element={<SupportPage />} />
          <Route path="/soporte/:id" element={<SupportDetail />} />
          <Route path="/notificaciones" element={<NotificacionesPage />} />

          <Route path="/sobre-nosotros" element={<Aboutus />} />
          <Route path="/bolsa-de-empleo" element={<BolsaDeEmpleo />} />
          {/* <Route path="/services" element={<Services />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/employer-list" element={<Employerlist />} />
          <Route path="/employer-detail/:id" element={<EmployerDetail />} />
          <Route path="/employer-detail" element={<EmployerDetail />} />
          <Route path="/candidate-list" element={<CandidateList />} /> */}
          <Route path="/candidato-perfil/:id" element={<CandidateDetail />} />
          <Route path="/candidato-perfil/cursos" element={<CursosList />} />
          <Route path="/candidato-perfil/empleos" element={<EmpleoList />} />
          <Route
            path="/candidato-perfil"
            element={
              <PrivateRoute requiredPermissions={[
                "panel.dashboard.index",
                "panel.candidates.show"
              ]}>
                <CandidateDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/candidato-perfil-configuracion"
            element={
              /**
               * RUTA PRIVADA EXPLICADA
               * ! Ruta privada, como funciona?
               * ? Se wrappea (rodea) el elemento que hace referencia a la ruta privada
               * ? con el componente PrivateRoute que se encuentra en la raiz de nuestro
               * ? proyecto.
               * ? Este componente recibe un array de permisos que debe de tener el usuario
               * ? logeado para poder acceder a dicha seccion.
               */
              <PrivateRoute requiredPermissions={["panel.dashboard.index"]}>
                <CandidateProfileSetting />
              </PrivateRoute>
            }
          />
          {/* <Route path="/career" element={<Career />} /> */}
          <Route path="/contacto" element={<Contact />} />
          {/* <Route path="/comingsoon" element={<Comingsoon />} /> */}
          {/* <Route path="/maintenance" element={<Maintenance />} /> */}
          <Route path="*" element={<Navigate to="/error" />} />
          <Route path="/error" element={<Error />} />
          <Route path="/confirmacion-registro" element={<Thankyou />} />
          {/* <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/registro" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:titulo" element={<Post />} />
          {/* <Route path="/:titulo" element={<Post />} />
          <Route path="/lock-screen" element={<LockScreen />} /> */}
        </Routes>

        {/* COMENTAMOS EL SWITCHER TEMPORALMENTE PARA SABER SI USAREMOS EL MODO OSCURO O NO */}
        {/* <Switcher /> */}
      </BrowserRouter>
      <ToastContainer />
    </RecoilRoot>
  );
}

export default App;
