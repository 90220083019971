import { atom } from "recoil";

export const postsAtom = atom({
    key: "blogAtom",
    default: [],
})

export const postSelectedAtom = atom({
    key: "blogSelectedAtom",
    default: {},
})

export const postIdAtom = atom({
    key: 'postIdAtom',
    default: localStorage.getItem('postId') || null,
})