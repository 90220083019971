import React, { useRef, useState, Fragment } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import useTickets from "./hooks/useTickets";
import SupportFilters from "./SupportFilters";
import { useLocation } from "react-router-dom";
import SupportList from "./SupportList";
import TicketPlaceholder from "./components/TicketPlaceholder";
import { Dialog, Transition } from "@headlessui/react";
import { LifeBuoy, PlusCircle, X } from "react-feather";
import CreateTicketForm from "./components/CreateTicketForm";

export default function SupportPage() {
  const { loading, totalElements } = useTickets();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tieneBusqueda = queryParams.has("busqueda");
  const valorBusqueda = tieneBusqueda ? queryParams.get("busqueda") : "";

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Navbar />
      <section className="relative lg:mt-24 mt-[74px] pb-16">
        <SupportFilters busqueda={valorBusqueda} />
        <div
          style={{ height: "calc(100vh - 500px)" }}
          className="container mt-10"
        >
          <div className="grid grid-cols-1 gap-[30px] mt-5">
            {!loading.get ? (
              <SupportList />
            ) : (
              <TicketPlaceholder iterations={totalElements} />
            )}
          </div>
          <div
            className="group relative overflow-hidden md:flex justify-center bg-slate-100 items-center rounded shadow hover:shadow-md dark:shadow-gray-700 transition-all duration-500 p-5 mt-4 cursor-pointer"
            onClick={() => setOpen(true)}
          >
            <PlusCircle size={18} />{" "}
            <p className="pl-2">Crear un nuevo ticket de soporte</p>
          </div>
        </div>
      </section>

      {/* MODAL ABRIR NUEVO TICKET */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-overall"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 relative top-1/2 -translate-y-1/2">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  style={{ maxWidth: "448px" }}
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6 w-full"
                >
                  <div>
                    <div
                      style={{ right: 0 }}
                      className="absolute top-0 pt-4 pr-4 cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <X size={20} />
                    </div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <LifeBuoy className="w-5 h-5" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Creando nuevo ticket de soporte
                      </Dialog.Title>
                    </div>
                    <CreateTicketForm />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Footer />
    </>
  );
}
