import React, { useState, useEffect } from "react";
import { LuSearch } from "../../assets/icons/vander";
import useCourses from "./hooks/useCourses";
import {
  Book,
  Briefcase,
  Clock,
  DollarSign,
  MapPin,
  Monitor,
  UserCheck,
} from "react-feather";
import { filter } from "lodash";

const CourseFilters = () => {
  const [selectedModalidad, setSelectedModalidad] = useState("");
  // const [selectedJornada, setSelectedJornada] = useState('')
  // const [selectedSalario, setSelectedSalario] = useState('');
  const [selectedCategoria, setSelectedCategoria] = useState("");

  const {
    filters,
    categorias,
    getCategoriasCursos,
    handleChangeFilters,
    currentPage,
    getCursos,
  } = useCourses();

  useEffect(() => {
    getCategoriasCursos();
  }, []);

  const handleModalidadChange = (value) => {
    setSelectedModalidad(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    getCursos({ ...filters, page: currentPage });
  };

  const handleResetFilters = () => {
    const auxFilters = {
      name: "",
      categoriaId: "",
      lugarTrabajo: "",
      modalidad: null,
    };
    handleModalidadChange(null);

    handleChangeFilters(auxFilters);
    getCursos({ ...auxFilters, page: currentPage });
  };

  return (
    <div className="lg:col-span-4 md:col-span-6">
      <div className="shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900 sticky top-20">
        <form>
          <div className="grid grid-cols-1 gap-3">
            <div>
              <label htmlFor="searchname" className="font-semibold">
                Nombre
              </label>
              <div className="relative mt-2">
                <LuSearch className="text-lg absolute top-[10px] start-3" />
                <input
                  name="name"
                  id="name"
                  type="text"
                  className="form-input border border-slate-100 dark:border-slate-800 ps-10"
                  placeholder="Nombre"
                  value={filters.name}
                  onChange={(e) =>
                    handleChangeFilters({ ...filters, name: e.target.value })
                  }
                />
              </div>
            </div>

            <div>
              <label className="font-semibold">Categorías</label>
              <select
                name="categoria"
                id="categoria"
                className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                onChange={(e) => {
                  console.log(e.target.value);
                  handleChangeFilters({
                    ...filters,
                    categoria: e.target.value,
                  });
                }}
                value={filters.categoria}
              >
                <option value="">Selecciona una opción</option>
                {categorias?.map((categoria) => (
                  <option key={categoria.id} value={categoria.id}>
                    {categoria.nombre}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="font-semibold" htmlFor="lugarTrabajo">
                Localización
              </label>
              <div className="relative mt-2">
                <MapPin className="absolute top-[10px] start-3 text-slate-600 w-5 h-5" />
                <input
                  name="lugarTrabajo"
                  id="lugarTrabajo"
                  type="text"
                  className="form-input border border-slate-100 dark:border-slate-800 ps-10"
                  placeholder="Localización"
                  onChange={(e) =>
                    handleChangeFilters({
                      ...filters,
                      lugarTrabajo: e.target.value,
                    })
                  }
                  value={filters.lugarTrabajo}
                />
              </div>
              {/* <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                                <option value="cordoba">Córdoba</option>
                                <option value="cardena">Cardeña</option>
                                <option value="lucena">Lucena</option>
                            </select> */}
            </div>

            <div>
              <label className="font-semibold">Modalidad</label>
              <div className="block mt-2">
                <div>
                  <label
                    htmlFor="presencial"
                    className="inline-flex items-center"
                  >
                    <input
                      type="radio"
                      name="modalidad"
                      id="presencial"
                      className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      value="presencial"
                      checked={filters.modalidad === "presencial"}
                      onChange={(e) =>
                        handleChangeFilters({
                          ...filters,
                          modalidad: e.target.value,
                        })
                      }
                    />
                    <span className="ms-2 text-slate-400">Presencial</span>
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="semipresencial"
                    className="inline-flex items-center"
                  >
                    <input
                      type="radio"
                      name="modalidad"
                      id="semipresencial"
                      className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      value="semipresencial"
                      checked={filters.modalidad === "semipresencial"}
                      onChange={(e) =>
                        handleChangeFilters({
                          ...filters,
                          modalidad: e.target.value,
                        })
                      }
                    />
                    <span className="ms-2 text-slate-400">Semipresencial</span>
                  </label>
                </div>
                <div>
                  <label htmlFor="online" className="inline-flex items-center">
                    <input
                      type="radio"
                      name="modalidad"
                      id="online"
                      className="form-radio border-gray-200 dark:border-gray-800 text-primary focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50"
                      value="online"
                      checked={filters.modalidad === "online"}
                      onChange={(e) =>
                        handleChangeFilters({
                          ...filters,
                          modalidad: e.target.value,
                        })
                      }
                    />
                    <span className="ms-2 text-slate-400">Online</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex gap-2 mt-4">
              <button
                type="button"
                onClick={(e) => handleSubmit(e)}
                className="btn bg-primary-900 hover:bg-black border-primary hover:border-black text-white rounded-md w-full"
              >
                Aplicar filtros
              </button>
              <button
                type="button"
                className="btn bg-primary-900 hover:bg-black border-primary hover:border-black text-white rounded-md w-full"
                onClick={() => handleResetFilters()}
              >
                Limpiar filtros
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CourseFilters;
