import * as Yup from 'yup';

export const validationInscripcionOfertaEmpleo = Yup.object().shape({
        discapacidad: Yup.string().required('Por favor, indica si tienes discapacidad.'),
        desempleado: Yup.string().nullable().required('Por favor, indica si actualmente tienes empleo.'),
        archivo_informatica: Yup.mixed(),
        archivo_drive: Yup.mixed(),
        archivo_idiomas: Yup.mixed(),
        archivo_formacion: Yup.mixed(),
        archivo_experiencia: Yup.mixed()
    });